import { Select, Spin, Form } from 'antd';
import debounce from 'lodash/debounce';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
        <Select
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
        />
    );
}

// Usage of DebounceSelect
const ReferenceSelect = (props) => {
    const { onFetch, onChange, isMultiple } = props;
    const { t } = useTranslation();
    console.log(onFetch);
    return <Form.Item {...props.wrapper}>
        <div style={{ display: 'flex' }}>
            <Form.Item {...props.inner} noStyle>
                <DebounceSelect
                    name={props.inner.name}
                    mode={isMultiple ? 'multiple' : 'single'}
                    placeholder={props.placeholder ? props.placeholder : t('referenceSelect.select')}
                    fetchOptions={onFetch}
                    onChange={onChange ? onChange : null}
                    {...props.selectProps}
                />
            </Form.Item>
        </div>
        {props.note ? <div style={{ fontSize: 12 }}>{props.note}</div> : ''}
    </Form.Item >
};
export default ReferenceSelect;