import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Form, Input, Switch, Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { createProductCategory, readProductCategory, updateProductCategory } from '../actions/product-category';
import { useTranslation } from 'react-i18next';
import { convertProductCategoryTranslations } from '../helpers/product';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

function ProductCategory() {
    const languages = [
        { code: 'zh-HK', name: 'Traditional Chinese' },
        { code: 'en-US', name: 'English' },
        { code: 'ja-JP', name: 'Japanese' },
    ];
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { Content } = Layout;
    const [form] = Form.useForm();
    const items = languages.map(language => {
        return {
            forceRender: true,
            label: language.name,
            key: language.code,
            children: <>
                <Form.Item name={["translations", language.code, "name"]} label={t('productCategory.name.label')} rules={[{ required: true, message: t('productCategory.name.required') }]}>
                    <Input placeholder={t('productCategory.name.placeholder')} />
                </Form.Item>
            </>
        }
    });

    const [productTitle, setProductCategoryTitle] = useState('');
    useEffect(() => {
        if (typeof params.id !== 'undefined') {
            setProductCategoryTitle(t('productCategory.edit.title'));
            dispatch(readProductCategory(params.id)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    let product = convertProductCategoryTranslations(response.result);
                    setProductCategoryTitle(t('productCategory.edit.title') + ' - ' + response.result.slug);
                    form.setFieldsValue(product);
                } else {
                    message.error(t('productCategory.message.read-error'));
                    navigate('/product-categories');
                }
            }).catch(e => {
                console.log(e);
                message.error(t('productCategory.message.read-error'));
                navigate('/product-categories');
            });
        } else {
            setProductCategoryTitle(t('productCategory.new.title'));
        }
    }, [params.id, dispatch, navigate, form, t]);

    const onFinish = (values) => {
        if (typeof values.status === 'undefined') {
            values.status = false;
        }
        if (typeof params.id !== 'undefined') {
            // Update the existing product
            dispatch(updateProductCategory(params.id, values)).then((response) => {
                console.log(response);
                if (response.result) {
                    setProductCategoryTitle(t('productCategory.edit.title') + ' - ' + response.result.slug);
                    form.setFieldsValue(convertProductCategoryTranslations(response.result));
                    console.log(response.result);
                }
                message.success(t('productCategory.message.update-success'));
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        } else {
            // Create new product
            dispatch(createProductCategory(values)).then((data) => {
                message.success(t('productCategory.message.create-success'));
                if (data.result) {
                    navigate('/product-category/' + data.result.id);
                } else {
                    navigate('/product-categories');
                }
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        if (typeof errorInfo.errorFields !== 'undefined' && Array.isArray(errorInfo.errorFields)) {
            errorInfo.errorFields.forEach(field => {
                message.error(field.errors[0]);
            });
        }
    };

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <Form {...layout} form={form} name="product" onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={{
                        "saleable": true,
                        "published": true,
                    }}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate('/product-categories')}
                            title={productTitle}
                            extra={[
                                <Button key="cancel" type="primary" danger onClick={() => navigate('/product-categories')}>{t('productCategory.button.cancel')}</Button>,
                                <Button key="save" type="primary" htmlType="submit">{params.id ? t('productCategory.button.update') : t('productCategory.button.save')}</Button>
                            ]}
                        />
                        <Form.Item
                            name="slug"
                            label={t('productCategory.slug.label')}
                            rules={[
                                { required: true, message: t('productCategory.slug.required') },
                                // slug pattern
                                { pattern: /^[a-z0-9-_]+$/, message: t('productCategory.slug.pattern') }
                            ]}
                        >
                            <Input placeholder={t('productCategory.slug.placeholder')} />
                        </Form.Item>
                        <Form.Item name="status" label={t('productCategory.status.label')} valuePropName="checked">
                            <Switch />
                        </Form.Item>
                        <Tabs
                            defaultActiveKey={languages[0]}
                            items={items}
                        />
                    </Form>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default ProductCategory;