import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space, Upload } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { CopyOutlined, InboxOutlined, RedoOutlined } from '@ant-design/icons';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { readMedia, countMedia, deleteMedia } from '../actions/media';
import { API_CDN_URL, API_MEDIA_URL } from '../services/config';
import authHeader from '../services/auth-header';
import moment from 'moment';
import { userIsReadOnly as userIsReadOnlyCheck } from '../helpers/user';
import { useTranslation } from 'react-i18next';

function MediaLibrary(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Content } = Layout;
    const [dataSourceCount, setDataSourceCount] = useState(0);
    const [dataSource, setDataSource] = useState([]);
    const mediaUploadAction = API_MEDIA_URL + 'upload';
    const currentUser = useSelector(state => state.auth.user);
    const userIsReadOnly = userIsReadOnlyCheck(currentUser);
    const [processedUploadedFiles, setProcessedUploadedFiles] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultPerPageState = props.isDrawer ? 40 : (searchParams.get('perPage') || 40);
    const [perPage, setPerPage] = useState(defaultPerPageState);
    const defaultPageState = props.isDrawer ? 1 : (searchParams.get('page') || 1);
    const [page, setPage] = useState(defaultPageState);

    const deleteMediaAction = (record) => {
        dispatch(deleteMedia(record.id)).then((response) => {
            message.success(t('media.message.delete-success'));
            reloadMedia();
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const mediaUploadChange = (event) => {
        if (Array.isArray(event.fileList)) {
            event.fileList.forEach(uploadedFile => {
                console.log(uploadedFile);
                if (uploadedFile.status === 'error' || uploadedFile.status === 'done') {
                    if (processedUploadedFiles.indexOf(uploadedFile.name) === -1) {
                        setProcessedUploadedFiles([...processedUploadedFiles, uploadedFile.name]);
                        if (uploadedFile.status === 'done') {
                            message.success(uploadedFile.response.message);
                        } else {
                            message.error(uploadedFile.response.message);
                        }
                    }
                }
            });
        }
    }


    const columns = [
        {
            title: t('media.columns.name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('media.columns.link'),
            key: 'link',
            width: '40%',
            render: (text, record) => {
                let link = API_CDN_URL() + record.key;
                return <a href={link} rel="noreferrer" target="_blank">{link}</a>;
            }
        },
        {
            title: t('media.columns.created'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => {
                return moment(record['createdAt']).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        {
            title: t('media.columns.actions'),
            key: 'action',
            render: (text, record) => {
                return <Space>
                    {props.isDrawer && <Button type="primary" onClick={() => props.selectMedia ? props.selectMedia(record) : null}>{t('media.actions.select')}</Button>}
                    <Button type="primary" onClick={() => copyMediaUrl(record)}><CopyOutlined /> {t('media.actions.copy')}</Button>
                    {!userIsReadOnly && <Popconfirm
                        title={t('media.message.delete-confirm')}
                        onConfirm={() => deleteMediaAction(record)}
                        okText={t('media.actions.delete-yes')}
                        cancelText={t('media.actions.delete-no')}
                    ><Button type="primary" danger>{t('media.actions.delete')}</Button></Popconfirm>}
                </Space>
            }
        }
    ];

    const copyMediaUrl = (record) => {
        let url = API_CDN_URL() + record.key;
        navigator.clipboard.writeText(url);
        message.success(t('media.message.copied'));
    }

    const reloadMedia = useCallback(() => {
        dispatch(countMedia()).then(function (response) {
            if (typeof response.result !== 'undefined') {
                setDataSourceCount(response.result);
            } else {
                message.error('Error while fetching media');
            }
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
        dispatch(readMedia({ page, perPage })).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
            } else {
                message.error('Error while fetching media');
            }
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch, page, perPage]);
    useEffect(() => {
        if (typeof props.isDrawer === 'undefined') setSearchParams({ page, perPage });
        reloadMedia();
    }, [perPage, page, props.isDrawer, reloadMedia, setSearchParams]);

    const headerBack = () => navigate('/');
    const innerMediaLibrary = <><PageHeader
        className="site-page-header"
        onBack={props.isDrawer ? null : headerBack}
        title={t('media.title')}
        subTitle={t('media.subtitle')}
        extra={[
            <Button key="refresh" type="primary" onClick={() => reloadMedia()}>
                <RedoOutlined /> {t('media.actions.refresh')}</Button>
        ]}
    />
        {!userIsReadOnly && <div style={{ marginBottom: 20 }}>
            <Upload.Dragger
                name="files"
                headers={authHeader()}
                action={mediaUploadAction}
                onChange={mediaUploadChange} multiple={true}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t('media.upload.action-reminder')}</p>
                <p className="ant-upload-hint">{t('media.upload.support-reminder')}</p>
            </Upload.Dragger>
        </div>}
        {!userIsReadOnly && <h4>{t('media.message.refresh-reminder')}</h4>}
        <Table
            rowKey={record => record.id}
            dataSource={dataSource}
            columns={columns}
            pagination={{
                position: ['topRight', 'bottomRight'],
                total: dataSourceCount,
                showTotal: (total, range) => `${range[0]}-${range[1]} ${t('pagination.of')} ${total} ${t('pagination.items')}`,
                pageSize: perPage,
                current: page,
                onChange: (page, pageSize) => {
                    setPage(page);
                    setPerPage(pageSize);
                }
            }}
        /></>;

    if (props.isDrawer) {
        // Means called in drawer
        return innerMediaLibrary;
    } else {
        return <>
            <MemberSidebar />
            <Layout className="site-layout">
                <Content style={{ margin: '0 16px' }}>
                    <div className="site-layout-background">
                        {innerMediaLibrary}
                    </div>
                </Content>
                <MemberFooter />
            </Layout>
        </>;
    }
}

export default MediaLibrary;