export const API_CDN_URL = () => {
    return 'https://sake-outlet-cdn.k2.digital/';
}
export const API_HOST = process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : 'http://localhost:3000/api';
export const API_MEDIA_URL = API_HOST + '/media/';
export const API_AUTH_URL = API_HOST + '/auth/';
export const API_LOG_URL = API_HOST + '/log/';
export const API_ROLE_URL = API_HOST + '/role/';
export const API_ADMIN_URL = API_HOST + '/admin/';
export const API_PRODUCT_URL = API_HOST + '/product/';
export const API_PRODUCT_CATEGORY_URL = API_HOST + '/product-category/';
export const API_ORDER_URL = API_HOST + '/order/';
export const API_USER_URL = API_HOST + '/user/';
export const API_REPORT_URL = API_HOST + '/report/';

export const API_OWNER_PRODUCT_INVENTORY_URL = (productOwnerId) => {
    return API_HOST + '/product_owner/' + productOwnerId + '/product-inventory/';
}
export const API_OPERATION_PRODUCT_INVENTORY_URL = (inventoryId) => {
    if (typeof inventoryId === 'undefined' || inventoryId === null) {
        return API_HOST + '/operation_team/product-inventory/';
    } else {
        return API_HOST + '/operation_team/product-inventory/' + inventoryId + '/';
    }
}

export const API_OWNER_PRODUCT_REQUEST_URL = (productOwnerId) => {
    return API_HOST + '/product_owner/' + productOwnerId + '/product-request/';
}
export const API_OPERATION_PRODUCT_REQUEST_URL = (requestId) => {
    if (typeof requestId === 'undefined' || requestId === null) {
        return API_HOST + '/operation_team/product-request/';
    } else {
        return API_HOST + '/operation_team/product-request/' + requestId + '/';
    }
}

// These are probably the special endpoints for admin / root
export const API_WAREHOUSE_URL = API_HOST + '/warehouse/';


export const API_DELIVERY_NOTE_URL = (warehouseId, deliveryNoteId) => {
    if (typeof warehouseId === 'undefined' || warehouseId === null) {
        // operation team
        return API_HOST + '/operation_team/delivery-note/' + (typeof deliveryNoteId === 'undefined' || deliveryNoteId === null ? '' : deliveryNoteId + '/');
    } else {
        // warehouse manager
        return API_HOST + '/warehouse_manager/' + warehouseId + '/delivery-note/' + (typeof deliveryNoteId === 'undefined' || deliveryNoteId === null ? '' : deliveryNoteId + '/');
    }
}
