import DeliveryNoteService from "../services/delivery.note.service";

// Warehouse manager Use
// Action to get all delivery notes by warehouse manager id
export const readDeliveryNotes = (id, searchParams) => (dispatch) => {
    return DeliveryNoteService.readDeliveryNotes(id, searchParams).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Warehouse manager Use
// Action to get a delivery note by warehouse manager id and delivery note id
export const readDeliveryNote = (id, deliveryNoteId) => (dispatch) => {
    return DeliveryNoteService.readDeliveryNoteById(id, deliveryNoteId).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Warehouse manager Use
// Action to update a delivery note to packed
export const packedDeliveryNote = (id, deliveryNoteId) => (dispatch) => {
    return DeliveryNoteService.packedDeliveryNote(id, deliveryNoteId).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Operation team Use
// read all delivery notes
export const readAllDeliveryNotes = (searchParams) => (dispatch) => {
    return DeliveryNoteService.readAllDeliveryNotes(searchParams).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Operation team use
// add log on the delivery note
export const addLogByOperationTeam = (deliveryNoteId, log) => (dispatch) => {
    return DeliveryNoteService.addLogByOperationTeam(deliveryNoteId, log).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Warehouse manager use
// add log on the delivery note
export const addLog = (id, deliveryNoteId, log) => (dispatch) => {
    return DeliveryNoteService.addLog(id, deliveryNoteId, log).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Operation team Use
export const readDeliveryNoteByOperationTeam = (deliveryNoteId) => (dispatch) => {
    return DeliveryNoteService.readDeliveryNoteByOperationTeam(deliveryNoteId).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Operation team Use
export const packedDeliveryNoteByOperationTeam = (deliveryNoteId) => (dispatch) => {
    return DeliveryNoteService.packedDeliveryNoteByOperationTeam(deliveryNoteId).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Warehouse manager Use
export const shipDeliveryNote = (id, deliveryNoteId, tracking) => (dispatch) => {
    return DeliveryNoteService.shipDeliveryNote(id, deliveryNoteId, tracking).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Operation team Use
export const shipDeliveryNoteByOperationTeam = (deliveryNoteId, tracking) => (dispatch) => {
    return DeliveryNoteService.shipDeliveryNoteByOperationTeam(deliveryNoteId, tracking).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}