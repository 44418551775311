import React, { useEffect, useState } from 'react';
import { API_CDN_URL } from '../services/config';
import { Layout, message, Button, Descriptions, Table, Popconfirm, Form, Input, Modal, InputNumber } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readOrder, processOrder, addNoteToOrder, refundOrder, assignStockToOrder, processOrderPreparing, completeOrder, shipOrder, cancelOrder } from '../actions/order';
import { useTranslation } from 'react-i18next';
import { green, red, cyan } from "@ant-design/colors";
import moment from 'moment';
import _ from 'underscore';

function Order() {
    const [noteForm] = Form.useForm();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const [inventoryForm] = Form.useForm();
    const { Content } = Layout;
    const [orderTitle, setOrderTitle] = useState('');
    const [dataSource, setDataSource] = useState(null);
    const [inventoryModalOpened, setInventoryModalOpened] = useState(false);
    const [inventoryToBeUsed, setInventoryToBeUsed] = useState(null);
    const [refundForm] = Form.useForm();
    const [refundModalOpen, setRefundModalOpen] = useState(false);

    const handleRefundModalOk = () => {
        refundForm.validateFields().then(() => {
            dispatch(refundOrder(dataSource.id, refundForm.getFieldValue('refundedAmount'))).then(response => {
                message.success(t('orders.refund_success'));
                refundForm.resetFields();
                setRefundModalOpen(false);
                dispatch(readOrder(params.id)).then((response) => {
                    if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                        setOrderTitle(t('order.edit.title') + ' - ' + response.result.id);
                        console.log(response.result);
                        setDataSource(response.result);
                    } else {
                        message.error(t('order.message.read-error'));
                        navigate('/orders');
                    }
                }).catch(e => {
                    console.log(e);
                    message.error(t('order.message.read-error'));
                    navigate('/orders');
                });
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }).catch(e => {
            console.log(e);
        });
    }
    const handleRefundModalCancel = () => {
        refundForm.resetFields();
        setRefundModalOpen(false);
    }

    const shipOrderAction = (orderId) => {
        dispatch(shipOrder(orderId)).then((response) => {
            if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                message.success(t('order.message.ship-success'));
                setOrderTitle(t('order.edit.title') + ' - ' + response.result.id);
                console.log(response.result);
                setDataSource(response.result);
            } else {
                message.error(t('order.message.ship-error'));
            }
        }).catch(e => {
            console.log(e);
            message.error(t('order.message.ship-error'));
        });
    }
    const completeOrderAction = (orderId) => {
        dispatch(completeOrder(orderId)).then((response) => {
            if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                message.success(t('order.message.complete-success'));
                setOrderTitle(t('order.edit.title') + ' - ' + response.result.id);
                console.log(response.result);
                setDataSource(response.result);
            } else {
                message.error(t('order.message.complete-error'));
            }
        }).catch(e => {
            console.log(e);
            message.error(t('order.message.complete-error'));
        });
    }
    const stockArrangementActionOk = () => {
        const stockFormValues = {};
        const inventoryFormValues = inventoryForm.getFieldsValue();
        stockFormValues.quantity = inventoryFormValues.quantity;
        stockFormValues.inventory = inventoryToBeUsed.id;
        dispatch(assignStockToOrder(dataSource.id, stockFormValues)).then((response) => {
            if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                message.success(t('order.message.assign-stock-success'));
                inventoryForm.resetFields();
                setInventoryModalOpened(false);
                setDataSource(response.result);
            } else {
                if (typeof response.message !== 'undefined') message.error(response.message);
                message.error(t('order.message.assign-stock-error'));
            }
        }).catch(e => {
            if (typeof e.response !== 'undefined' && typeof e.response.data !== 'undefined' && typeof e.response.data.message !== 'undefined') message.error(e.response.data.message);
            console.log(e);
            message.error(t('order.message.assign-stock-error'));
        });
    }
    const stockArrangementActionCancel = () => {
        inventoryForm.resetFields();
        setInventoryModalOpened(false);
    }
    const shippingColumns = [
        {
            title: t('order.stockArrangement.field.sku'),
            dataIndex: 'sku',
            key: 'sku',
            render: (text, record) => {
                if (typeof record.product === 'object' && typeof record.product._id !== 'undefined') {
                    return record.product.sku;
                } else {
                    return record.snapshot.sku;

                }
            }
        },
        {
            title: t('order.stockArrangement.field.product'),
            dataIndex: 'product',
            key: 'product',
            render: (text, record) => {
                let productName = '';
                // check product isobject
                if (typeof record.product === 'object' && typeof record.product._id !== 'undefined') {
                    // Find the translations zh-hk
                    let translations = record.product.translations.filter((translation) => {
                        return translation.lang === 'zh-HK';
                    });
                    if (translations.length > 0) {
                        productName = translations[0].name;
                    } else {
                        productName = record.product.name;
                    }
                } else {
                    productName = record.snapshot.name;
                }
                return <Link to={`/product/${typeof record.product === 'object' ? record.product._id : record.product}`}>{productName}</Link>;
            }
        },
        {
            title: t('order.stockArrangement.field.orderedQuantity'),
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: t('order.stockArrangement.field.assignedQuantity'),
            dataIndex: 'assignedQuantity',
            key: 'assignedQuantity',
            render: (text, record) => {
                if (typeof record.inventories !== 'undefined' && Array.isArray(record.inventories)) {
                    return record.inventories.reduce((a, b) => a + (b['quantity'] || 0), 0);
                } else {
                    return 0;
                }
            }
        },
        {
            title: t('order.stockArrangement.field.balanced'),
            dataIndex: 'balanced',
            key: 'balanced',
            render: (text, record) => {
                // return green or red text
                if (typeof record.inventories !== 'undefined' && Array.isArray(record.inventories)) {
                    let quantity = record.inventories.reduce((a, b) => a + (b['quantity'] || 0), 0);
                    if (record.quantity === quantity) {
                        return <span style={{ color: green[5] }}>{t('order.stockArrangement.balanced')}</span>;
                    } else {
                        return <span style={{ color: red[5] }}>{t('order.stockArrangement.notBalanced')}</span>;
                    }
                } else {
                    return <span style={{ color: red[5] }}>{t('order.stockArrangement.notBalanced')}</span>;
                }
            }
        }
    ];
    const inventoryColumns = [
        {
            title: t('order.stockArrangement.field.stock_id'),
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {
                return <Link to={`/operation_team/inventory-requests/?_id=${record.id}`}>{record.id}</Link>;
            }
        },
        {
            title: t('order.stockArrangement.field.stock_from'),
            dataIndex: 'from',
            key: 'from',
            render: (text, record) => {
                return <Link to={record.admin ? `/admin/${record.admin._id}` : ''}>{record.admin ? record.admin.name : 'N/A'}</Link>;
            }
        },
        {
            title: t('order.stockArrangement.field.warehouse'),
            dataIndex: 'warehouse',
            key: 'warehouse',
            render: (text, record) => {
                return <Link to={`/warehouse/${record.warehouse._id}`}>{record.warehouse.name}</Link>;
            }
        },
        {
            title: t('order.stockArrangement.field.createdAt'),
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: t('order.stockArrangement.field.availableQuantity'),
            dataIndex: 'availableQuantity',
            key: 'availableQuantity',
            render: (text, record) => {
                if (typeof record.orders !== 'undefined' && Array.isArray(record.orders) && record.orders.length > 0) {
                    // Exclude the current order from the calculation
                    let orders = record.orders.filter((order) => {
                        return order.order !== dataSource.id;
                    });
                    let quantity = orders.reduce((a, b) => a + (b['quantity'] || 0), 0);
                    return record.currentQuantity - quantity;
                } else {
                    return record.currentQuantity - record.orderedQuantity;
                }
            }
        },
        {
            title: t('order.stockArrangement.field.assignedQuantity'),
            dataIndex: 'assignedQuantity',
            key: 'assignedQuantity',
            render: (text, record) => {
                if (dataSource && dataSource.products && dataSource.products.length > 0) {
                    console.log(dataSource.products);
                    let product = dataSource.products.find((product) => {
                        return product.snapshot._id === record.product;
                    });
                    console.log(product);
                    if (product && product.inventories) {
                        let inventory = product.inventories.find((inventory) => {
                            if (typeof inventory.inventory === 'object') {
                                return inventory.inventory._id === record.id;
                            } else {
                                return inventory.inventory === record.id;
                            }
                        });
                        if (inventory) {
                            return inventory.quantity;
                        } else {
                            return 0;
                        }
                    }
                }
            }
        },
        {
            title: t('order.stockArrangement.field.assign'),
            dataIndex: 'assign',
            key: 'assign',
            render: (text, record) => {
                if (dataSource && dataSource.status === 'processing') {
                    return <Button type="primary" onClick={() => {
                        inventoryForm.resetFields();
                        setInventoryToBeUsed(record);
                        setInventoryModalOpened(true);
                    }}>{t('order.stockArrangement.field.assign')}</Button>;
                }
            }
        }
    ]
    const expandStockRecord = (product) => {
        return <Table size='small' rowKey={record => record.id} dataSource={product.matchedInventories} columns={inventoryColumns} />;
    }
    const addNoteAction = (values) => {
        if (dataSource && dataSource.id) {
            dispatch(addNoteToOrder(dataSource.id, values.note)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    message.success(t('order.message.add-note-success'));
                    noteForm.resetFields();
                    setOrderTitle(t('order.edit.title') + ' - ' + response.result.id);
                    dispatch(readOrder(params.id)).then((response) => {
                        if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                            setOrderTitle(t('order.edit.title') + ' - ' + response.result.id);
                            console.log(response.result);
                            setDataSource(response.result);
                        } else {
                            message.error(t('order.message.read-error'));
                            navigate('/orders');
                        }
                    }).catch(e => {
                        console.log(e);
                        message.error(t('order.message.read-error'));
                        navigate('/orders');
                    });
                } else {
                    message.error(t('order.message.add-note-error'));
                }
            }).catch(e => {
                console.log(e);
                message.error(t('order.message.add-note-error'));
            });
        } else {
            message.error(t('order.message.add-note-error'));
        }
    }
    const processOrderPreparingAction = (orderId) => {
        dispatch(processOrderPreparing(orderId)).then((response) => {
            if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                message.success(t('order.message.preparing-success'));
                setOrderTitle(t('order.edit.title') + ' - ' + response.result.id);
                console.log(response.result);
                setDataSource(response.result);
            } else {
                if (typeof response.message !== 'undefined') message.error(response.message);
                message.error(t('order.message.preparing-error'));
            }
        }).catch(e => {
            if (typeof e.response !== 'undefined' && typeof e.response.data !== 'undefined' && typeof e.response.data.message !== 'undefined') message.error(e.response.data.message);
            message.error(t('order.message.preparing-error'));
        });
    }

    const refundOrderAction = (record) => {
        refundForm.setFieldsValue({ refundedAmount: record.subtotal });
        setRefundModalOpen(true);
    }

    const cancelOrderAction = (orderId) => {
        dispatch(cancelOrder(orderId)).then((response) => {
            if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                message.success(t('order.message.cancel-success'));
                setOrderTitle(t('order.edit.title') + ' - ' + response.result.id);
                console.log(response.result);
                setDataSource(response.result);
            } else {
                message.error(t('order.message.process-error'));
            }
        }).catch(e => {
            console.log(e);
            message.error(t('order.message.process-error'));
        });
    }
    const processOrderAction = (orderId) => {
        dispatch(processOrder(orderId)).then((response) => {
            if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                message.success(t('order.message.process-success'));
                setOrderTitle(t('order.edit.title') + ' - ' + response.result.id);
                // Dispatch the readOrder again because some stock information need it
                dispatch(readOrder(params.id)).then((response) => {
                    if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                        setOrderTitle(t('order.edit.title') + ' - ' + response.result.id);
                        console.log(response.result);
                        setDataSource(response.result);
                    } else {
                        message.error(t('order.message.read-error'));
                        navigate('/orders');
                    }
                }).catch(e => {
                    console.log(e);
                    message.error(t('order.message.read-error'));
                    navigate('/orders');
                });
            } else {
                message.error(t('order.message.process-error'));
            }
        }).catch(e => {
            console.log(e);
            message.error(t('order.message.process-error'));
        });
    }
    const deliveryNotesColumns = [
        {
            title: t('order.deliveryNote.field.id'),
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {
                return <Link to={`/delivery-note/${record.id}`}>{record.id}</Link>;
            }
        },
        {
            title: t('order.deliveryNote.field.warehouse'),
            dataIndex: 'warehouse',
            key: 'warehouse',
            render: (text, record) => {
                return <Link to={`/warehouse/${record.warehouse._id}`}>{record.warehouse.name}</Link>;

            }
        }, {
            title: t('order.deliveryNote.field.products'),
            dataIndex: 'products',
            key: 'products',
            render: (text, record) => {
                return <ul>
                    {record.snapshotProducts.map((product) => {
                        let inventoryCount = 0;
                        product.inventories.forEach((inventory) => {
                            inventoryCount += inventory.quantity;
                        });
                        let productName = '';
                        // check product isobject
                        if (typeof product.product === 'object' && typeof product.product._id !== 'undefined') {
                            // Find the translations zh-hk
                            let translations = product.product.translations.filter((translation) => {
                                return translation.lang === 'zh-HK';
                            });
                            if (translations.length > 0) {
                                productName = translations[0].name;
                            } else {
                                productName = product.product.name;
                            }
                        } else {
                            productName = product.snapshot.name;
                        }
                        return <li><Link to={`/product/${typeof product.product === 'object' ? product.product._id : product.product}`}>{productName} x {inventoryCount}</Link></li>;
                    })}
                </ul>
            }
        }, {
            title: t('order.deliveryNote.field.createdAt'),
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: t('order.deliveryNote.field.completed'),
            dataIndex: 'completed',
            key: 'completed',
            render: (text, record) => {
                if (record.completed) {
                    return <span style={{ color: green[5] }}>{t('order.deliveryNote.field.completed')}</span>;
                } else {
                    return <span style={{ color: red[5] }}>{t('order.deliveryNote.field.notCompleted')}</span>;
                }
            }
        },
        {
            title: t('order.deliveryNote.field.tracking'),
            dataIndex: 'tracking',
            key: 'tracking',
            render: (text, record) => <>{(record.tracking && record.tracking.number) ? record.tracking.number : 'N/A'}</>
        },
    ]
    useEffect(() => {
        if (typeof params.id !== 'undefined') {
            setOrderTitle(t('order.edit.title'));
            dispatch(readOrder(params.id)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setOrderTitle(t('order.edit.title') + ' - ' + response.result.id);
                    console.log(response.result);
                    setDataSource(response.result);
                } else {
                    message.error(t('order.message.read-error'));
                    navigate('/orders');
                }
            }).catch(e => {
                console.log(e);
                message.error(t('order.message.read-error'));
                navigate('/orders');
            });
        } else {
            setOrderTitle(t('order.new.title'));
        }
    }, [params.id, dispatch, navigate, t]);

    let extraButtons = [
        <Button key="cancel" type="primary" danger onClick={() => navigate('/orders')}>{t('order.button.cancel')}</Button>,
    ];
    if (dataSource && dataSource.id) {
        extraButtons.push(
            <Button style={{ backgroundColor: green[5] }} key="save" type="primary" onClick={() => {
                navigate('/user/' + dataSource.user._id);
            }}>{t('order.button.user')}</Button>
        );
        if (dataSource.status === 'preparing') {
            extraButtons.push(<Popconfirm
                title={t('orders.confirm.shipping_message')}
                onConfirm={() => shipOrderAction(dataSource.id)}
                okText={t('orders.confirm.shipping_confirm_ok')}
                cancelText={t('orders.confirm.shipping_confirm_cancel')}
            >
                <Button type="primary" style={{ backgroundColor: cyan[5] }}>{t('orders.button.shipping')}</Button>
            </Popconfirm>);
        }
        if (dataSource.status === 'shipping') {
            extraButtons.push(
                <Popconfirm
                    title={t('orders.confirm.complete_message')}
                    onConfirm={() => completeOrderAction(dataSource.id)}
                    okText={t('orders.confirm.complete_confirm_ok')}
                    cancelText={t('orders.confirm.complete_confirm_cancel')}
                >
                    <Button type="primary" style={{ backgroundColor: green[5] }}>{t('orders.button.complete')}</Button></Popconfirm>
            );
        }
    }
    if (dataSource && !['completed', 'shipping', 'refunded', 'cancelled'].includes(dataSource.status)) {
        extraButtons.push(<Popconfirm
            title={t('orders.confirm.cancel_message')}
            onConfirm={() => cancelOrderAction(dataSource.id)}
            okText={t('orders.confirm.cancel_confirm_ok')}
            cancelText={t('orders.confirm.cancel_confirm_cancel')}
        ><Button type="primary" danger>{t('orders.button.cancel')}</Button></Popconfirm>)
    }
    if (dataSource && !['pending', 'shipping', 'completed', 'cancelled', 'refunded'].includes(dataSource.status)) {
        extraButtons.push(<Popconfirm
            title={t('orders.confirm.refund_message')}
            onConfirm={() => refundOrderAction(dataSource)}
            okText={t('orders.confirm.refund_confirm_ok')}
            cancelText={t('orders.confirm.refund_confirm_cancel')}
        ><Button type="primary" danger>{t('orders.button.refund')}</Button></Popconfirm>);
    }
    if (dataSource && dataSource.status === 'paid') {
        extraButtons.push(
            <Popconfirm
                key="confirm"
                title={t('order.button.processing.title')}
                onConfirm={() => processOrderAction(dataSource.id)}
                okText={t('order.button.processing.ok')}
                cancelText={t('order.button.processing.cancel')}
            >
                <Button key="processing" type="primary">{t('order.button.processing.button')}</Button>
            </Popconfirm>
        );
    }
    if (dataSource && dataSource.status === 'processing') {
        extraButtons.push(
            <Popconfirm
                key="preparing_confirm"
                title={t('order.button.preparing_confirm.title')}
                onConfirm={() => processOrderPreparingAction(dataSource.id)}
                okText={t('order.button.preparing_confirm.ok')}
                cancelText={t('order.button.preparing_confirm.cancel')}
            >
                <Button key="deliveryNote" type="primary">{t('order.button.preparing.button')}</Button>
            </Popconfirm>
        );
    }

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <PageHeader
                    className="site-page-header"
                    onBack={() => navigate('/orders')}
                    title={orderTitle}
                    extra={extraButtons}
                />
                <div className="site-layout-background">
                    <Descriptions title={<h2>{t('order.section.information')}</h2>} column={2} bordered>
                        {dataSource && <>
                            <Descriptions.Item label={t('order.field.id')}>{dataSource.id}</Descriptions.Item>
                            <Descriptions.Item label={t('order.field.user')}>{dataSource.user.lastName}, {dataSource.user.firstName} &lt;<Link to={`/user/${dataSource.user._id}`}>{dataSource.user._id}</Link>&gt;</Descriptions.Item>
                            <Descriptions.Item label={t('order.field.status')}>{t('order.status.' + dataSource.status)}</Descriptions.Item>
                            <Descriptions.Item label={t('order.field.subtotal')}>{dataSource.subtotal}</Descriptions.Item>
                            <Descriptions.Item label={t('order.field.shipping_fee')}>{dataSource.shipping_fee}</Descriptions.Item>
                            <Descriptions.Item label={t('order.field.total')}>{dataSource.total}</Descriptions.Item>
                            {dataSource && dataSource.status === 'refunded' && <>
                                <Descriptions.Item label={t('order.field.refund')}>{dataSource.refundedAmount}</Descriptions.Item>
                            </>}
                            <Descriptions.Item label={t('order.field.createdAt')}>{moment(dataSource.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Descriptions.Item>
                            <Descriptions.Item label={t('order.field.updatedAt')}>{moment(dataSource.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</Descriptions.Item>
                        </>}
                    </Descriptions>
                </div>
                <div className="site-layout-background">
                    <Descriptions title={<h2>{t('order.section.payment')}</h2>} column={1} bordered>
                        {dataSource && <>
                            <Descriptions.Item label={t('order.field.paymentMethod')}>{t('order.paymentMethod.' + dataSource.payment_method)}</Descriptions.Item>
                            <Descriptions.Item label={t('order.field.paymentRecord')}>
                                {dataSource.payment_record && <a href={API_CDN_URL() + dataSource.payment_record.path} target="_blank" rel="noreferrer"><img alt="payment_record" style={{ width: 100, height: 100, objectFit: 'cover' }} src={API_CDN_URL() + dataSource.payment_record.path} /></a>}
                            </Descriptions.Item>
                            <Descriptions.Item label={t('order.field.paymentRemark')}>{dataSource.payment_remark}</Descriptions.Item>
                        </>}
                    </Descriptions>
                </div>
                <div className="site-layout-background">
                    <h2>{t('order.section.products')}</h2>
                    <Table
                        size='small'
                        rowKey={record => record.id}
                        dataSource={dataSource && dataSource.products}
                        columns={[
                            {
                                title: t('order.products.field.image'),
                                dataIndex: 'image',
                                key: 'image',
                                width: 120,
                                render: (text, record) => {
                                    let productImage = '';
                                    // check product isobject
                                    if (typeof record.product === 'object' && typeof record.product._id !== 'undefined') {
                                        productImage = record.product.primary_image;
                                    } else {
                                        productImage = record.snapshot.primary_image;
                                    }
                                    return <img alt="product_image" style={{ width: 100, height: 100, objectFit: 'cover' }} src={productImage} />
                                }
                            },
                            {
                                title: t('order.products.field.sku'),
                                dataIndex: 'sku',
                                key: 'sku',
                                render: (text, record) => {
                                    let productSku = '';
                                    // check product isobject
                                    if (typeof record.product === 'object' && typeof record.product._id !== 'undefined') {
                                        productSku = record.product.sku;
                                    } else {
                                        productSku = record.snapshot.sku;
                                    }
                                    return productSku;
                                }
                            },
                            {
                                title: t('order.products.field.product'),
                                dataIndex: 'product',
                                key: 'product',
                                width: 320,
                                render: (text, record) => {
                                    let productName = '';
                                    // check product isobject
                                    if (typeof record.product === 'object' && typeof record.product._id !== 'undefined') {
                                        // Find the translations zh-hk
                                        let translations = record.product.translations.filter((translation) => {
                                            return translation.lang === 'zh-HK';
                                        });
                                        if (translations.length > 0) {
                                            productName = translations[0].name;
                                        } else {
                                            productName = record.product.name;
                                        }
                                    } else {
                                        productName = record.snapshot.name;
                                    }
                                    return <Link to={`/product/${typeof record.product === 'object' ? record.product._id : record.product}`}>{productName}</Link>;
                                }
                            },
                            {
                                title: t('order.products.field.quantity'),
                                dataIndex: 'quantity',
                                key: 'quantity',
                            },
                            {
                                title: t('order.products.field.price'),
                                dataIndex: 'price',
                                key: 'price',
                            },
                            {
                                title: t('order.products.field.total'),
                                dataIndex: 'total',
                                key: 'total',
                            },
                        ]}
                    ></Table>
                </div>
                <div className="site-layout-background">
                    <Descriptions title={<h2>{t('order.section.billing')}</h2>} column={2} bordered>
                        {dataSource && dataSource.billing && <>
                            <Descriptions.Item label={t('order.address.lastName')}>{dataSource.billing.lastName}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.firstName')}>{dataSource.billing.firstName}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.phoneRegion')}>{dataSource.billing.phoneRegion}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.phoneNumber')}>{dataSource.billing.phoneNumber}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.email')}>{dataSource.billing.email}</Descriptions.Item>
                            <Descriptions.Item></Descriptions.Item>
                            <Descriptions.Item label={t('order.address.address1')}>{dataSource.billing.address1}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.address2')}>{dataSource.billing.address2}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.region')}>{dataSource.billing.region}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.city')}>{dataSource.billing.city}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.zip')}>{dataSource.billing.zip}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.country')}>{dataSource.billing.country}</Descriptions.Item>
                        </>}
                    </Descriptions>
                </div>
                <div className="site-layout-background">
                    <Descriptions title={<h2>{t('order.section.shipping')}</h2>} column={2} bordered>
                        {dataSource && dataSource.shipping && <>
                            <Descriptions.Item label={t('order.address.lastName')}>{dataSource.shipping.lastName}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.firstName')}>{dataSource.shipping.firstName}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.phoneRegion')}>{dataSource.shipping.phoneRegion}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.phoneNumber')}>{dataSource.shipping.phoneNumber}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.email')}>{dataSource.shipping.email}</Descriptions.Item>
                            <Descriptions.Item></Descriptions.Item>
                            <Descriptions.Item label={t('order.address.address1')}>{dataSource.shipping.address1}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.address2')}>{dataSource.shipping.address2}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.region')}>{dataSource.shipping.region}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.city')}>{dataSource.shipping.city}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.zip')}>{dataSource.shipping.zip}</Descriptions.Item>
                            <Descriptions.Item label={t('order.address.country')}>{dataSource.shipping.country}</Descriptions.Item>
                        </>}
                    </Descriptions>
                </div>
                <div className="site-layout-background">
                    <h2>{t('order.section.stockArrangement')}</h2>
                    {dataSource && ['pending', 'paid'].includes(dataSource.status) && <>
                        {t('order.stockArrangement.not_available_shipping')}
                    </>}
                    {dataSource && ['processing', 'shipping', 'preparing', 'completed'].includes(dataSource.status) && <>
                        <Table
                            size='small'
                            rowKey={record => record.id}
                            dataSource={dataSource && dataSource.products}
                            columns={shippingColumns}
                            expandable={{
                                defaultExpandAllRows: true,
                                expandedRowRender: expandStockRecord,
                                rowExpandable: () => true
                            }}
                        />
                        <Modal
                            title={t('order.stockArrangement.modal.title')}
                            open={inventoryModalOpened}
                            onOk={stockArrangementActionOk}
                            onCancel={stockArrangementActionCancel}
                        >
                            <p>{t('order.stockArrangement.modal.content')}</p>
                            <Form form={inventoryForm}>
                                <Form.Item
                                    label={t('order.stockArrangement.modal.field.quantity')}
                                    name="quantity"
                                >
                                    <InputNumber />
                                </Form.Item>
                            </Form>
                        </Modal>
                    </>}
                </div>
                <div className="site-layout-background">
                    <h2>{t('order.section.deliveryNote')}</h2>
                    {dataSource && !['preparing', 'shipping', 'completed', 'cancelled', 'refunded'].includes(dataSource.status) && <>
                        {t('order.deliveryNote.not_available_preparing')}
                    </>}
                    {dataSource && ['preparing', 'shipping', 'completed', 'cancelled', 'refunded'].includes(dataSource.status) && <>
                        <Table size='small' rowKey={record => record.id} dataSource={dataSource && dataSource.deliveryNotes} columns={deliveryNotesColumns} />
                    </>}
                </div>
                <div className="site-layout-background">
                    <h2>{t('order.section.logs')}</h2>
                    <Table size='small' rowKey={record => record.id} dataSource={dataSource && dataSource.logs && _.sortBy(dataSource.logs, 'date').reverse()} columns={[
                        {
                            title: t('order.logs.field.action'),
                            dataIndex: 'action',
                            key: 'action',
                        },
                        {
                            title: t('order.logs.field.date'),
                            dataIndex: 'date',
                            key: 'date',
                            render: (text, record) => {
                                return moment(record.date).format('YYYY-MM-DD HH:mm:ss');
                            }
                        },
                        {
                            title: t('order.logs.field.note'),
                            dataIndex: 'note',
                            key: 'note',
                        }
                    ]}></Table>
                </div>
                <div className="site-layout-background">
                    <h2>{t('order.section.notes')}</h2>
                    <Form form={noteForm} layout="inline" onFinish={addNoteAction} style={{ backgroundColor: `rgba(0,0,0,0.05)`, padding: 10 }}>
                        <Form.Item
                            style={{ flex: 1 }}
                            name="note"
                        >
                            <Input placeholder={t('order.notes.field.note.placeholder')} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">{t('order.notes.button.add')}</Button>
                        </Form.Item>
                    </Form>
                    <Table size='small' rowKey={record => record.id} dataSource={dataSource && dataSource.notes && _.sortBy(dataSource.notes, 'date').reverse()} columns={[
                        {
                            title: t('order.notes.field.from'),
                            dataIndex: 'action',
                            key: 'action',
                            width: 200,
                            render: (text, record) => {
                                if (record.fromModel === 'User') {
                                    return 'User';
                                } else {
                                    return <Link to={`/admin/${record.from}`}>{record.fromModel}</Link>;
                                }
                            }
                        },
                        {
                            title: t('order.notes.field.date'),
                            dataIndex: 'date',
                            key: 'date',
                            width: 240,
                            render: (text, record) => {
                                return moment(record.date).format('YYYY-MM-DD HH:mm:ss');
                            }
                        },
                        {
                            title: t('order.notes.field.note'),
                            dataIndex: 'note',
                            key: 'note',
                        }
                    ]}></Table>
                </div>
            </Content>
            <Modal okText={t('modal.ok-text')} cancelText={t('modal.cancel-text')} title={t('orders.refundModal.title')} open={refundModalOpen} onOk={handleRefundModalOk} onCancel={handleRefundModalCancel}>
                <p>{t('orders.refundModal.description')}</p>
                <Form form={refundForm} name="refund-form">
                    <Form.Item>
                        <div>{t('orders.refundModal.refund.label')}</div>
                        <Form.Item
                            name="refundedAmount"
                            rules={[
                                {
                                    required: true,
                                    message: t('orders.refundModal.refund.required'),
                                }
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Form.Item>
                </Form>
            </Modal>
            <MemberFooter />
        </Layout>
    </>;
}

export default Order;