import authHeader from './auth-header';
import axios from "axios";
import {
    API_OWNER_PRODUCT_INVENTORY_URL,
    API_OPERATION_PRODUCT_INVENTORY_URL
} from "./config";
import qs from 'qs';
import { convertUrlSearchParamsToObject } from '../helpers/url';

// API to get all product inventories
const readAllProductInventories = (filters) => {
    let filterString = '';
    if (typeof filters !== 'undefined') {
        try {
            filterString = qs.stringify(convertUrlSearchParamsToObject(filters));
        } catch (e) {
            filterString = '';
        }
    }
    return axios.get(API_OPERATION_PRODUCT_INVENTORY_URL(null) + '?' + filterString, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to process the product inventory request
const processProductInventory = (id) => {
    return axios.put(API_OPERATION_PRODUCT_INVENTORY_URL(id) + 'process', null, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to approve the product inventory request
const approveProductInventory = (id) => {
    return axios.put(API_OPERATION_PRODUCT_INVENTORY_URL(id) + 'approve', null, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to reject the product inventory request
const rejectProductInventory = (id) => {
    return axios.put(API_OPERATION_PRODUCT_INVENTORY_URL(id) + 'reject', null, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to freeze the product inventory request
const freezeProductInventory = (id) => {
    return axios.put(API_OPERATION_PRODUCT_INVENTORY_URL(id) + 'freeze', null, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to adjust the product inventory request
const adjustProductInventory = (id, body) => {
    return axios.put(API_OPERATION_PRODUCT_INVENTORY_URL(id) + 'adjust', body, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to addLog on the product inventory request
const addLogProductInventory = (id, body) => {
    return axios.put(API_OPERATION_PRODUCT_INVENTORY_URL(id) + 'add-log', body, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to update the remark on the product inventory request
const updateRemarkProductInventory = (id, body) => {
    return axios.put(API_OPERATION_PRODUCT_INVENTORY_URL(id) + 'update-remark', body, {
        headers: authHeader()
    }).then(res => res.data);
}
// API to create product inventory request by operation team
const createProductInventoryByOperationTeam = (data) => {
    return axios.post(API_OPERATION_PRODUCT_INVENTORY_URL(null), data, {
        headers: authHeader()
    }).then(res => res.data);
}
// API to batch product inventory request by operation team
const batchProductInventory = (inventoryIds, action) => {
    return axios.put(API_OPERATION_PRODUCT_INVENTORY_URL(null) + 'batch', { inventoryIds, action }, {
        headers: authHeader()
    }).then(res => res.data);
}

// Product Owner Use
// API to get all product inventories by product owner id
const readProductInventories = (id, filters) => {
    let filterString = '';
    if (typeof filters !== 'undefined') {
        try {
            filterString = qs.stringify(convertUrlSearchParamsToObject(filters));
        } catch (e) {
            filterString = '';
        }
    }
    return axios.get(API_OWNER_PRODUCT_INVENTORY_URL(id) + '?' + filterString, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to create product inventory request by product owner id
const createProductInventory = (id, data) => {
    return axios.post(API_OWNER_PRODUCT_INVENTORY_URL(id), data, {
        headers: authHeader()
    }).then(res => res.data);
}

const ownerProductInventory = {
    // product owner use
    readProductInventories,
    createProductInventory,
    createProductInventoryByOperationTeam,
    batchProductInventory,
    // operation team use
    readAllProductInventories,
    processProductInventory,
    approveProductInventory,
    rejectProductInventory,
    freezeProductInventory,
    adjustProductInventory,
    addLogProductInventory,
    updateRemarkProductInventory
};

export default ownerProductInventory;