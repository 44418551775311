import authHeader from './auth-header';
import axios from "axios";
import qs from 'qs';
import { API_ORDER_URL } from "./config";
import { convertUrlSearchParamsToObject } from '../helpers/url';

// API to get order by id
const readOrder = (id) => {
    return axios.get(API_ORDER_URL + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all orders
const readOrders = (filters) => {
    let filterString = '';
    if (typeof filters !== 'undefined') {
        try {
            filterString = qs.stringify(convertUrlSearchParamsToObject(filters));
        } catch (e) {
            filterString = '';
        }
    }
    return axios.get(API_ORDER_URL + '?' + filterString, {
        headers: authHeader()
    }).then(res => res.data);
}
// API to count order
const countOrder = (filters) => {
    let filterString = '';
    if (typeof filters !== 'undefined') {
        try {
            filterString = qs.stringify(convertUrlSearchParamsToObject(filters));
        } catch (e) {
            filterString = '';
        }
    }
    return axios.get(API_ORDER_URL + 'count?' + filterString, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to cancel the order
const cancelOrder = (id) => {
    return axios.put(API_ORDER_URL + id + '/cancel', {}, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to process order
const processOrder = (id) => {
    return axios.put(API_ORDER_URL + id + '/process', {}, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to add note to order
const addNoteToOrder = (id, note) => {
    return axios.put(API_ORDER_URL + id + '/add-note', { note }, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to assign stock to order
const assignStockToOrder = (id, stockValues) => {
    return axios.put(API_ORDER_URL + id + '/assign-stock', stockValues, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to process order shipment
const processOrderPreparing = (id) => {
    return axios.put(API_ORDER_URL + id + '/prepare', {}, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to complete order
const completeOrder = (id) => {
    return axios.put(API_ORDER_URL + id + '/complete', {}, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to ship the order
const shipOrder = (id) => {
    return axios.put(API_ORDER_URL + id + '/ship', {}, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to refund the order
const refundOrder = (id, amount) => {
    return axios.put(API_ORDER_URL + id + '/refund', { amount }, {
        headers: authHeader()
    }).then(res => res.data);
}

const order = {
    readOrder,
    readOrders,
    countOrder,
    cancelOrder,
    processOrder,
    completeOrder,
    shipOrder,
    addNoteToOrder,
    assignStockToOrder,
    processOrderPreparing,
    refundOrder
};

export default order;