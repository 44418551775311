import React, { useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space, Modal, Form, InputNumber, Input, Collapse, Select, DatePicker, Descriptions } from 'antd';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import {
    batchProductInventory,
    readAllProductInventories,
    approveProductInventory,
    processProductInventory,
    rejectProductInventory,
    freezeProductInventory,
    adjustProductInventory,
    addLogProductInventory,
    updateRemarkProductInventory
} from '../actions/product.inventory';
import { PageHeader } from '@ant-design/pro-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import dayjs from 'dayjs';
import { green, yellow } from "@ant-design/colors";
import _, { select } from 'underscore';

function OperationProductInventoryRequests() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [extraButtons, setExtraButtons] = useState([]);
    const { Content } = Layout;
    const [adjustForm] = Form.useForm();
    const [addLogForm] = Form.useForm();
    const [updateRemarkForm] = Form.useForm();
    const [dataSource, setDataSource] = useState([]);
    const [adjustRecord, setAdjustRecord] = useState(null);
    const [adjustModalOpen, setAdjustModalOpen] = useState(false);
    const [addLogRecord, setAddLogRecord] = useState(null);
    const [addLogModalOpen, setAddLogModalOpen] = useState(false);
    const [updateRemarkRecord, setUpdateRemarkRecord] = useState(null);
    const [updateRemarkModalOpen, setUpdateRemarkModalOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterForm] = Form.useForm();

    const productDataSettings = [
        {
            slug: 'sku',
            name: t('products.columns.sku'),
            type: 'text'
        },
        {
            slug: 'name',
            name: t('products.columns.name'),
            type: 'text'
        },
        {
            slug: 'status',
            name: t('productInventories.columns.status'),
            type: 'dropdown',
            dropdownOptions: [
                {
                    label: t('productInventories.status.pending'),
                    value: 'pending'
                },
                {
                    label: t('productInventories.status.processing'),
                    value: 'processing'
                },
                {
                    label: t('productInventories.status.approved'),
                    value: 'approved'
                },
                {
                    label: t('productInventories.status.rejected'),
                    value: 'rejected'
                },
                {
                    label: t('productInventories.status.freezed'),
                    value: 'freezed'
                }
            ]
        },
        {
            slug: 'fromDate',
            name: t('productInventories.columns.fromDate'),
            type: 'date'
        },
        {
            slug: 'toDate',
            name: t('productInventories.columns.toDate'),
            type: 'date'
        }
    ];

    const applyFilters = (values) => {
        console.log('values', values);
        let newSearchParams = {};
        for (let key in values) {
            if (typeof values[key] !== 'undefined' && values[key] !== '') {
                // Check if is dayjs object
                if (dayjs.isDayjs(values[key])) {
                    // format as YYYY-MM-DD
                    newSearchParams[key] = values[key].format('YYYY-MM-DD');
                } else if (moment.isMoment(values[key])) {
                    newSearchParams[key] = values[key].format('YYYY-MM-DD');
                } else {
                    newSearchParams[key] = values[key];
                }
            }
        }
        console.log('values', values);
        setSearchParams(newSearchParams);
    }

    const clearFilters = () => {
        let newSearchParams = {};
        filterForm.resetFields();
        setSearchParams(newSearchParams);
    }

    let hasSearchParams = false;
    for (const entry of searchParams.entries()) {
        const [param] = entry;
        console.log('param', param);
        if (param !== 'page' && param !== 'perPage') {
            hasSearchParams = true;
            break;
        }
    }

    const { Panel } = Collapse;

    const showUpdateRemarkModal = (record) => {
        setUpdateRemarkRecord(record);
        setUpdateRemarkModalOpen(true);
        updateRemarkForm.setFieldsValue({
            remark: record.remark
        });
    };

    const handleUpdateRemarkModalOk = () => {
        updateRemarkForm.validateFields().then((values) => {
            dispatch(updateRemarkProductInventory(updateRemarkRecord.id, updateRemarkForm.getFieldsValue())).then((data) => {
                setDataSource(dataSource.map((item) => {
                    if (item.id === updateRemarkRecord.id) {
                        item.remark = data.result.remark;
                        item.logs = data.result.logs;
                    }
                    return item;
                }));
                message.success(t('operationProductInventoryRequests.message.updateRemark-success'));
                setUpdateRemarkModalOpen(false);
                setUpdateRemarkRecord(null);
                updateRemarkForm.resetFields();
            }).catch((error) => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    };

    const handleUpdateRemarkModalCancel = () => {
        setUpdateRemarkModalOpen(false);
        setUpdateRemarkRecord(null);
        updateRemarkForm.resetFields();
    };


    const showAddLogModal = (record) => {
        setAddLogRecord(record);
        setAddLogModalOpen(true);
        addLogForm.resetFields();
    };

    const handleAddLogModalOk = () => {
        addLogForm.validateFields().then((values) => {
            dispatch(addLogProductInventory(addLogRecord.id, addLogForm.getFieldsValue())).then((data) => {
                setDataSource(dataSource.map((item) => {
                    if (item.id === addLogRecord.id) {
                        item.logs = data.result.logs;
                    }
                    return item;
                }));
                message.success(t('operationProductInventoryRequests.message.addLog-success'));
                setAddLogModalOpen(false);
                setAddLogRecord(null);
                addLogForm.resetFields();
            }).catch((error) => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    };

    const handleAddLogModalCancel = () => {
        setAddLogModalOpen(false);
    };

    const showAdjustModal = (record) => {
        setAdjustRecord(record);
        setAdjustModalOpen(true);
        adjustForm.setFieldsValue({
            quantity: record.currentQuantity
        });
    };

    const handleAdjustModalOk = () => {
        adjustForm.validateFields().then((values) => {
            dispatch(adjustProductInventory(adjustRecord.id, adjustForm.getFieldsValue())).then((data) => {
                setDataSource(dataSource.map((item) => {
                    if (item.id === adjustRecord.id) {
                        item.currentQuantity = data.result.currentQuantity;
                        item.logs = data.result.logs;
                    }
                    return item;
                }));
                message.success(t('operationProductInventoryRequests.message.adjust-success'));
                setAdjustModalOpen(false);
                setAdjustRecord(null);
                adjustForm.resetFields();
            }).catch((error) => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    };

    const handleAdjustModalCancel = () => {
        setAdjustModalOpen(false);
        setAdjustRecord(null);
        adjustForm.resetFields();
    };

    const [showBatchButtons, setShowBatchButtons] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys) => {
        let matchedRecords = dataSource.filter((record) => newSelectedRowKeys.includes(record.id));
        if (matchedRecords.length === 0) {
            setShowBatchButtons(false);
        } else {
            let showBatchButtons = matchedRecords[0].status;
            let firstStatus = matchedRecords[0].status;
            for (let i = 1; i < matchedRecords.length; i++) {
                if (matchedRecords[i].status !== firstStatus) {
                    showBatchButtons = false;
                    break;
                }
            }
            setShowBatchButtons(showBatchButtons);
        }
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const expandRecord = (record) => {
        return <>
            <div>Stock ID: {record.id}</div>
            <Descriptions bordered column={1}>
                {record.remark && <Descriptions.Item label={t('operationProductInventoryRequests.columns.remark')} labelStyle={{ width: 240 }}>{record.remark}</Descriptions.Item>}
            </Descriptions>
            {(Array.isArray(record.logs) && record.logs.length > 0) ? <Table
                rowKey={record => record._id}
                dataSource={_.sortBy(record.logs, 'createdAt').reverse()}
                columns={[
                    {
                        title: t('operationProductInventoryRequests.columns.logs.action'),
                        dataIndex: 'action',
                        key: 'action',
                    },
                    {
                        title: t('operationProductInventoryRequests.columns.logs.note'),
                        dataIndex: 'note',
                        key: 'note',
                    },
                    {
                        title: t('operationProductInventoryRequests.columns.logs.date'),
                        dataIndex: 'date',
                        key: 'date',
                        render: (text, record) => {
                            if (typeof record.date !== 'undefined' && record.date !== null) {
                                return moment(record.date).format('DD/MM/YYYY HH:mm:ss');
                            } else {
                                return 'N/A';
                            }
                        }
                    }
                ]}
            >
            </Table> : <div>{t('logs.not-available')}</div>}
        </>
    }

    const approveRequestAction = (id) => {
        dispatch(approveProductInventory(id)).then((data) => {
            setDataSource(dataSource.map((item) => {
                if (item.id === id) {
                    item.status = 'approved';
                    item.logs = data.result.logs;
                }
                return item;
            }))
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const processRequestAction = (id) => {
        dispatch(processProductInventory(id)).then((data) => {
            setDataSource(dataSource.map((item) => {
                if (item.id === id) {
                    item.status = 'processing';
                    item.logs = data.result.logs;
                }
                return item;
            }))
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const rejectRequestAction = (id) => {
        dispatch(rejectProductInventory(id)).then((data) => {
            setDataSource(dataSource.map((item) => {
                if (item.id === id) {
                    item.status = 'rejected';
                    item.logs = data.result.logs;
                }
                return item;
            }))
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const freezeRequestAction = (id) => {
        dispatch(freezeProductInventory(id)).then((data) => {
            setDataSource(dataSource.map((item) => {
                if (item.id === id) {
                    item.status = 'freezed';
                    item.logs = data.result.logs;
                }
                return item;
            }))
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }


    const columns = [
        {
            title: t('operationProductInventoryRequests.columns.product'),
            dataIndex: 'product',
            key: 'product',
            width: '250px',
            render: (text, record) => {
                if (record.productDetail) {
                    let productDetail = [record.productDetail.sku];
                    if (typeof record.productDetail.translations !== 'undefined' && Array.isArray(record.productDetail.translations)) {
                        record.productDetail.translations.forEach((translation) => {
                            if (typeof translation.name !== 'undefined' && translation.lang === 'zh-HK') {
                                productDetail.push(<br />);
                                productDetail.push(translation.name);
                            }
                        });
                    }
                    return <div style={{ fontSize: '0.8em' }}>{productDetail}<br />&lt;<Link to={`/product/${record.product}`}>{record.product}</Link>&gt;</div>;
                } else {
                    return <><Link to={`/product/${record.product}`}>{record.product}</Link></>
                }
            }
        },
        {
            title: t('operationProductInventoryRequests.columns.owner'),
            dataIndex: 'admin',
            key: 'admin',
            render: (text, record) => <>{record.admin ? record.admin.name : 'N/A'}</>
        },
        {
            title: t('operationProductInventoryRequests.columns.warehouse'),
            dataIndex: 'warehouse',
            key: 'warehouse',
            render: (text, record) => <>{record.warehouse.name}</>
        },
        {
            title: t('operationProductInventoryRequests.columns.quantity'),
            dataIndex: 'quantity',
            key: 'quantity',
            children: [
                {
                    align: 'center',
                    title: t('operationProductInventoryRequests.columns.initialQuantity'),
                    dataIndex: 'initialQuantity',
                    key: 'initialQuantity',
                },
                {
                    align: 'center',
                    title: t('operationProductInventoryRequests.columns.currentQuantity'),
                    dataIndex: 'currentQuantity',
                    key: 'currentQuantity',
                },
                {
                    align: 'center',
                    title: t('operationProductInventoryRequests.columns.orderedQuantity'),
                    dataIndex: 'orderedQuantity',
                    key: 'orderedQuantity',
                },
            ]
        },
        {
            title: t('operationProductInventoryRequests.columns.status'),
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <>{t(`ownerProductInventories.columns.${record.status}`)}</>
        },
        {
            title: t('operationProductInventoryRequests.columns.remark'),
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            render: (text, record) => {
                if (typeof record.remark !== 'undefined' && record.remark && record.remark !== '') {
                    return t('admins.columns.yes');
                } else {
                    return t('admins.columns.no');
                }
            }
        },
        {
            title: t('operationProductInventoryRequests.columns.created'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => <>{moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</>
        },
        {
            title: t('operationProductInventoryRequests.columns.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => <Space.Compact size={[8, 8]} wrap>
                <Button style={{ backgroundColor: yellow[6] }} type="primary" onClick={() => showAddLogModal(record)}>{t('operationProductInventoryRequests.button.addLog')}</Button>
                <Button style={{ backgroundColor: green[6] }} type="primary" onClick={() => showUpdateRemarkModal(record)}>{t('operationProductInventoryRequests.button.updateRemark')}</Button>
                {(record.status === 'pending' || record.status === 'freezed') && <Popconfirm
                    title={t('operationProductInventoryRequests.confirm.process_message')}
                    onConfirm={() => processRequestAction(record.id)}
                    okText={t('operationProductInventoryRequests.confirm.process_confirm_ok')}
                    cancelText={t('operationProductInventoryRequests.confirm.process_confirm_cancel')}
                ><Button type="primary">{t('operationProductInventoryRequests.button.process')}</Button></Popconfirm>}
                {record.status === 'processing' && <Button type="primary" onClick={() => showAdjustModal(record)}>{t('operationProductInventoryRequests.button.adjust')}</Button>}
                {(record.status === 'processing' || record.status === 'freezed') && <Popconfirm
                    title={t('operationProductInventoryRequests.confirm.approve_message')}
                    onConfirm={() => approveRequestAction(record.id)}
                    okText={t('operationProductInventoryRequests.confirm.approve_confirm_ok')}
                    cancelText={t('operationProductInventoryRequests.confirm.approve_confirm_cancel')}
                ><Button type="primary">{t('operationProductInventoryRequests.button.approve')}</Button></Popconfirm>}
                {(record.status !== 'pending' && record.status !== 'freezed' && record.status !== 'rejected') && <Popconfirm
                    title={t('operationProductInventoryRequests.confirm.freeze_message')}
                    onConfirm={() => freezeRequestAction(record.id)}
                    okText={t('operationProductInventoryRequests.confirm.freeze_confirm_ok')}
                    cancelText={t('operationProductInventoryRequests.confirm.freeze_confirm_cancel')}
                ><Button type="primary">{t('operationProductInventoryRequests.button.freeze')}</Button></Popconfirm>}
                {record.status === 'processing' && <Popconfirm
                    title={t('operationProductInventoryRequests.confirm.reject_message')}
                    onConfirm={() => rejectRequestAction(record.id)}
                    okText={t('operationProductInventoryRequests.confirm.reject_confirm_ok')}
                    cancelText={t('operationProductInventoryRequests.confirm.reject_confirm_cancel')}
                ><Button type="primary" danger>{t('operationProductInventoryRequests.button.reject')}</Button></Popconfirm>}
            </Space.Compact>

        }
    ];

    useEffect(() => {
        if (dataSource.length > 0) {
            if (selectedRowKeys.length > 0) {
                let matchedRecords = dataSource.filter((record) => selectedRowKeys.includes(record.id));
                if (matchedRecords.length === 0) {
                    setShowBatchButtons(false);
                } else {
                    let showBatchButtons = matchedRecords[0].status;
                    let firstStatus = matchedRecords[0].status;
                    console.log('firstStatus', firstStatus);
                    for (let i = 1; i < matchedRecords.length; i++) {
                        if (matchedRecords[i].status !== firstStatus) {
                            showBatchButtons = false;
                            break;
                        }
                    }
                    if (showBatchButtons === false) {
                        setSelectedRowKeys([]);
                    }
                    setShowBatchButtons(showBatchButtons);
                }
            } else {
                setShowBatchButtons(false);
            }
        } else {
            setShowBatchButtons(false);
        }
    }, [dataSource]);

    useEffect(() => {
        // set form values
        searchParams.forEach((value, key) => {
            if (key === 'fromDate' || key === 'toDate') {
                // convert the date from YYYY-MM-DD to dayjs object
                filterForm.setFieldsValue({ [key]: dayjs(value) });
            } else {
                filterForm.setFieldsValue({ [key]: value });
            }
        });
    }, [searchParams, filterForm]);

    useEffect(() => {
        dispatch(readAllProductInventories(searchParams)).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
            } else {
                setDataSource([]);
            }
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch, searchParams]);
    const batchAction = (status) => {
        dispatch(batchProductInventory(selectedRowKeys, status)).then((data) => {
            dispatch(readAllProductInventories(searchParams)).then((response) => {
                if (typeof response.result !== 'undefined') {
                    setDataSource(response.result);
                } else {
                    setDataSource([]);
                }
            }).catch((error) => {
                try {
                    setDataSource([]);
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    };
    useEffect(() => {
        let newExtraButtons = [
            <Button key="new" type="primary" onClick={() => navigate(`/operation_team/inventory-request`)}>
                {t('operationProductInventoryRequests.button.new')}
            </Button>
        ];
        if (showBatchButtons && selectedRowKeys.length > 1) {
            const processingButton = <Popconfirm
                title={t('operationProductInventoryRequests.batchActionConfirm.process_message')}
                onConfirm={() => batchAction('processing')}
                okText={t('operationProductInventoryRequests.batchActionConfirm.process_confirm_ok')}
                cancelText={t('operationProductInventoryRequests.batchActionConfirm.process_confirm_cancel')}
            ><Button key="processing" type="primary">{t('operationProductInventoryRequests.button.batch.processing')}</Button></Popconfirm>;
            const freezedButton = <Popconfirm
                title={t('operationProductInventoryRequests.batchActionConfirm.process_message')}
                onConfirm={() => batchAction('freezed')}
                okText={t('operationProductInventoryRequests.batchActionConfirm.process_confirm_ok')}
                cancelText={t('operationProductInventoryRequests.batchActionConfirm.process_confirm_cancel')}
            ><Button key="freeze" type="primary">{t('operationProductInventoryRequests.button.batch.freeze')}</Button></Popconfirm>;
            const approvedButton = <Popconfirm
                title={t('operationProductInventoryRequests.batchActionConfirm.process_message')}
                onConfirm={() => batchAction('approved')}
                okText={t('operationProductInventoryRequests.batchActionConfirm.process_confirm_ok')}
                cancelText={t('operationProductInventoryRequests.batchActionConfirm.process_confirm_cancel')}
            ><Button key="approve" type="primary">{t('operationProductInventoryRequests.button.batch.approve')}</Button></Popconfirm>;
            const rejectedButton = <Popconfirm
                title={t('operationProductInventoryRequests.batchActionConfirm.process_message')}
                onConfirm={() => batchAction('rejected')}
                okText={t('operationProductInventoryRequests.batchActionConfirm.process_confirm_ok')}
                cancelText={t('operationProductInventoryRequests.batchActionConfirm.process_confirm_cancel')}
            ><Button key="reject" type="primary" danger>{t('operationProductInventoryRequests.button.batch.reject')}</Button></Popconfirm>;
            switch (showBatchButtons) {
                case "pending":
                    newExtraButtons.push(processingButton);
                    break;
                case "approved":
                    newExtraButtons.push(freezedButton);
                    break;
                case "freezed":
                    newExtraButtons.push(processingButton);
                    newExtraButtons.push(approvedButton);
                    break;
                case "processing":
                    newExtraButtons.push(approvedButton);
                    newExtraButtons.push(freezedButton);
                    newExtraButtons.push(rejectedButton);
                    break;
                case "rejected":
                    break;
            }
        }
        setExtraButtons(newExtraButtons);
    }, [selectedRowKeys, showBatchButtons]);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title={t('operationProductInventoryRequests.new_title')}
                        subTitle={t('operationProductInventoryRequests.new_subtitle')}
                        extra={extraButtons}
                    />
                    <p className="list-note" dangerouslySetInnerHTML={{ __html: t('operationProductInventoryRequests.description') }}></p>
                    <Collapse style={{ marginTop: 10, marginBottom: 10 }} bordered={false} defaultActiveKey={hasSearchParams ? ['filter'] : []}>
                        <Panel header={<strong>{t('products.filter.title')}</strong>} key="filter">
                            <Form form={filterForm} layout="inline" onFinish={applyFilters}>
                                <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key="_id" name="_id" label="ID">
                                    <Input />
                                </Form.Item>
                                {productDataSettings.map(field => {
                                    switch (field.type) {
                                        case 'checkbox':
                                        case 'radio':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Select allowClear={true}>{field.choiceOptions && field.choiceOptions.map(option => <Select.Option key={option} value={option.value}>{option.label}</Select.Option>)}</Select>
                                            </Form.Item>;
                                        case 'dropdown':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Select allowClear={true}>{field.dropdownOptions && field.dropdownOptions.map(option => <Select.Option key={option} value={option.value}>{option.label}</Select.Option>)}</Select>
                                            </Form.Item>;
                                        case 'number':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <InputNumber />
                                            </Form.Item>;
                                        case 'email':
                                        case 'primary_email':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} rules={[{ type: 'email' }]}>
                                                <Input />
                                            </Form.Item>;
                                        case 'date':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} rules={[{ type: 'date' }]}>
                                                <DatePicker />
                                            </Form.Item>;
                                        case 'text':
                                        case 'textarea':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Input />
                                            </Form.Item>;
                                        case 'date':
                                            return <Form.Item
                                                style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}
                                                getValueProps={(i) => ({ value: moment(i).format('YYYY-MM-DD') })}
                                            >
                                                <DatePicker format={{
                                                    format: 'YYYY-MM-DD',
                                                    type: 'mask',
                                                }} />
                                            </Form.Item>;
                                        case 'boolean':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} valuePropName="checked">
                                                <Select allowClear={true}>
                                                    <Select.Option value="true">True</Select.Option>
                                                    <Select.Option value="false">False</Select.Option>
                                                </Select>
                                            </Form.Item>;
                                        default:
                                            console.log(`Please contact admin to add support for ${field.name} <${field.slug}|${field.type}> field type`);
                                            return null;
                                    }
                                })}
                                <Form.Item style={{ width: '100%' }}>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            {t('products.filter.apply')}
                                        </Button>
                                        <Button type="primary" danger onClick={clearFilters}>
                                            {t('products.filter.clear')}
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </Panel>
                    </Collapse>
                    <Table
                        size="small"
                        rowKey={record => record.id}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: onSelectChange,
                        }}
                        expandable={{
                            expandedRowRender: expandRecord,
                            rowExpandable: () => true
                        }}
                        dataSource={dataSource}
                        columns={columns} />
                    <Modal okText={t('modal.ok-text')} cancelText={t('modal.cancel-text')} title={t('operationProductInventoryRequests.addLog.title')} open={addLogModalOpen} onOk={handleAddLogModalOk} onCancel={handleAddLogModalCancel}>
                        <p>{t('operationProductInventoryRequests.addLog.description')}</p>
                        <Form form={addLogForm} name="addLog-form">
                            <Form.Item
                                name="log"
                                rules={[
                                    {
                                        required: true,
                                        message: t('operationProductInventoryRequests.addLog.log.required'),
                                    }
                                ]}
                            >
                                <Input.TextArea placeholder={t('operationProductInventoryRequests.addLog.log.placeholder')} />
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal okText={t('modal.ok-text')} cancelText={t('modal.cancel-text')} title={t('operationProductInventoryRequests.updateRemark.title')} open={updateRemarkModalOpen} onOk={handleUpdateRemarkModalOk} onCancel={handleUpdateRemarkModalCancel}>
                        <p>{t('operationProductInventoryRequests.updateRemark.description')}</p>
                        <Form form={updateRemarkForm} name="updateRemark-form">
                            <Form.Item
                                name="remark"
                                rules={[
                                    {
                                        required: true,
                                        message: t('operationProductInventoryRequests.updateRemark.log.required'),
                                    }
                                ]}
                            >
                                <Input.TextArea placeholder={t('operationProductInventoryRequests.updateRemark.log.placeholder')} />
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal okText={t('modal.ok-text')} cancelText={t('modal.cancel-text')} title={t('operationProductInventoryRequests.adjustModal.title')} open={adjustModalOpen} onOk={handleAdjustModalOk} onCancel={handleAdjustModalCancel}>
                        <p>{t('operationProductInventoryRequests.adjustModal.description')}</p>
                        <Form form={adjustForm} name="adjust-form">
                            <Form.Item>
                                <div>{t('operationProductInventoryRequests.adjustModal.quantity.label')}</div>
                                <Form.Item
                                    name="quantity"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('operationProductInventoryRequests.adjustModal.quantity.required'),
                                        }
                                    ]}>
                                    <InputNumber min={-100} placeholder={t('operationProductInventoryRequests.adjustModal.quantity.placeholder')} />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item>
                                <div>{t('operationProductInventoryRequests.adjustModal.reason.label')}</div>
                                <Form.Item
                                    name="reason"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('operationProductInventoryRequests.adjustModal.reason.required'),
                                        }
                                    ]}
                                >
                                    <Input.TextArea placeholder={t('operationProductInventoryRequests.adjustModal.reason.placeholder')} />
                                </Form.Item>
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default OperationProductInventoryRequests;