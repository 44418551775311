import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Form, Input, Switch, Tabs, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useParams, Link } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { createWarehouse, readWarehouses, readWarehouse, updateWarehouse } from '../actions/warehouse';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};
const supportedLanguages = [
    { id: 'en-US', name: 'English ( en-US )' },
    { id: 'zh-HK', name: 'Traditional Chinese ( zh-HK )' },
];
const { Option } = Select;
const defaultRobotsTxtContent = 'User-agent: *\nDisallow: /';

function Warehouse() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { Content } = Layout;
    const [form] = Form.useForm();

    const [warehouseTitle, setWarehouseTitle] = useState('');
    useEffect(() => {
        if (typeof params.id !== 'undefined') {
            setWarehouseTitle('Edit Warehouse');
            dispatch(readWarehouse(params.id)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setWarehouseTitle('Edit Warehouse - ' + response.result.name);
                    form.setFieldsValue(response.result);
                } else {
                    message.error(t('warehouse.message.read-error'));
                    navigate('/warehouses');
                }
            }).catch(e => {
                message.error(t('warehouse.message.read-error'));
                navigate('/warehouses');
            });
        } else {
            setWarehouseTitle(t('warehouse.title.new-warehouse'));
        }
    }, [params.id, dispatch, navigate, form]);

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        if (typeof errorInfo.errorFields !== 'undefined') {
            errorInfo.errorFields.forEach((error) => {
                message.error(error.errors[0]);
            });
        }
    };

    const onFinish = (values) => {
        if (typeof params.id !== 'undefined') {
            // Update the existing warehouse
            dispatch(updateWarehouse(params.id, values)).then((response) => {
                if (response.result) {
                    setWarehouseTitle('Edit Warehouse - ' + response.result.name);
                    form.setFieldsValue(response.result);
                    dispatch(readWarehouses());
                }
                message.success(t('warehouse.message.updated-successfully'));
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        } else {
            // Create new warehouse
            dispatch(createWarehouse(values)).then((data) => {
                console.log(data.result);
                message.success(t('warehouse.message.created-successfully'));
                if (data.result) {
                    navigate('/warehouse/' + data.result.id);
                } else {
                    navigate('/warehouses');
                }
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    };

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <Form {...layout} form={form} name="warehouse" onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={{
                    }}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate('/warehouses')}
                            title={warehouseTitle}
                            extra={[
                                <Button key="cancel" type="primary" danger onClick={() => navigate('/warehouses')}>{t('warehouse.actions.cancel')}</Button>,
                                <Button key="save" type="primary" htmlType="submit">{params.id ? t('warehouse.actions.update') : t('warehouse.actions.save')}</Button>
                            ]}
                        />
                        <Form.Item
                            name="name"
                            label={t('warehouse.field.name.label')}
                            rules={[{ required: true, message: t('warehouse.field.name.required') }]}
                        >
                            <Input placeholder={t('warehouse.field.name.placeholder')} />
                        </Form.Item>
                        <Form.Item
                            name="slug"
                            label={t('warehouse.field.slug.label')}
                            rules={[
                                { required: true, message: t('warehouse.field.slug.required') },
                                { pattern: /^[a-zA-Z0-9\-\_]+$/, message: t('warehouse.field.slug.required-pattern') }
                            ]}
                        >
                            <Input placeholder={t('warehouse.field.slug.placeholder')} />
                        </Form.Item>
                    </Form>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Warehouse;