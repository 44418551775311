import authHeader from './auth-header';
import axios from "axios";
import { API_WAREHOUSE_URL } from "./config";

// API to get warhouse by id
const readWarehouse = (id) => {
    return axios.get(API_WAREHOUSE_URL + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all warhouses
const readWarehouses = () => {
    return axios.get(API_WAREHOUSE_URL, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to create warhouse
const createWarehouse = (warehouse) => {
    return axios
        .post(API_WAREHOUSE_URL, warehouse, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update warhouse
const updateWarehouse = (id, warehouse) => {
    return axios
        .put(API_WAREHOUSE_URL + id, warehouse, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete warhouse
const deleteWarehouse = (id) => {
    return axios
        .delete(API_WAREHOUSE_URL + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const warehouses = {
    createWarehouse,
    readWarehouse,
    readWarehouses,
    updateWarehouse,
    deleteWarehouse
};

export default warehouses;