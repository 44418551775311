import React, { useEffect, useState } from 'react';
import { Button, Descriptions, Layout, Table, message } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { readDeliveryNote } from '../actions/delivery.note';
import { PageHeader } from '@ant-design/pro-components';
import { useTranslation } from 'react-i18next';

function WarehouseDeliveryNote() {
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Content } = Layout;
    const [deliveryNoteTitle, setDeliveryNoteTitle] = useState(`${t('deliveryNote.title')}`);
    const [dataSource, setDataSource] = useState(null);

    const productColumns = [
        {
            title: t('deliveryNote.section.products.sku.label'),
            dataIndex: 'sku',
            key: 'sku',
            render: (text, record) => {
                if (typeof record.product === 'object') {
                    return <Link to={`/products/${record.product._id}`}>{record.product.sku}</Link>;
                } else {
                    return record.snapshot.sku;
                }
            }
        },
        {
            title: t('deliveryNote.section.products.name.label'),
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                let productName = '';
                // check product isobject
                if (typeof record.product === 'object' && typeof record.product._id !== 'undefined') {
                    // Find the translations zh-hk
                    let translations = record.product.translations.filter((translation) => {
                        return translation.lang === 'zh-HK';
                    });
                    if (translations.length > 0) {
                        productName = translations[0].name;
                    } else {
                        productName = record.product.name;
                    }
                } else {
                    productName = record.snapshot.name;
                }
                return <Link to={`/product/${typeof record.product === 'object' ? record.product._id : record.product}`}>{productName}</Link>;
            }
        },
        {
            title: t('deliveryNote.section.products.quantity.label'),
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text, record) => {
                if (typeof record.inventories !== 'undefined') {
                    // sum up the quantity
                    let quantity = 0;
                    record.inventories.forEach((inventory) => {
                        quantity += inventory.quantity;
                    });
                    return quantity;
                } else {
                    return 0;
                }
            }
        }
    ]

    useEffect(() => {
        dispatch(readDeliveryNote(params.warehouseManagerId, params.id)).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
                setDeliveryNoteTitle(`${t('deliveryNote.title')} #${response.result.id}`);
            } else {
                setDataSource(null);
                setDeliveryNoteTitle(`${t('deliveryNote.title')} #${params.id}`);
            }
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, []);

    const expandedRowRender = (record) => {
        console.log(record);
        return <Table
            size="small"
            dataSource={record.inventories}
            columns={[
                {
                    title: t('deliveryNote.section.products.inventories.id.label'),
                    dataIndex: 'id',
                    key: 'id',
                    render: (text, record) => {
                        return <Link to={`/inventories/${record.inventory._id}`}>{record.inventory._id}</Link>;
                    }
                },
                {
                    title: t('deliveryNote.section.products.inventories.admin.label'),
                    dataIndex: 'admin',
                    key: 'admin',
                    render: (text, record) => {
                        return record.inventory.admin ? record.inventory.admin.name : 'N/A';
                    }
                },
                {
                    title: t('deliveryNote.section.products.inventories.createdAt.label'),
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    render: (text, record) => {
                        return record.inventory.createdAt;
                    }
                },
                {
                    title: t('deliveryNote.section.products.inventories.quantity.label'),
                    dataIndex: 'quantity',
                    key: 'quantity'
                },
                {
                    title: t('deliveryNote.section.products.inventories.remark.label'),
                    dataIndex: 'remark',
                    key: 'remark',
                    render: (text, record) => {
                        return record.inventory.remark;
                    }
                }
            ]}
        />
    }

    const printDeliveryNote = () => {
        if (window) window.print();
    }

    return <>
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        title={deliveryNoteTitle}
                        extra={[
                            <Button key="download" type="primary" onClick={() => alert(t('no-support-yet'))}>{t('deliveryNote.actions.download')}</Button>,
                            <Button key="print" type="primary" onClick={printDeliveryNote}>{t('deliveryNote.actions.print')}</Button>
                        ]}
                    />
                    {dataSource && <div style={{ padding: 16 }}>
                        <section>
                            <h2>{t('deliveryNote.section.title.order')}</h2>
                            <Descriptions bordered column={1}>
                                <Descriptions.Item label={t('deliveryNote.section.order.id.label')} labelStyle={{ width: 240 }}>{dataSource?.order?._id}</Descriptions.Item>
                                <Descriptions.Item label={t('deliveryNote.section.order.user.label')} labelStyle={{ width: 240 }}>{(dataSource && dataSource.snapshotUser) ? `${dataSource.snapshotUser.lastName}, ${dataSource.snapshotUser.firstName} <${dataSource.snapshotUser._id}>` : ''}</Descriptions.Item>
                                <Descriptions.Item label={t('deliveryNote.section.order.warehouse.label')} labelStyle={{ width: 240 }}>{(dataSource && dataSource.warehouse) ? `${dataSource.warehouse.name}` : ''}</Descriptions.Item>
                            </Descriptions>
                        </section>
                        <section style={{ marginTop: 32 }}>
                            <h2>{t('deliveryNote.section.title.shipping')}</h2>
                            {dataSource && dataSource.snapshotShipping && <Descriptions column={1} bordered>
                                {['name', 'phone', 'email', 'address1', 'address2', 'region', 'city', 'zip', 'country'].map(field => {
                                    switch (field) {
                                        case 'name':
                                            return <Descriptions.Item label={t('deliveryNote.section.shipping.' + field + '.label')} labelStyle={{ width: 240 }}>{`${dataSource.snapshotShipping.lastName}, ${dataSource.snapshotShipping.firstName}`}</Descriptions.Item>;
                                        case 'phone':
                                            return <Descriptions.Item label={t('deliveryNote.section.shipping.' + field + '.label')} labelStyle={{ width: 240 }}>{`${dataSource.snapshotShipping.phoneRegion} ${dataSource.snapshotShipping.phoneNumber}`}</Descriptions.Item>;
                                        default:
                                            return <Descriptions.Item label={t('deliveryNote.section.shipping.' + field + '.label')} labelStyle={{ width: 240 }}>{dataSource.snapshotShipping[field]}</Descriptions.Item>;
                                    }
                                })}
                            </Descriptions>
                            }
                        </section>
                        <section style={{ marginTop: 32 }}>
                            <h2>{t('deliveryNote.section.title.products')}</h2>
                            {dataSource && dataSource.snapshotProducts && <Table
                                size="small"
                                dataSource={dataSource.snapshotProducts}
                                columns={productColumns}
                                pagination={false}
                                expandable={{
                                    defaultExpandAllRows: true,
                                    expandedRowRender: expandedRowRender,
                                    rowExpandable: record => record.inventories.length > 0
                                }}
                            />}
                        </section>
                    </div>}
                </div>
            </Content>
        </Layout>
    </>;
}

export default WarehouseDeliveryNote;