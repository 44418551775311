export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const FIRST_UPDATE_PASSWORD_SUCCESS = "FIRST_UPDATE_PASSWORD_SUCCESS";
export const FIRST_UPDATE_PASSWORD_FAIL = "FIRST_UPDATE_PASSWORD_FAIL";

export const PROFILE_UPDATE_NAME_SUCCESS = "PROFILE_UPDATE_NAME_SUCCESS";
export const PROFILE_UPDATE_NAME_FAIL = "PROFILE_UPDATE_NAME_FAIL";

export const CURRENT_USER_SUCCESS = "CURRENT_USER_SUCCESS";
export const CURRENT_USER_FAIL = "CURRENT_USER_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";