import authHeader from './auth-header';
import axios from "axios";
import qs from 'qs';
import { API_USER_URL } from "./config";
import { convertUrlSearchParamsToObject } from '../helpers/url';

// API to get user by id
const readUser = (id) => {
    return axios.get(API_USER_URL + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all users
const readUsers = (filters) => {
    let filterString = '';
    if (typeof filters !== 'undefined') {
        try {
            filterString = qs.stringify(convertUrlSearchParamsToObject(filters));
        } catch (e) {
            filterString = '';
        }
    }
    return axios.get(API_USER_URL + '?' + filterString, {
        headers: authHeader()
    }).then(res => res.data);
}
// API to count user
const countUser = (filters) => {
    let filterString = '';
    if (typeof filters !== 'undefined') {
        try {
            filterString = qs.stringify(convertUrlSearchParamsToObject(filters));
        } catch (e) {
            filterString = '';
        }
    }
    return axios.get(API_USER_URL + 'count?' + filterString, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to update user
const updateUser = (id, data) => {
    return axios.put(API_USER_URL + id, data, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to create user
const createUser = (data) => {
    return axios.post(API_USER_URL, data, {
        headers: authHeader()
    }).then(res => res.data);
}

const user = {
    readUser,
    readUsers,
    countUser,
    createUser,
    updateUser
};

export default user;