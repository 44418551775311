import ProductService from "../services/product.service";

// Action to get product by id
export const readProduct = (id) => (dispatch) => {
    return ProductService.readProduct(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to count product
export const countProduct = (searchParams) => (dispatch) => {
    return ProductService.countProduct(searchParams).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all products
export const readProducts = (searchParams) => (dispatch) => {
    return ProductService.readProducts(searchParams).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete product
export const deleteProduct = (id) => (dispatch) => {
    return ProductService.deleteProduct(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to publish product
export const publishProduct = (id) => (dispatch) => {
    return ProductService.publishProduct(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to unpublish product
export const unpublishProduct = (id) => (dispatch) => {
    return ProductService.unpublishProduct(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to sell product
export const sellProduct = (id) => (dispatch) => {
    return ProductService.sellProduct(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to unsell product
export const unsellProduct = (id) => (dispatch) => {
    return ProductService.unsellProduct(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create product
export const createProduct = (data) => (dispatch) => {
    return ProductService.createProduct(data).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to update product
export const updateProduct = (id, data) => (dispatch) => {
    return ProductService.updateProduct(id, data).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to refresh product inventory cache
export const refreshProductInventoryCaches = () => (dispatch) => {
    return ProductService.refreshProductInventoryCaches().then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}