import React, { useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space, Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch, useSelector } from 'react-redux';
import { readAdmins, deleteAdmin, disableAdmin, resetAdmin, enableAdmin } from '../actions/admin';
import { isRoot, isAdmin, isRootOrAdmin } from '../helpers/user';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function Admins() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { Content } = Layout;
    const currentUser = useSelector(state => state.auth.user);
    const [dataSource, setDataSource] = useState([]);


    const resetAdminAction = (id) => {
        dispatch(resetAdmin(id)).then(response => {
            message.success(t('admins.reset_success'));
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }
    const deleteAdminAction = (id) => {
        dispatch(deleteAdmin(id)).then(response => {
            message.success(t('admins.delete_success'));
            setDataSource(dataSource.filter(item => item.id !== id));
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }
    const enableAdminAction = (id) => {
        dispatch(enableAdmin(id)).then(response => {
            message.success(t('admins.enable_success'));
            setDataSource(dataSource.map(item => {
                if (item.id === id) {
                    item.status = true;
                }
                return item;
            }));
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });

    }
    const disableAdminAction = (id) => {
        dispatch(disableAdmin(id)).then(response => {
            message.success(t('admins.disable_success'));
            setDataSource(dataSource.map(item => {
                if (item.id === id) {
                    item.status = false;
                }
                return item;
            }));
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const columns = [
        {
            title: t('admins.columns.name'),
            dataIndex: 'name',
            key: 'name',
            // render: (text, record) => <div style={{ fontSize: '0.8em' }}>{text}</div>
        },
        {
            title: t('admins.columns.email'),
            dataIndex: 'email',
            key: 'email',
            // render: (text, record) => <div style={{fontSize: '0.8em'}}>{text}</div>
        },
        {
            title: t('admins.columns.isRoot'),
            dataIndex: 'isRoot',
            key: 'isRoot',
            render: (text, record) => {
                if (record.isRoot) {
                    return t('admins.columns.yes');
                } else {
                    return t('admins.columns.no');
                }
            }
        },
        {
            title: t('admins.columns.status'),
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (record.status) {
                    return t('admins.columns.active');
                } else {
                    return t('admins.columns.inactive');
                }
            }
        },
        {
            title: t('admins.columns.needChangePassword'),
            dataIndex: 'needChangePassword',
            key: 'needChangePassword',
            render: (text, record) => {
                if (record.needChangePassword) {
                    return t('admins.columns.yes');
                } else {
                    return t('admins.columns.no');
                }
            }
        },
        {
            title: t('admins.columns.role'),
            dataIndex: 'role',
            key: 'role',
            render: (text, record) => {
                if (typeof record.role !== 'undefined' && record.role) {
                    return <Tag>{t(`role.${record.role}`)}</Tag>;
                } else {
                    return '-';
                }
            }
        },
        {
            title: t('admins.columns.warehouses'),
            dataIndex: 'warehouses',
            key: 'warehouses',
            render: (text, record) => {
                if (typeof record.warehouses !== 'undefined' && record.warehouses) {
                    return record.warehouses.map(item => {
                        return <Tag key={item.id}>{item.name}</Tag>
                    });
                } else {
                    return '-';
                }
            }
        },
        {
            title: t('admins.columns.byPassTokenExpiredDate'),
            dataIndex: 'byPassTokenExpiredDate',
            key: 'byPassTokenExpiredDate',
            render: (text, record) => {
                if (record.byPassTokenExpiredDate) {
                    return moment(record.byPassTokenExpiredDate).format('YYYY-MM-DD HH:mm:ss');
                } else {
                    return '-';
                }
            }

        },
        {
            title: t('admins.columns.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                // let canAssignThisUser = false;
                let canDeleteThisUser = false;
                let canUpdateThisUser = false;
                let canTriggerResetPassword = false;
                let canEnableDisableThisUser = false;

                if (isRoot(currentUser)) {
                    //  Current user is root
                    canDeleteThisUser = true;
                    canUpdateThisUser = true;
                    canTriggerResetPassword = true;
                    canEnableDisableThisUser = true;
                    if (!isRootOrAdmin(record)) {
                        // Admin cannot be assigned
                        // canAssignThisUser = true;
                    }
                } else if (isAdmin(currentUser)) {
                    // Not root but admin
                    // Current user is top admin
                    if (!isRootOrAdmin(record)) {
                        // Current record is not root or top admin
                        // canAssignThisUser = true;
                        canDeleteThisUser = true;
                        canUpdateThisUser = true;
                        canTriggerResetPassword = true;
                        canEnableDisableThisUser = true;
                    }
                }
                // Cannot do anything for the current user
                if (currentUser.id === record.id) {
                    // canAssignThisUser = false;
                    canDeleteThisUser = false;
                    canUpdateThisUser = false;
                    canTriggerResetPassword = false;
                    canEnableDisableThisUser = false;
                }
                return <Space.Compact block size='middle'>
                    {canTriggerResetPassword && <Popconfirm
                        title={t('admins.confirm.reset_password_message')}
                        onConfirm={() => resetAdminAction(record.id)}
                        okText={t('admins.confirm.reset_password_confirm_ok')}
                        cancelText={t('admins.confirm.reset_password_confirm_cancel')}
                    >
                        <Button type="primary">{t('admins.button.reset_password')}</Button>
                    </Popconfirm>}
                    {canUpdateThisUser && <Button type="primary" onClick={() => navigate(`/admin/${record.id}`)}>{t('admins.button.edit')}</Button>}
                    {canEnableDisableThisUser && (record.status ? <Popconfirm
                        title={t('admins.confirm.disable_message')}
                        onConfirm={() => disableAdminAction(record.id)}
                        okText={t('admins.confirm.disable_confirm_ok')}
                        cancelText={t('admins.confirm.disable_confirm_cancel')}
                    ><Button type="primary" danger>{t('admins.button.disable')}</Button></Popconfirm> : <Popconfirm
                        title={t('admins.confirm.enable_message')}
                        onConfirm={() => enableAdminAction(record.id)}
                        okText={t('admins.confirm.enable_confirm_ok')}
                        cancelText={t('admins.confirm.enable_confirm_cancel')}
                    ><Button type="primary">{t('admins.button.enable')}</Button></Popconfirm>)}
                    {canDeleteThisUser && <Popconfirm
                        title={t('admins.confirm.delete_message')}
                        onConfirm={() => deleteAdminAction(record.id)}
                        okText={t('admins.confirm.delete_confirm_ok')}
                        cancelText={t('admins.confirm.delete_confirm_cancel')}
                    >
                        <Button danger type="primary">{t('admins.button.delete')}</Button>
                    </Popconfirm>}
                </Space.Compact>
            }
        }
    ];

    useEffect(() => {
        dispatch(readAdmins()).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
            } else {
                setDataSource([]);
            }
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch]);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title={t('admins.title')}
                        subTitle={t('admins.subtitle')}
                        extra={[
                            <Button key="create" type="primary" onClick={() => navigate('/admin')}>{t('admins.button.new')}</Button>
                        ]}
                    />
                    <Table
                        size='small'
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns}
                    />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Admins;