import authHeader from './auth-header';
import axios from "axios";
import { API_MEDIA_URL } from "./config";

// API to read all media by entity Id
const readMedia = (params) => {
    let query = '?1=1';
    if (typeof params !== 'undefined') {
        for (let key in params) {
            query += `&${key}=${params[key]}`;
        }
    }
    return axios.get(API_MEDIA_URL + query, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to delete media
const deleteMedia = (mediaId) => {
    return axios.delete(API_MEDIA_URL + mediaId, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to count all media
const countMedia = () => {
    return axios.get(API_MEDIA_URL + 'count', {
        headers: authHeader()
    }).then(res => res.data);
}

const media = {
    readMedia,
    countMedia,
    deleteMedia
}

export default media;