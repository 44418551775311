import React, { useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Select, Button, Table, Space, Form, Collapse, Input, InputNumber, DatePicker, Modal } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readOrders, countOrder, cancelOrder, completeOrder, shipOrder, refundOrder } from '../actions/order';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { green, cyan } from '@ant-design/colors';

function Orders() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { Content } = Layout;
    const [dataSource, setDataSource] = useState([]);
    const [dataSourceCount, setDataSourceCount] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [reloadFlag, setReloadFlag] = useState(new Date());
    const defaultPerPageState = searchParams.get('perPage') || 40;
    const [perPage, setPerPage] = useState(defaultPerPageState);
    const defaultPageState = searchParams.get('page') || 1;
    const [page, setPage] = useState(defaultPageState);
    const [filterForm] = Form.useForm();
    const [refundForm] = Form.useForm();
    const [refundModalOpen, setRefundModalOpen] = useState(false);
    const [refundOrderId, setRefundOrderId] = useState(null);

    const refundOrderAction = (record) => {
        setRefundOrderId(record.id);
        refundForm.setFieldsValue({ refundedAmount: record.subtotal });
        setRefundModalOpen(true);
    }

    const handleRefundModalOk = () => {
        refundForm.validateFields().then(() => {
            dispatch(refundOrder(refundOrderId, refundForm.getFieldValue('refundedAmount'))).then(response => {
                message.success(t('orders.refund_success'));
                setRefundOrderId(null);
                refundForm.resetFields();
                setRefundModalOpen(false);
                setReloadFlag(new Date());
            }).catch(error => {
                setReloadFlag(new Date());
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }).catch(error => {
            console.log('Failed:', error);
        });
    }
    const handleRefundModalCancel = () => {
        setRefundOrderId(null);
        refundForm.resetFields();
        setRefundModalOpen(false);
    }

    const shipOrderAction = (id) => {
        dispatch(shipOrder(id)).then(response => {
            message.success(t('orders.shipping_success'));
            setReloadFlag(new Date());
        }).catch(error => {
            setReloadFlag(new Date());
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const completeOrderAction = (id) => {
        dispatch(completeOrder(id)).then(response => {
            message.success(t('orders.complete_success'));
            setReloadFlag(new Date());
        }).catch(error => {
            setReloadFlag(new Date());
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const cancelOrderAction = (id) => {
        dispatch(cancelOrder(id)).then(response => {
            message.success('Order is cancelled successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            setReloadFlag(new Date());
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const columns = [
        {
            title: t('orders.columns.id'),
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: t('orders.columns.user'),
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => {
                return <Link to={`/user/${record.user._id}`}>{record.user.lastName}, {record.user.firstName}<br /><div style={{ fontSize: '0.9em' }}>&lt;{record.user._id}&gt;</div></Link>
            }
        },
        {
            title: t('orders.columns.total'),
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            render: (text, record) => {
                if(record.status === 'refunded') {
                    return <><div>{record.total.toFixed(2)}</div><div>(-{record.refundedAmount.toFixed(2)})</div></>;
                }else{
                    return record.total.toFixed(2);
                }
            }
        },
        {
            title: t('orders.columns.payment_method'),
            dataIndex: 'payment_method',
            key: 'payment_method',
            align: 'center',
            render: (text, record) => {
                return t('orders.payment_method.' + record.payment_method);
            }
        },
        {
            title: t('orders.columns.status'),
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text, record) => {
                return t('orders.status.' + record.status);
            }
        },
        {
            title: t('orders.columns.createdAt'),
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: t('orders.columns.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return <Space>
                    <Button type="primary" onClick={() => navigate(`/order/${record.id}`)}>{t('orders.button.edit')}</Button>
                    {!['completed', 'shipping', 'refunded', 'cancelled'].includes(record.status) && <Popconfirm
                        title={t('orders.confirm.cancel_message')}
                        onConfirm={() => cancelOrderAction(record.id)}
                        okText={t('orders.confirm.cancel_confirm_ok')}
                        cancelText={t('orders.confirm.cancel_confirm_cancel')}
                    ><Button type="primary" danger>{t('orders.button.cancel')}</Button></Popconfirm>}
                    {!['pending', 'shipping', 'completed', 'cancelled', 'refunded'].includes(record.status) && <Popconfirm
                        title={t('orders.confirm.refund_message')}
                        onConfirm={() => refundOrderAction(record)}
                        okText={t('orders.confirm.refund_confirm_ok')}
                        cancelText={t('orders.confirm.refund_confirm_cancel')}
                    ><Button type="primary" danger>{t('orders.button.refund')}</Button></Popconfirm>}
                    {record.status === 'preparing' && <Popconfirm
                        title={t('orders.confirm.shipping_message')}
                        onConfirm={() => shipOrderAction(record.id)}
                        okText={t('orders.confirm.shipping_confirm_ok')}
                        cancelText={t('orders.confirm.shipping_confirm_cancel')}
                    >
                        <Button type="primary" style={{ backgroundColor: green[5] }}>{t('orders.button.shipping')}</Button>
                    </Popconfirm>}
                    {record.status === 'shipping' && <Popconfirm
                        title={t('orders.confirm.complete_message')}
                        onConfirm={() => completeOrderAction(record.id)}
                        okText={t('orders.confirm.complete_confirm_ok')}
                        cancelText={t('orders.confirm.complete_confirm_cancel')}
                    >
                        <Button type="primary" style={{ backgroundColor: cyan[5] }}>{t('orders.button.complete')}</Button></Popconfirm>}
                </Space>
            }
        }
    ];

    useEffect(() => {
        // set form values
        searchParams.forEach((value, key) => {
            filterForm.setFieldsValue({ [key]: value });
        });
    }, [searchParams, filterForm]);

    useEffect(() => {
        dispatch(countOrder(searchParams)).then(function (response) {
            if (typeof response.result !== 'undefined') {
                setDataSourceCount(response.result);
            } else {
                message.error('Error while fetching media');
            }
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
        dispatch(readOrders(searchParams)).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
            } else {
                setDataSource([]);
            }
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [reloadFlag, dispatch, navigate, searchParams]);

    const { Panel } = Collapse;

    const orderDataSettings = [
        {
            slug: 'user',
            name: t('orders.columns.userId'),
            type: 'text'
        },
        {
            slug: 'username',
            name: t('orders.columns.userName'),
            type: 'text'
        },
        {
            slug: 'userphone',
            name: t('orders.columns.userPhone'),
            type: 'text'
        },
        {
            slug: 'status',
            name: t('orders.columns.status'),
            type: 'dropdown',
            dropdownOptions: [
                {
                    label: t('orders.status.pending'),
                    value: 'pending'
                },
                {
                    label: t('orders.status.paid'),
                    value: 'paid'
                },
                {
                    label: t('orders.status.processing'),
                    value: 'processing'
                },
                {
                    label: t('orders.status.preparing'),
                    value: 'preparing'
                },
                {
                    label: t('orders.status.shipping'),
                    value: 'shipping'
                },
                {
                    label: t('orders.status.cancelled'),
                    value: 'cancelled'
                },
                {
                    label: t('orders.status.completed'),
                    value: 'completed'
                }, {
                    label: t('orders.status.refunded'),
                    value: 'refunded'
                },
            ]
        }
    ];

    const applyFilters = (values) => {
        console.log('values', values);
        let newSearchParams = Object.assign({}, { page, perPage });
        for (let key in values) {
            if (typeof values[key] !== 'undefined' && values[key] !== '') {
                if (moment.isMoment(values[key])) {
                    newSearchParams[key] = values[key].format('YYYY-MM-DD');
                } else {
                    newSearchParams[key] = values[key];
                }
            }
        }
        console.log('values', values);
        setSearchParams(newSearchParams);
    }

    const clearFilters = () => {
        let newSearchParams = Object.assign({}, { page, perPage });
        filterForm.resetFields();
        setSearchParams(newSearchParams);
    }

    let hasSearchParams = false;
    for (const entry of searchParams.entries()) {
        const [param] = entry;
        console.log('param', param);
        if (param !== 'page' && param !== 'perPage') {
            hasSearchParams = true;
            break;
        }
    }

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title={t('orders.title')}
                        subTitle={t('orders.subtitle')}
                    />
                    <Collapse style={{ marginTop: 10 }} bordered={false} defaultActiveKey={hasSearchParams ? ['filter'] : []}>
                        <Panel header={<strong>{t('orders.filter.title')}</strong>} key="filter">
                            <Form form={filterForm} layout="inline" onFinish={applyFilters}>
                                <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key="_id" name="_id" label="ID">
                                    <Input />
                                </Form.Item>
                                {orderDataSettings.map(field => {
                                    switch (field.type) {
                                        case 'checkbox':
                                        case 'radio':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Select allowClear={true}>{field.choiceOptions && field.choiceOptions.map(option => <Select.Option key={option} value={option.value}>{option.label}</Select.Option>)}</Select>
                                            </Form.Item>;
                                        case 'dropdown':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Select allowClear={true}>{field.dropdownOptions && field.dropdownOptions.map(option => <Select.Option key={option} value={option.value}>{option.label}</Select.Option>)}</Select>
                                            </Form.Item>;
                                        case 'number':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <InputNumber />
                                            </Form.Item>;
                                        case 'email':
                                        case 'primary_email':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} rules={[{ type: 'email' }]}>
                                                <Input />
                                            </Form.Item>;
                                        case 'date':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} rules={[{ type: 'date' }]}>
                                                <DatePicker />
                                            </Form.Item>;
                                        case 'text':
                                        case 'textarea':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Input />
                                            </Form.Item>;
                                        case 'boolean':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} valuePropName="checked">
                                                <Select allowClear={true}>
                                                    <Select.Option value="true">True</Select.Option>
                                                    <Select.Option value="false">False</Select.Option>
                                                </Select>
                                            </Form.Item>;
                                        default:
                                            console.log(`Please contact admin to add support for ${field.name} <${field.slug}|${field.type}> field type`);
                                            return null;
                                    }
                                })}
                                {/* <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key="createdAt" name="createdAt" label="Created At" rules={[{ type: 'date' }]}>
                                    <DatePicker />
                                </Form.Item> */}
                                <Form.Item style={{ width: '100%' }}>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            {t('orders.filter.apply')}
                                        </Button>
                                        <Button type="primary" danger onClick={clearFilters}>
                                            {t('orders.filter.clear')}
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </Panel>
                    </Collapse>
                    <Table
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns}
                        size='small'
                        pagination={{
                            position: ['topRight', 'bottomRight'],
                            total: dataSourceCount,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            pageSize: perPage,
                            current: page,
                            onChange: (page, pageSize) => {
                                setPage(page);
                                setPerPage(pageSize);
                            }
                        }}
                    />
                </div>
            </Content>
            <Modal okText={t('modal.ok-text')} cancelText={t('modal.cancel-text')} title={t('orders.refundModal.title')} open={refundModalOpen} onOk={handleRefundModalOk} onCancel={handleRefundModalCancel}>
                <p>{t('orders.refundModal.description')}</p>
                <Form form={refundForm} name="refund-form">
                    <Form.Item>
                        <div>{t('orders.refundModal.refund.label')}</div>
                        <Form.Item
                            name="refundedAmount"
                            rules={[
                                {
                                    required: true,
                                    message: t('orders.refundModal.refund.required'),
                                }
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Form.Item>
                </Form>
            </Modal>
            <MemberFooter />
        </Layout>
    </>;
}

export default Orders;