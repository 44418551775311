import React, { useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space, Modal, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import {
    readAllProductRequests,
    processProductRequest,
    rejectProductRequest,
    completeProductRequest,
    addNoteOnProductRequest
} from '../actions/product.request';
import { PageHeader } from '@ant-design/pro-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function OperationProductRequests() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Content } = Layout;
    const [dataSource, setDataSource] = useState([]);
    const [noteForm] = Form.useForm();
    const [noteRecord, setNoteRecord] = useState(null);
    const [noteModalOpen, setNoteModalOpen] = useState(false);

    const showNoteModal = (record) => {
        setNoteRecord(record);
        setNoteModalOpen(true);
        noteForm.setFieldsValue({
            quantity: record.currentQuantity
        });
    };

    const handleNoteModalOk = () => {
        noteForm.validateFields().then((values) => {
            dispatch(addNoteOnProductRequest(noteRecord.id, noteForm.getFieldsValue())).then((data) => {
                setDataSource(dataSource.map((item) => {
                    if (item.id === noteRecord.id) {
                        return data.result
                    }
                    return item;
                }));
                message.success(t('operationProductRequests.message.note-success'));
                setNoteModalOpen(false);
                setNoteRecord(null);
                noteForm.resetFields();
            }).catch((error) => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    };

    const handleNoteModalCancel = () => {
        setNoteModalOpen(false);
        setNoteRecord(null);
        noteForm.resetFields();
    };

    const expandedRowRender = (record) => {
        return <Table
            rowKey={record => record._id}
            dataSource={Object.keys(record).filter((key) => { return key === 'note' || key === 'extra_note' }).map((key) => { return { key: key, value: record[key] } })}
            columns={[
                {
                    title: t('operationProductRequests.columns.key'),
                    dataIndex: 'key',
                    key: 'key',
                },
                {
                    title: t('operationProductRequests.columns.value'),
                    dataIndex: 'value',
                    key: 'value',
                    render: (text, row) => {
                        if (Array.isArray(row.value)) {
                            return <>{row.value.map((item, key) => {
                                return <div key={key}>{item}</div>
                            })}</>
                        } else {
                            return <>{row.value}</>
                        }
                    }
                }
            ]}
        >
        </Table>
    }

    const processRequestAction = (id) => {
        dispatch(processProductRequest(id)).then((data) => {
            setDataSource(dataSource.map((item) => {
                if (item.id === id) {
                    item.status = 'processing';
                }
                return item;
            }))
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const rejectRequestAction = (id) => {
        dispatch(rejectProductRequest(id)).then((data) => {
            setDataSource(dataSource.map((item) => {
                if (item.id === id) {
                    item.status = 'rejected';
                }
                return item;
            }))
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const completeRequestAction = (id) => {
        dispatch(completeProductRequest(id)).then((data) => {
            setDataSource(dataSource.map((item) => {
                if (item.id === id) {
                    item.status = 'completed';
                }
                return item;
            }))
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }


    const columns = [
        {
            title: t('operationProductRequests.columns.admin'),
            dataIndex: 'admin',
            key: 'admin',
            render: (text, record) => <>{record.admin ? record.admin.name : 'N/A'}</>
        },
        {
            title: t('operationProductRequests.columns.sku'),
            dataIndex: 'sku',
            key: 'sku',
            render: (text, record) => <>{record.sku}</>
        },
        {
            title: t('operationProductRequests.columns.name'),
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <>{record.name}</>
        },
        {
            title: t('operationProductRequests.columns.url'),
            dataIndex: 'url',
            key: 'url',
            render: (text, record) => <>{record.url}</>
        },
        {
            title: t('operationProductRequests.columns.volume'),
            dataIndex: 'volume',
            key: 'volume',
            render: (text, record) => <>{record.volume}</>
        },
        {
            title: t('operationProductRequests.columns.status'),
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <>{t('operationProductRequests.' + record.status)}</>
        },
        {
            title: t('operationProductRequests.columns.created'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => <>{moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</>
        },
        {
            title: t('operationProductRequests.columns.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => <Space>
                {(record.status === 'pending') && <Popconfirm
                    title={t('operationProductRequests.confirm.process_message')}
                    onConfirm={() => processRequestAction(record.id)}
                    okText={t('operationProductRequests.confirm.process_confirm_ok')}
                    cancelText={t('operationProductRequests.confirm.process_confirm_cancel')}
                ><Button type="primary">{t('operationProductRequests.button.process')}</Button></Popconfirm>}
                {(record.status === 'processing') && <Popconfirm
                    title={t('operationProductRequests.confirm.complete_message')}
                    onConfirm={() => completeRequestAction(record.id)}
                    okText={t('operationProductRequests.confirm.complete_confirm_ok')}
                    cancelText={t('operationProductRequests.confirm.complete_confirm_cancel')}
                ><Button type="primary">{t('operationProductRequests.button.complete')}</Button></Popconfirm>}
                {(record.status === 'pending' || record.status === 'processing') && <Popconfirm
                    title={t('operationProductRequests.confirm.reject_message')}
                    onConfirm={() => rejectRequestAction(record.id)}
                    okText={t('operationProductRequests.confirm.reject_confirm_ok')}
                    cancelText={t('operationProductRequests.confirm.reject_confirm_cancel')}
                ><Button type="primary" danger>{t('operationProductRequests.button.reject')}</Button></Popconfirm>}
                <Button type="primary" onClick={() => showNoteModal(record)}>{t('operationProductRequests.button.note')}</Button>
            </Space>

        }
    ];

    useEffect(() => {
        dispatch(readAllProductRequests()).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
            } else {
                setDataSource([]);
            }
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch]);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title={t('operationProductRequests.title')}
                        subTitle={t('operationProductRequests.subtitle')}
                    />
                    <Table
                        rowKey={record => record.id}
                        expandable={{
                            expandedRowRender: expandedRowRender,
                            rowExpandable: () => true
                        }}
                        dataSource={dataSource}
                        columns={columns} />
                    <Modal okText={t('modal.ok-text')} cancelText={t('modal.cancel-text')} title={t('operationProductRequests.noteModal.title')} open={noteModalOpen} onOk={handleNoteModalOk} onCancel={handleNoteModalCancel}>
                        <p>{t('operationProductRequests.noteModal.description')}</p>
                        <Form form={noteForm} name="note-form">
                            <Form.Item>
                                <div>{t('operationProductRequests.noteModal.note.label')}</div>
                                <Form.Item
                                    name="note"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('operationProductRequests.noteModal.note.required'),
                                        }
                                    ]}
                                >
                                    <Input.TextArea placeholder={t('operationProductRequests.noteModal.note.placeholder')} />
                                </Form.Item>
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default OperationProductRequests;