import ProductCategoryService from "../services/product-category.service";

// Action to get product category by id
export const readProductCategory = (id) => (dispatch) => {
    return ProductCategoryService.readProductCategory(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all product categories
export const readProductCategories = (searchParams) => (dispatch) => {
    return ProductCategoryService.readProductCategories(searchParams).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create product category
export const createProductCategory = (data) => (dispatch) => {
    return ProductCategoryService.createProductCategory(data).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to update product category
export const updateProductCategory = (id, data) => (dispatch) => {
    return ProductCategoryService.updateProductCategory(id, data).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}
