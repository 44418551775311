function isRoot(user) {
    try {
        if (user) {
            if (typeof user.isRoot !== 'undefined' && user.isRoot === true) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (e) {
        console.log(e);
        return false;
    }
}

function isAdmin(user) {
    // Check the user.role if equal to 'admin'
    try {
        if (user) {
            if (typeof user.role !== 'undefined') {
                if (user.role === 'admin') {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (e) {
        console.log(e);
        return false;
    }
}

function isRootOrAdmin(user) {
    return isAdmin(user) || isRoot(user);
}

function isWarehouseManager(user) {
    if (isRootOrAdmin(user)) {
        return true;
    } else {
        if (typeof user.role !== 'undefined') {
            return user.role === 'warehouse_manager';
        } else {
            return false;
        }
    }
}

function isProductOwner(user) {
    if (isRootOrAdmin(user)) {
        return true;
    } else {
        if (typeof user.role !== 'undefined') {
            return user.role === 'product_owner';
        } else {
            return false;
        }
    }
}

function isOperationTeam(user) {
    if (isRootOrAdmin(user)) {
        return true;
    } else {
        if (typeof user.role !== 'undefined') {
            return user.role === 'operation_team';
        } else {
            return false;
        }
    }
}

function userIsReadOnly(user) {
    if (isRootOrAdmin(user)) {
        return false;
    } else {
        if (typeof isReadOnly !== 'undefined' && user.isReadOnly === true) {
            return true;
        } else {
            return false;
        }
    }
}

function userBelongsTo(user, role) {
    if (user) {
        if (typeof user.role !== 'undefined') {
            if (role === 'admin') {
                return isRootOrAdmin(user);
            } else {
                return user.role === role;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export {
    isRoot,
    isAdmin,
    isRootOrAdmin,
    userIsReadOnly,
    userBelongsTo,
    isProductOwner,
    isWarehouseManager,
    isOperationTeam,
}