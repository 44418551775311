import React, { useEffect, useState } from "react";
import { Layout, message, Button, Table, Space } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import MemberSidebar from "./components/member.sidebar.component";
import MemberFooter from "./components/member.footer.component";
import { useDispatch } from "react-redux";
import { readProductRequests } from "../actions/product.request";
import { PageHeader } from "@ant-design/pro-components";
import { useTranslation } from "react-i18next";
import moment from "moment";

function ProductRequests() {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Content } = Layout;
  const [dataSource, setDataSource] = useState([]);

  const expandedRowRender = (record) => {
    return <Table
      rowKey={record => record._id}
      dataSource={Object.keys(record).filter((key) => { return key === 'note' || key === 'extra_note' }).map((key) => { return { key: key, value: record[key] } })}
      columns={[
        {
          title: t('operationProductRequests.columns.key'),
          dataIndex: 'key',
          key: 'key',
        },
        {
          title: t('operationProductRequests.columns.value'),
          dataIndex: 'value',
          key: 'value',
          render: (text, row) => {
            if (Array.isArray(row.value)) {
              return <>{row.value.map((item, key) => {
                return <div key={key}>{item}</div>
              })}</>
            } else {
              return <>{row.value}</>
            }
          }
        }
      ]}
    >
    </Table>
  }

  const columns = [
    {
      title: t("productRequests.columns.sku"),
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: t("productRequests.columns.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("productRequests.columns.url"),
      dataIndex: "url",
      key: "url",
    },
    {
      title: t("productRequests.columns.volume"),
      dataIndex: "volume",
      key: "volume",
    },
    {
      title: t("productRequests.columns.status"),
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return t('productRequests.status.' + record.status);
      }
    },
    {
      title: t("productRequests.columns.created"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <>{moment(record.createdAt).format("YYYY-MM-DD HH:mm:ss")}</>
      ),
    },
    {
      title: t("productRequests.columns.actions"),
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        if (record.status === "pending") {
          return (
            <Space>
              <Button
                type="primary"
                onClick={() =>
                  navigate(
                    `/product_owner/${params.productOwnerId}/product-request/${record.id}`
                  )
                }
              >
                {t("productRequests.actions.edit")}
              </Button>
            </Space>
          );
        } else {
          return '-';
        }
      },
    },
  ];

  useEffect(() => {
    dispatch(readProductRequests(params.productOwnerId))
      .then((response) => {
        if (typeof response.result !== "undefined") {
          setDataSource(response.result);
        } else {
          setDataSource([]);
        }
      })
      .catch((error) => {
        try {
          setDataSource([]);
          let responseMessage = error.response.data.message;
          console.log(responseMessage);
          message.error(responseMessage);
        } catch (e) {
          console.log("Failed:", e);
          message.error(JSON.stringify(e));
        }
      });
  }, [dispatch, params.productOwnerId]);

  return (
    <>
      <MemberSidebar />
      <Layout className="site-layout">
        <Content style={{ margin: "0 16px" }}>
          <div className="site-layout-background">
            <PageHeader
              className="site-page-header"
              onBack={() => navigate("/dashboard")}
              title={t("productRequests.title")}
              subTitle={t("productRequests.subtitle")}
              extra={[
                <Button
                  key="1"
                  type="primary"
                  onClick={() =>
                    navigate(
                      `/product_owner/${params.productOwnerId}/product-request`
                    )
                  }
                >
                  {t("productRequests.actions.new")}
                </Button>,
              ]}
            />
            <Table
              rowKey={(record) => record.id}
              expandable={{ expandedRowRender, rowExpandable: () => true }}
              dataSource={dataSource}
              columns={columns}
            />
          </div>
        </Content>
        <MemberFooter />
      </Layout>
    </>
  );
}

export default ProductRequests;
