import authHeader from './auth-header';
import axios from "axios";
import qs from 'qs';
import { API_PRODUCT_URL } from "./config";
import { convertUrlSearchParamsToObject } from '../helpers/url';

// API to get product by id
const readProduct = (id) => {
    return axios.get(API_PRODUCT_URL + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all products
const readProducts = (filters) => {
    let filterString = '';
    if (typeof filters !== 'undefined') {
        try {
            filterString = qs.stringify(convertUrlSearchParamsToObject(filters));
        } catch (e) {
            filterString = '';
        }
    }
    return axios.get(API_PRODUCT_URL + '?' + filterString, {
        headers: authHeader()
    }).then(res => res.data);
}
// API to count product
const countProduct = (filters) => {
    let filterString = '';
    if (typeof filters !== 'undefined') {
        try {
            filterString = qs.stringify(convertUrlSearchParamsToObject(filters));
        } catch (e) {
            filterString = '';
        }
    }
    return axios.get(API_PRODUCT_URL + 'count?' + filterString, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to delete product
const deleteProduct = (id) => {
    return axios.delete(API_PRODUCT_URL + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to publish product
const publishProduct = (id) => {
    return axios.post(API_PRODUCT_URL + id + '/publish', {}, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to unpublish product
const unpublishProduct = (id) => {
    return axios.post(API_PRODUCT_URL + id + '/unpublish', {}, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to sell product
const sellProduct = (id) => {
    return axios.post(API_PRODUCT_URL + id + '/sell', {}, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to unsell product
const unsellProduct = (id) => {
    return axios.post(API_PRODUCT_URL + id + '/unsell', {}, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to create product
const createProduct = (data) => {
    return axios.post(API_PRODUCT_URL, data, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to update product
const updateProduct = (id, data) => {
    return axios.put(API_PRODUCT_URL + id, data, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to search products by sku
const searchProductsBySku = (sku) => {
    return axios.get(API_PRODUCT_URL + 'search?sku=' + sku, {
        headers: authHeader()
    }).then(res => res.data.result);
}

// API to search products by sku or name
const searchProductsBySkuOrName = (skuOrName) => {
    return axios.get(API_PRODUCT_URL + 'search?skuOrName=' + skuOrName, {
        headers: authHeader()
    }).then(res => res.data.result);
}

// API to refresh product inventory caches
const refreshProductInventoryCaches = () => {
    return axios.post(API_PRODUCT_URL + 'refresh-inventory-caches', {}, {
        headers: authHeader()
    }).then(res => res.data);
}

const product = {
    readProduct,
    readProducts,
    countProduct,
    deleteProduct,
    publishProduct,
    unpublishProduct,
    sellProduct,
    unsellProduct,
    createProduct,
    updateProduct,
    searchProductsBySku,
    searchProductsBySkuOrName,
    refreshProductInventoryCaches
};

export default product;