// Convert Product Translation
export const convertProductTranslations = (product) => {
    if (typeof product.translations !== 'undefined' && product.translations) {
        let translations = {};
        product.translations.forEach(translation => {
            const lang = translation.lang;
            delete translation.lang;
            translations[lang] = translation;
        });
        product.translations = translations;
        return product;
    } else {
        return product;
    }
}

// Convert Product Category Translation
export const convertProductCategoryTranslations = (product) => {
    if (typeof product.translations !== 'undefined' && product.translations) {
        let translations = {};
        product.translations.forEach(translation => {
            const lang = translation.lang;
            delete translation.lang;
            translations[lang] = translation;
        });
        product.translations = translations;
        return product;
    } else {
        return product;
    }
}