import authHeader from './auth-header';
import axios from "axios";
import qs from 'qs';
import { API_PRODUCT_CATEGORY_URL } from "./config";
import { convertUrlSearchParamsToObject } from '../helpers/url';

// API to search the category by name or slug
// API to search products by sku or name
const searchCategoryBySlugOrName = (slugOrName) => {
    return axios.get(API_PRODUCT_CATEGORY_URL + 'search?slugOrName=' + slugOrName, {
        headers: authHeader()
    }).then(res => res.data.result);
}

// API to get product by id
const readProductCategory = (id) => {
    return axios.get(API_PRODUCT_CATEGORY_URL + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all products
const readProductCategories = (filters) => {
    let filterString = '';
    if (typeof filters !== 'undefined') {
        try {
            filterString = qs.stringify(convertUrlSearchParamsToObject(filters));
        } catch (e) {
            filterString = '';
        }
    }
    return axios.get(API_PRODUCT_CATEGORY_URL + '?' + filterString, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to create product
const createProductCategory = (data) => {
    return axios.post(API_PRODUCT_CATEGORY_URL, data, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to update product
const updateProductCategory = (id, data) => {
    return axios.put(API_PRODUCT_CATEGORY_URL + id, data, {
        headers: authHeader()
    }).then(res => res.data);
}

const productCategory = {
    readProductCategory,
    readProductCategories,
    createProductCategory,
    updateProductCategory,
    searchCategoryBySlugOrName
};

export default productCategory;