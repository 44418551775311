import React from 'react';
import { Layout } from 'antd';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useTranslation } from 'react-i18next';

function Dashboard() {
    const { Content } = Layout;
    const { t } = useTranslation();

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <h1 style={{ marginBottom: 20 }}>{t('notfound.title')}</h1>
                    <p>{t('notfound.description')}</p>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Dashboard;