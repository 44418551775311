import UserService from "../services/user.service";

// Action to get user by id
export const readUser = (id) => (dispatch) => {
    return UserService.readUser(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to count user
export const countUser = (searchParams) => (dispatch) => {
    return UserService.countUser(searchParams).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all users
export const readUsers = (searchParams) => (dispatch) => {
    return UserService.readUsers(searchParams).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to update user
export const updateUser = (id, data) => (dispatch) => {
    return UserService.updateUser(id, data).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create user
export const createUser = (data) => (dispatch) => {
    return UserService.createUser(data).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}