import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Form, Input, Switch, Select } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readUser, createUser, updateUser } from '../actions/user';
import { useTranslation } from 'react-i18next';
import { green } from "@ant-design/colors";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const getAddressFormItems = (addressType, t) => {
    return <>
        {/* lastName: text */}
        <Form.Item
            name={[addressType, 'lastName']}
            label={t('user.lastName.label')}
        >
            <Input placeholder={t('user.lastName.placeholder')} />
        </Form.Item>
        {/* firstName: text */}
        <Form.Item
            name={[addressType, 'firstName']}
            label={t('user.firstName.label')}
        >
            <Input placeholder={t('user.firstName.placeholder')} />
        </Form.Item>
        {/* email: text */}
        <Form.Item
            name={[addressType, 'email']}
            label={t('user.email.label')}
        >
            <Input placeholder={t('user.email.placeholder')} />
        </Form.Item>
        {/* phoneRegion: text */}
        <Form.Item
            name={[addressType, 'phoneRegion']}
            label={t('user.phoneRegion.label')}
        >
            <Select allowClear placeholder={t('user.phoneRegion.placeholder')}>
                <Select.Option value="+852">+852</Select.Option>
                <Select.Option value="+86">+86</Select.Option>
                <Select.Option value="+853">+853</Select.Option>
            </Select>
        </Form.Item>
        {/* phoneNumber: text */}
        <Form.Item
            name={[addressType, 'phoneNumber']}
            label={t('user.phoneNumber.label')}
        >
            <Input placeholder={t('user.phoneNumber.placeholder')} />
        </Form.Item>
        {/* address1: text */}
        <Form.Item
            name={[addressType, 'address1']}
            label={t('user.address1.label')}
        >
            <Input placeholder={t('user.address1.placeholder')} />
        </Form.Item>
        {/* address2: text */}
        <Form.Item
            name={[addressType, 'address2']}
            label={t('user.address2.label')}
        >
            <Input placeholder={t('user.address2.placeholder')} />
        </Form.Item>
        {/* region: text */}
        <Form.Item
            name={[addressType, 'region']}
            label={t('user.region.label')}
        >
            <Input placeholder={t('user.region.placeholder')} />
        </Form.Item>
        {/* city: select */}
        <Form.Item
            name={[addressType, 'city']}
            label={t('user.city.label')}
        >
            <Select allowClear placeholder={t('user.city.placeholder')}>
                <Select.Option value="hk">Hong Kong</Select.Option>
            </Select>
        </Form.Item>
        {/* zip: text */}
        <Form.Item
            name={[addressType, 'zip']}
            label={t('user.zip.label')}
        >
            <Input placeholder={t('user.zip.placeholder')} />
        </Form.Item>
        {/* country: select */}
        <Form.Item
            name={[addressType, 'country']}
            label={t('user.country.label')}
        >
            <Select allowClear placeholder={t('user.country.placeholder')}>
                <Select.Option value="hk">Hong Kong</Select.Option>
            </Select>
        </Form.Item>
    </>
}

function User() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { Content } = Layout;
    const [form] = Form.useForm();

    const [userTitle, setUserTitle] = useState('');
    useEffect(() => {
        if (typeof params.id !== 'undefined') {
            setUserTitle(t('user.edit.title'));
            dispatch(readUser(params.id)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setUserTitle(t('user.edit.title') + ' - ' + response.result.id);
                    form.setFieldsValue(response.result);
                } else {
                    message.error(t('user.message.read-error'));
                    navigate('/users');
                }
            }).catch(e => {
                console.log(e);
                message.error(t('user.message.read-error'));
                navigate('/users');
            });
        } else {
            setUserTitle(t('user.new.title'));
        }
    }, [params.id, dispatch, navigate, form, t]);

    const onFinish = (values) => {
        if (typeof values.keyIsVerified === 'undefined') {
            values.keyIsVerified = false;
        }
        if (typeof values.status === 'undefined') {
            values.status = false;
        }
        if (typeof params.id !== 'undefined') {
            // Update the existing user
            dispatch(updateUser(params.id, values)).then((response) => {
                console.log(response);
                if (response.result) {
                    setUserTitle(t('user.edit.title') + ' - ' + response.result.id);
                    form.setFieldsValue(response.result);
                }
                message.success(t('user.message.update-success'));
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        } else {
            // Create new user
            dispatch(createUser(values)).then((data) => {
                message.success(t('user.message.create-success'));
                if (data.result) {
                    navigate('/user/' + data.result.id);
                } else {
                    navigate('/users');
                }
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        if (typeof errorInfo.errorFields !== 'undefined' && Array.isArray(errorInfo.errorFields)) {
            errorInfo.errorFields.forEach(field => {
                message.error(field.errors[0]);
            });
        }
    };

    let extraButtons = [
        <Button key="cancel" type="primary" danger onClick={() => navigate('/users')}>{t('user.button.cancel')}</Button>,
    ];
    if (typeof params.id !== 'undefined') {
        extraButtons.push(<Button key="orders" style={{ backgroundColor: green[5] }} type="primary" onClick={() => navigate(`/orders?user=${params.id}`)}>{t('user.button.orders')}</Button>);
    }
    extraButtons.push(<Button key="save" type="primary" htmlType="submit">{params.id ? t('user.button.update') : t('user.button.save')}</Button>);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <Form {...layout} form={form} name="user" onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={{
                        "keyIsVerified": true,
                        "status": true,
                    }}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate('/users')}
                            title={userTitle}
                            extra={extraButtons}
                        />
                        <h2>{t('user.section.personal')}</h2>
                        <Form.Item
                            name="lastName"
                            label={t('user.lastName.label')}
                            rules={[{ required: true, message: t('user.lastName.required') }]}
                        >
                            <Input placeholder={t('user.lastName.placeholder')} />
                        </Form.Item>
                        <Form.Item
                            name="firstName"
                            label={t('user.firstName.label')}
                            rules={[{ required: true, message: t('user.firstName.required') }]}
                        >
                            <Input placeholder={t('user.firstName.placeholder')} />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label={t('user.email.label')}
                            rules={[{ required: true, message: t('user.email.required') }]}
                        >
                            <Input placeholder={t('user.email.placeholder')} />
                        </Form.Item>
                        {/* checkbox: keyIsVerified */}
                        <Form.Item
                            name="keyIsVerified"
                            label={t('user.keyIsVerified.label')}
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                        {/* checkbox: status */}
                        <Form.Item
                            name="status"
                            label={t('user.status.label')}
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                        {/* phoneRegion: text */}
                        <Form.Item
                            name="phoneRegion"
                            label={t('user.phoneRegion.label')}
                        >
                            <Select allowClear placeholder={t('user.phoneRegion.placeholder')}>
                                <Select.Option value="+852">+852</Select.Option>
                                <Select.Option value="+86">+86</Select.Option>
                                <Select.Option value="+853">+853</Select.Option>
                            </Select>
                        </Form.Item>
                        {/* phoneNumber: text */}
                        <Form.Item
                            name="phoneNumber"
                            label={t('user.phoneNumber.label')}
                        >
                            <Input placeholder={t('user.phoneNumber.placeholder')} />
                        </Form.Item>

                        <h2>{t('user.section.billing')}</h2>
                        {getAddressFormItems('billing', t)}
                        <h2>{t('user.section.shipping')}</h2>
                        {getAddressFormItems('shipping', t)}
                    </Form>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default User;