import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Form, Input, Switch, Tabs, InputNumber, Drawer } from 'antd';
import { UpCircleOutlined, DownCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { createProduct, readProduct, updateProduct } from '../actions/product';
import { useTranslation } from 'react-i18next';
import { convertProductTranslations } from '../helpers/product';
import { API_CDN_URL } from '../services/config';
import dot from 'dot-object';
import MediaSelect from './components/mediaSelect.field';
import MediaLibrary from './media.page';
import ReferenceSelect from './fields/referenceSelect.field';
import ProductCategoryService from '../services/product-category.service';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

function Product() {
    const languages = [
        { code: 'zh-HK', name: 'Traditional Chinese' },
        { code: 'en-US', name: 'English' },
        { code: 'ja-JP', name: 'Japanese' },
    ];
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { Content } = Layout;
    const [form] = Form.useForm();
    const [mediaDrawerVisible, setMediaDrawerVisible] = useState(false);
    const [currentMediaFieldName, setCurrentMediaFieldName] = useState(null);
    const items = languages.map(language => {
        return {
            forceRender: true,
            label: language.name,
            key: language.code,
            children: <>
                <Form.Item name={["translations", language.code, "name"]} label={t('product.name.label')} rules={[{ required: true, message: t('product.name.required') }]}>
                    <Input placeholder={t('product.name.placeholder')} />
                </Form.Item>
                <Form.Item name={["translations", language.code, "description"]} label={t('product.description.label')}>
                    <Input.TextArea placeholder={t('product.description.placeholder')} autoSize={{ minRows: 5, maxRows: 10 }} />
                </Form.Item>
            </>
        }
    });

    const showMediaDrawer = (fieldName) => {
        setCurrentMediaFieldName(fieldName);
        setMediaDrawerVisible(true);
    };
    const onMediaDrawerClose = () => {
        setMediaDrawerVisible(false);
    };

    const onSelectMedia = (media) => {
        setMediaDrawerVisible(false);
        if (currentMediaFieldName) {
            let url = API_CDN_URL() + media.key;
            let newValue = {
                [currentMediaFieldName.join('.')]: url
            };
            newValue = dot.object(newValue);
            form.setFieldsValue(newValue);
        }
    }

    const [productTitle, setProductTitle] = useState('');
    useEffect(() => {
        if (typeof params.id !== 'undefined') {
            setProductTitle(t('product.edit.title'));
            dispatch(readProduct(params.id)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    let product = convertProductTranslations(response.result);
                    setProductTitle(t('product.edit.title') + ' - ' + response.result.sku);
                    form.setFieldsValue(product);
                } else {
                    message.error(t('product.message.read-error'));
                    navigate('/products');
                }
            }).catch(e => {
                console.log(e);
                message.error(t('product.message.read-error'));
                navigate('/products');
            });
        } else {
            setProductTitle(t('product.new.title'));
        }
    }, [params.id, dispatch, navigate, form, t]);

    const onFinish = (values) => {
        if (typeof values.saleable === 'undefined') {
            values.saleable = false;
        }
        if (typeof values.published === 'undefined') {
            values.published = false;
        }
        if (typeof values.categories !== 'undefined') {
            values.categories = values.categories.map(category => category.value);
        }
        if (typeof params.id !== 'undefined') {
            // Update the existing product
            dispatch(updateProduct(params.id, values)).then((response) => {
                console.log(response);
                if (response.result) {
                    setProductTitle(t('product.edit.title') + ' - ' + response.result.sku);
                    form.setFieldsValue(convertProductTranslations(response.result));
                    console.log(response.result);
                }
                message.success(t('product.message.update-success'));
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        } else {
            // Create new product
            dispatch(createProduct(values)).then((data) => {
                message.success(t('product.message.create-success'));
                if (data.result) {
                    navigate('/product/' + data.result.id);
                } else {
                    navigate('/products');
                }
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        if (typeof errorInfo.errorFields !== 'undefined' && Array.isArray(errorInfo.errorFields)) {
            errorInfo.errorFields.forEach(field => {
                message.error(field.errors[0]);
            });
        }
    };

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <Form {...layout} form={form} name="product" onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={{
                        "saleable": true,
                        "published": true,
                    }}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate('/products')}
                            title={productTitle}
                            extra={[
                                <Button key="cancel" type="primary" danger onClick={() => navigate('/products')}>{t('product.button.cancel')}</Button>,
                                <Button key="save" type="primary" htmlType="submit">{params.id ? t('product.button.update') : t('product.button.save')}</Button>
                            ]}
                        />
                        <Form.Item
                            name="sku"
                            label={t('product.sku.label')}
                            rules={[{ required: true, message: t('product.sku.required') }]}
                        >
                            <Input placeholder={t('product.sku.placeholder')} />
                        </Form.Item>
                        <Form.Item
                            name="volume"
                            label={t('product.volume.label')}
                            rules={[{ required: true, message: t('product.volume.required') }]}
                        >
                            <Input placeholder={t('product.volume.placeholder')} />
                        </Form.Item>
                        <Form.Item
                            name="price"
                            label={t('product.price.label')}
                            rules={[{ required: true, message: t('product.price.required') }]}
                        >
                            <InputNumber placeholder={t('product.price.placeholder')} />
                        </Form.Item>
                        <Form.Item
                            name="weight"
                            label={t('product.weight.label')}
                            rules={[{ required: true, message: t('product.weight.required') }]}
                        >
                            <InputNumber placeholder={t('product.weight.placeholder')} />
                        </Form.Item>
                        <Form.Item name="saleable" label={t('product.saleable.label')} valuePropName="checked">
                            <Switch />
                        </Form.Item>
                        <Form.Item name="published" label={t('product.published.label')} valuePropName="checked">
                            <Switch />
                        </Form.Item>
                        <MediaSelect showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                            wrapperCol: 16,
                            label: t('product.primary_image.label')
                        }} inner={{
                            name: ['primary_image'],
                        }} />
                        <ReferenceSelect
                            isMultiple={true}
                            selectProps={{
                                showSearch: true,
                                allowClear: true,
                                labelInValue: true,
                            }}
                            onFetch={ProductCategoryService.searchCategoryBySlugOrName}
                            inner={{
                                name: "categories"
                            }}
                            wrapper={{
                                label: t('product.product-categories.label'),
                            }} />
                        <Form.List name={["images"]} style={{ width: '100%' }}>
                            {(fields, { add, remove, move }) => (
                                <>
                                    <h1>{t('product.images.label')}</h1>
                                    {fields.map(({ key, name, ...restField }, fieldPosition) => {
                                        return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <MediaSelect showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                                                wrapperCol: 16,
                                                required: true,
                                                style: { marginBottom: 8, width: 'calc(100% - 100px)' }
                                            }} inner={{
                                                name: [name],
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: t('product.images.required')
                                                    }
                                                ]
                                            }}
                                                fieldName={["images", name]}
                                                placeholder={t('product.images.placeholder')} />
                                            <div style={{ width: 100 }}>
                                                {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                                {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                                <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                                            </div>
                                        </div>
                                    })}
                                    <Form.Item wrapperCol={{ span: 24 }}>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            {t('product.images.add')}
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <Tabs
                            defaultActiveKey={languages[0]}
                            items={items}
                        />
                        <Drawer contentWrapperStyle={{ width: '1000px', maxWidth: '100%', minWidth: '50%' }} title={t('mediaDrawer.title')} placement="right" onClose={onMediaDrawerClose} open={mediaDrawerVisible}>
                            <MediaLibrary isDrawer={true} selectMedia={onSelectMedia} />
                        </Drawer>
                    </Form>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Product;