import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Table, Space, Select, Form, Collapse, Input, InputNumber, DatePicker, Popconfirm, Modal } from 'antd';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { packedDeliveryNote, readDeliveryNotes, addLog, shipDeliveryNote } from '../actions/delivery.note';
import { PageHeader } from '@ant-design/pro-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { green, red, yellow, cyan } from '@ant-design/colors';
import _, { filter } from 'underscore';

function WarehouseDeliveryNotes() {
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [filterForm] = Form.useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const { Content } = Layout;
    const [dataSource, setDataSource] = useState([]);
    const [reloadFlag, setReloadFlag] = useState(new Date());
    const [addLogModalOpen, setAddLogModalOpen] = useState(false);
    const [addLogForm] = Form.useForm();
    const [currentDeliveryNote, setCurrentDeliveryNote] = useState(null);
    const [shippingModalOpen, setShippingModalOpen] = useState(false);
    const [shippingForm] = Form.useForm();

    const handleShippingModalOk = () => {
        shippingForm.validateFields().then((values) => {
            const provider = values.provider;
            const number = values.number;
            const currentDeliveryNoteId = currentDeliveryNote.id;
            dispatch(shipDeliveryNote(params.warehouseManagerId, currentDeliveryNoteId, { provider, number })).then((response) => {
                if (typeof response.result !== 'undefined') {
                    message.success(t('deliveryNotes.shipping.success'));
                    setReloadFlag(new Date());
                    handleShippingModalCancel();
                } else {
                    message.error(t('deliveryNotes.shipping.failed'));
                }
            }).catch((error) => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }).catch((error) => {
            if (typeof error.errorFields !== 'undefined') {
                error.errorFields.forEach((field) => {
                    message.error(field.errors[0]);
                });
            }
            console.log(error);
            return;
        });
    }

    const handleShippingModalCancel = () => {
        setShippingModalOpen(false);
        shippingForm.resetFields();
    }

    const handleAddLogModalOk = () => {
        addLogForm.validateFields().then((values) => {
            const log = values.log;
            const currentDeliveryNoteId = currentDeliveryNote.id;
            dispatch(addLog(params.warehouseManagerId, currentDeliveryNoteId, { log })).then((response) => {
                if (typeof response.result !== 'undefined') {
                    message.success(t('deliveryNotes.addLog.success'));
                    setReloadFlag(new Date());
                    handleAddLogModalCancel();
                } else {
                    message.error(t('deliveryNotes.addLog.failed'));
                }
            }).catch((error) => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }).catch((error) => {
            if (typeof error.errorFields !== 'undefined') {
                error.errorFields.forEach((field) => {
                    message.error(field.errors[0]);
                });
            }
            console.log(error);
            return;
        });
    }

    const handleAddLogModalCancel = () => {
        setAddLogModalOpen(false);
        addLogForm.resetFields();
    }

    const expandRecord = (record) => {
        if (Array.isArray(record.logs) && record.logs.length > 0) {
            let sortedLogs = _.sortBy(record.logs, 'createdAt').reverse();
            return <Table
                rowKey={record => record._id}
                dataSource={sortedLogs}
                columns={[
                    {
                        title: t('operationProductInventoryRequests.columns.logs.action'),
                        dataIndex: 'action',
                        key: 'action',
                    },
                    {
                        title: t('operationProductInventoryRequests.columns.logs.note'),
                        dataIndex: 'note',
                        key: 'note',
                    },
                    {
                        title: t('operationProductInventoryRequests.columns.logs.date'),
                        dataIndex: 'date',
                        key: 'date',
                        render: (text, record) => {
                            if (typeof record.date !== 'undefined' && record.date !== null) {
                                return moment(record.date).format('DD/MM/YYYY HH:mm:ss');
                            } else {
                                return 'N/A';
                            }
                        }
                    }
                ]}
            >
            </Table>
        } else {
            return <div>{t('logs.not-available')}</div>;
        }
    }
    const packedDeliveryNoteAction = (recordId) => {
        if (params.warehouseManagerId) {
            dispatch(packedDeliveryNote(params.warehouseManagerId, recordId)).then((response) => {
                if (typeof response.result !== 'undefined') {
                    message.success(t('managerDeliveryNotes.actions.packed.success'));
                    setReloadFlag(new Date());
                } else {
                    message.error(t('managerDeliveryNotes.actions.packed.failed'));
                }
            }).catch((error) => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    }

    const columns = [
        {
            title: t('managerDeliveryNotes.columns.id'),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t('managerDeliveryNotes.columns.order'),
            dataIndex: 'order',
            key: 'order',
        },
        {
            title: t('managerDeliveryNotes.columns.user'),
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => {
                return <>{record.snapshotUser.lastName}, {record.snapshotUser.firstName}<br /><div style={{ fontSize: '0.9em' }}>&lt;{record.snapshotUser._id}&gt;</div></>
            }
        },
        {
            title: t('managerDeliveryNotes.columns.warehouse'),
            dataIndex: 'warehouse',
            key: 'warehouse',
            render: (text, record) => <>{record.warehouse.name}</>
        },
        {
            title: t('managerDeliveryNotes.columns.completed'),
            dataIndex: 'completed',
            key: 'completed',
            render: (text, record) => <>{record.completed ? <span style={{ color: green[5] }}>Yes</span> : <span style={{ color: red[5] }}>No</span>}</>
        },
        {
            title: t('managerDeliveryNotes.columns.tracking'),
            dataIndex: 'tracking',
            key: 'tracking',
            render: (text, record) => <>{(record.tracking && record.tracking.number) ? record.tracking.number : 'N/A'}</>
        },
        {
            title: t('managerDeliveryNotes.columns.createdAt'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => <>{moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</>
        },
        {
            title: t('managerDeliveryNotes.columns.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => <Space size={[8, 8]} wrap>
                <Link to={`/warehouse_manager/${params.warehouseManagerId}/delivery-note/${record.id}`} target="_blank"><Button type="primary">{t('managerDeliveryNotes.actions.view')}</Button></Link>
                {!record.completed && <Popconfirm
                    title={t('managerDeliveryNotes.actions.packed.confirm.title')}
                    onConfirm={() => packedDeliveryNoteAction(record.id)}
                    okText={t('managerDeliveryNotes.actions.packed.confirm.ok')}
                    cancelText={t('managerDeliveryNotes.actions.packed.confirm.cancel')}
                ><Button type="primary" style={{ backgroundColor: green[6], borderColor: green[6] }}>{t('managerDeliveryNotes.actions.packed')}</Button></Popconfirm>}
                {record.completed && (typeof record.tracking === 'undefined' || record.tracking === null) && <Button type="primary" style={{ backgroundColor: yellow[7], borderColor: yellow[7] }} onClick={() => {
                    setCurrentDeliveryNote(record);
                    setShippingModalOpen(true);
                }}>{t('managerDeliveryNotes.actions.shipped')}</Button>}
                <Button type="primary" style={{ backgroundColor: cyan[6], borderColor: cyan[6] }} onClick={() => {
                    setCurrentDeliveryNote(record);
                    setAddLogModalOpen(true);
                }}>{t('managerDeliveryNotes.actions.log')}</Button>
            </Space>

        }
    ];

    useEffect(() => {
        // set form values
        searchParams.forEach((value, key) => {
            filterForm.setFieldsValue({ [key]: value });
        });
    }, [searchParams, filterForm]);

    useEffect(() => {
        dispatch(readDeliveryNotes(params.warehouseManagerId, searchParams)).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
                console.log(response.result);
            } else {
                setDataSource([]);
            }
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch, params.productOwnerId, reloadFlag, searchParams]);

    const deliveryNoteDataSettings = [
        {
            slug: 'user',
            name: t('deliveryNotes.columns.user'),
            type: 'text'
        },
        {
            slug: 'order',
            name: t('deliveryNotes.columns.order'),
            type: 'text'
        },
        {
            slug: 'completed',
            name: t('deliveryNotes.columns.completed'),
            type: 'boolean'
        },
    ];

    const applyFilters = (values) => {
        console.log('values', values);
        let newSearchParams = {};
        for (let key in values) {
            if (typeof values[key] !== 'undefined' && values[key] !== '') {
                if (moment.isMoment(values[key])) {
                    newSearchParams[key] = values[key].format('YYYY-MM-DD');
                } else {
                    newSearchParams[key] = values[key];
                }
            }
        }
        console.log('values', values);
        setSearchParams(newSearchParams);
    }

    const clearFilters = () => {
        let newSearchParams = {};
        filterForm.resetFields();
        setSearchParams(newSearchParams);
    }

    let hasSearchParams = false;
    for (const entry of searchParams.entries()) {
        const [param] = entry;
        console.log('param', param);
        if (param !== 'page' && param !== 'perPage') {
            hasSearchParams = true;
            break;
        }
    }

    const { Panel } = Collapse;

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title={t('managerDeliveryNotes.title')}
                        subTitle={t('managerDeliveryNotes.subtitle')}
                        extra={[
                        ]}
                    />
                    <p className="list-note">{t('managerDeliveryNotes.description')}</p>
                    <Collapse style={{ marginTop: 10, marginBottom: 10 }} bordered={false} defaultActiveKey={hasSearchParams ? ['filter'] : []}>
                        <Panel header={<strong>{t('managerDeliveryNotes.filter.title')}</strong>} key="filter">
                            <Form form={filterForm} layout="inline" onFinish={applyFilters}>
                                <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key="_id" name="_id" label="ID">
                                    <Input />
                                </Form.Item>
                                {deliveryNoteDataSettings.map(field => {
                                    switch (field.type) {
                                        case 'checkbox':
                                        case 'radio':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Select allowClear={true}>{field.choiceOptions && field.choiceOptions.map(option => <Select.Option key={option} value={option.value}>{option.label}</Select.Option>)}</Select>
                                            </Form.Item>;
                                        case 'dropdown':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Select allowClear={true}>{field.dropdownOptions && field.dropdownOptions.map(option => <Select.Option key={option} value={option.value}>{option.label}</Select.Option>)}</Select>
                                            </Form.Item>;
                                        case 'number':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <InputNumber />
                                            </Form.Item>;
                                        case 'email':
                                        case 'primary_email':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} rules={[{ type: 'email' }]}>
                                                <Input />
                                            </Form.Item>;
                                        case 'date':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} rules={[{ type: 'date' }]}>
                                                <DatePicker />
                                            </Form.Item>;
                                        case 'text':
                                        case 'textarea':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Input />
                                            </Form.Item>;
                                        case 'boolean':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} valuePropName="checked">
                                                <Select allowClear={true}>
                                                    <Select.Option value="true">True</Select.Option>
                                                    <Select.Option value="false">False</Select.Option>
                                                </Select>
                                            </Form.Item>;
                                        default:
                                            console.log(`Please contact admin to add support for ${field.name} <${field.slug}|${field.type}> field type`);
                                            return null;
                                    }
                                })}
                                <Form.Item style={{ width: '100%' }}>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            {t('products.filter.apply')}
                                        </Button>
                                        <Button type="primary" danger onClick={clearFilters}>
                                            {t('products.filter.clear')}
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </Panel>
                    </Collapse>
                    <Table
                        size="small"
                        rowKey={record => record.id}
                        expandable={{
                            expandedRowRender: expandRecord,
                            rowExpandable: () => true
                        }}
                        dataSource={dataSource}
                        columns={columns} />
                    <Modal okText={t('modal.ok-text')} cancelText={t('modal.cancel-text')} title={t('deliveryNotes.addLog.title')} open={addLogModalOpen} onOk={handleAddLogModalOk} onCancel={handleAddLogModalCancel}>
                        <p>{t('deliveryNotes.addLog.description')}</p>
                        <Form form={addLogForm} name="addLog-form">
                            <Form.Item
                                name="log"
                                rules={[
                                    {
                                        required: true,
                                        message: t('deliveryNotes.addLog.log.required'),
                                    }
                                ]}
                            >
                                <Input.TextArea placeholder={t('deliveryNotes.addLog.log.placeholder')} />
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal okText={t('modal.ok-text')} cancelText={t('modal.cancel-text')} title={t('deliveryNotes.shipping.title')} open={shippingModalOpen} onOk={handleShippingModalOk} onCancel={handleShippingModalCancel}>
                        <p>{t('deliveryNotes.shipping.description')}</p>
                        <Form form={shippingForm} name="shipping-form">
                            <Form.Item
                                name="provider"
                                rules={[
                                    {
                                        required: true,
                                        message: t('deliveryNotes.shipping.provider.required'),
                                    }
                                ]}
                            >
                                <Select placeholder={t('deliveryNotes.shipping.provider.placeholder')}>
                                    <Select.Option value="sfexpress">SFExpress</Select.Option>
                                    <Select.Option value="other">Other</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="number"
                                rules={[
                                    {
                                        required: true,
                                        message: t('deliveryNotes.shipping.number.required'),
                                    }
                                ]}
                            >
                                <Input placeholder={t('deliveryNotes.shipping.number.placeholder')} />
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default WarehouseDeliveryNotes;