import { Form, Button, Input } from "antd";
import { FileOutlined } from '@ant-design/icons';

function MediaSelect(props) {

    const openMediaLibrary = (fieldName) => {
        if (props.showMediaDrawer) props.showMediaDrawer(props.fieldName ? props.fieldName : props.inner.name, props.form);
    }
    // TODO: clear media, do it later
    // const clearMedia = () => {
    //     try {
    //         if (typeof props.form !== 'undefined' && typeof props.inner.name !== 'undefined') {
    //             console.log(props.inner.name);
    //             props.form.setFieldsValue({
    //                 'heroImage.desktop': ''
    //             });
    //         }
    //     } catch (e) {
    //         console.log('Failed:', e);
    //     }
    // }


    return <Form.Item {...props.wrapper}>
        <div style={{ display: 'flex' }}>
            <Form.Item {...props.inner} noStyle>
                <Input placeholder={props.placeholder} />
            </Form.Item>
            <div style={{ marginLeft: 10 }}>
                <Button type="primary" onClick={() => openMediaLibrary()}><FileOutlined /> Select</Button>
                {/* <Button type="primary" danger onClick={() => clearMedia()}><DeleteOutlined /> Delete</Button> */}
            </div>
        </div>
        {props.note ? <div style={{ fontSize: 12 }}>{props.note}</div> : ''}
    </Form.Item >
}

export default MediaSelect;