import authHeader from './auth-header';
import axios from "axios";
import {
    API_DELIVERY_NOTE_URL
} from "./config";
import qs from 'qs';
import { convertUrlSearchParamsToObject } from '../helpers/url';

// Warehouse manager Use
// API to get all delivery notes by warehouse manager id
const readDeliveryNotes = (id, filters) => {
    let filterString = '';
    if (typeof filters !== 'undefined') {
        try {
            filterString = qs.stringify(convertUrlSearchParamsToObject(filters));
        } catch (e) {
            filterString = '';
        }
    }
    return axios.get(API_DELIVERY_NOTE_URL(id) + '?' + filterString, {
        headers: authHeader()
    }).then(res => res.data);
}

// Warehouse manager Use
// API to get a delivery note by warehouse manager id and delivery note id
const readDeliveryNoteById = (id, deliveryNoteId) => {
    return axios.get(API_DELIVERY_NOTE_URL(id) + deliveryNoteId, {
        headers: authHeader()
    }).then(res => res.data);
}

// Operation team Use
// API to get all delivery notes
const readAllDeliveryNotes = (filters) => {
    let filterString = '';
    if (typeof filters !== 'undefined') {
        try {
            filterString = qs.stringify(convertUrlSearchParamsToObject(filters));
        } catch (e) {
            filterString = '';
        }
    }
    return axios.get(API_DELIVERY_NOTE_URL() + '?' + filterString, {
        headers: authHeader()
    }).then(res => res.data);
}

// Operation team Use
// API to get a delivery note
const readDeliveryNoteByOperationTeam = (deliveryNoteId) => {
    return axios.get(API_DELIVERY_NOTE_URL(null, deliveryNoteId), {
        headers: authHeader()
    }).then(res => res.data);
}

// Warehouse manager Use
// API to update a delivery note to packed
const packedDeliveryNote = (id, deliveryNoteId) => {
    return axios.put(API_DELIVERY_NOTE_URL(id, deliveryNoteId) + 'packed', {}, {
        headers: authHeader()
    }).then(res => res.data);
}

// Operation team Use
// API to update a delivery note to packed
const packedDeliveryNoteByOperationTeam = (deliveryNoteId) => {
    return axios.put(API_DELIVERY_NOTE_URL(null, deliveryNoteId) + 'packed', {}, {
        headers: authHeader()
    }).then(res => res.data);
}

// Operation team use
// API to add log on the delivery note
const addLogByOperationTeam = (deliveryNoteId, log) => {
    return axios.put(API_DELIVERY_NOTE_URL(null, deliveryNoteId) + 'logs', log, {
        headers: authHeader()
    }).then(res => res.data);
}

// Warehouse manager use
// API to add log on the delivery note
const addLog = (id, deliveryNoteId, log) => {
    return axios.put(API_DELIVERY_NOTE_URL(id, deliveryNoteId) + 'logs', log, {
        headers: authHeader()
    }).then(res => res.data);
}

// Warehouse manager use
// API to ship a delivery note
const shipDeliveryNote = (id, deliveryNoteId, tracking) => {
    return axios.put(API_DELIVERY_NOTE_URL(id, deliveryNoteId) + 'ship', tracking, {
        headers: authHeader()
    }).then(res => res.data);
}

// Operation team use
// API to ship a delivery note
const shipDeliveryNoteByOperationTeam = (deliveryNoteId, tracking) => {
    return axios.put(API_DELIVERY_NOTE_URL(null, deliveryNoteId) + 'ship', tracking, {
        headers: authHeader()
    }).then(res => res.data);
}

const managerDeliveryNote = {
    // warehouse manager use
    readDeliveryNotes,
    readDeliveryNoteById,
    packedDeliveryNote,
    addLog,
    shipDeliveryNote,
    // operation team use
    readAllDeliveryNotes,
    readDeliveryNoteByOperationTeam,
    packedDeliveryNoteByOperationTeam,
    addLogByOperationTeam,
    shipDeliveryNoteByOperationTeam
};

export default managerDeliveryNote;