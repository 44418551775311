import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Layout, message } from 'antd';
import { useDispatch } from 'react-redux';
import { currentUser as getCurrentUser } from "../actions/auth";
import { isRootOrAdmin, isProductOwner, isOperationTeam, isWarehouseManager } from '../helpers/user';
import {
    Routes,
    Route
} from "react-router-dom";
// import Dashboard from './dashboard.page';
import NotFoundPage from './notfound.page';
import Profile from './profile.page';
// import Entities from './entities.page';
// import Entity from './entity.page';
// import EntityDashboard from './entity.dashboard.page';
// import EntityMedia from './entity.media.page';
import SettingDashboard from './setting.dashboard.page';
// import Trips from './entity.trips.page';
// import Trip from './entity.trip.page';
// import TripRegions from './entity.trip.regions.page';
// import TripRegion from './entity.trip.region.page';
// import TripCountries from './entity.trip.countries.page';
// import TripCountry from './entity.trip.country.page';
// import TripTags from './entity.trip.tags.page';
// import TripTag from './entity.trip.tag.page';
// import TripCollections from './entity.trip.collections.page';
// import TripCollection from './entity.trip.collection.page';
// import TripTopics from './entity.trip.topics.page';
// import TripTopic from './entity.trip.topic.page';
// import TripTypes from './entity.trip.types.page';
// import TripType from './entity.trip.type.page';

// import Discussions from './entity.discussions.page';
// import Discussion from './entity.discussion.page';
// import Comments from './entity.comments.page';
// import Comment from './entity.comment.page';

// import EntityPages from './entity.pages.page';
// import EntityPage from './entity.page.page';

// import ArticleTags from './entity.article.tags.page';
// import ArticleTag from './entity.article.tag.page';
// import ArticleCategories from './entity.article.categories.page';
// import ArticleCategory from './entity.article.category.page';
// import Articles from './entity.articles.page';
// import Article from './entity.article.page';

// import Users from './entity.users.page';
// import User from './entity.user.page';

import Warehouse from './warehouse.page';
import Warehouses from './warehouses.page';
import Admin from './admin.page';
import Admins from './admins.page';
import Products from './products.page';
import Product from './product.page';

import Orders from './orders.page';
import Order from './order.page';
import Users from './users.page';
import User from './user.page';
import DeliveryNotes from './delivery.notes.page';
import DeliveryNote from './delivery.note.page';

// Check the current product inventories owned by product owner
import OwnerProductInventories from './owner.product.inventories.page';
// Request to add new product inventory form
import OwnerProductInventory from './owner.product.inventory.page';
// Request to add new product
import OwnerProductRequests from './owner.product.requests.page';
// Request to add new product form
import OwnerProductRequest from './owner.product.request.page';

import OperationProductInventoryRequests from './operation.product.inventory.requests.page';
import OperationProductInventoryRequest from './operation.product.inventory.request.page';
import OperationProductRequests from './operation.product.requests.page';

import WarehouseDeliveryNotes from './warehouse.delivery.notes.page';
import WarehouseDeliveryNote from './warehouse.delivery.note.page';

import ProductCategories from './product.categories.page';
import ProductCategory from './product.category.page';

import Media from './media.page';

import Report from './report.page';



function MemberSection() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const currentUser = useSelector(state => state.auth.user);

    // Check whether should recirect to login
    // And also need to redirect to change password first
    useEffect(() => {
        if (!isLoggedIn || !currentUser) {
            navigate('/login');
        } else {
            if (typeof currentUser.needChangePassword === 'undefined' || currentUser.needChangePassword === null || currentUser.needChangePassword === true) {
                navigate('/update-password');
            }
        }
    }, [isLoggedIn, currentUser, navigate]);

    // Every restricted page should check whether the access token is stil valid.
    useEffect(() => {
        dispatch(getCurrentUser()).then(() => {

        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [location.pathname, dispatch]);

    // Undetermined state need to render empty content
    if (!isLoggedIn || !currentUser) {
        return null;
    } else {
        return <Layout className="crm-layout" style={{ minHeight: '100vh' }}>
            <Routes>
                <>
                    {/* <Route path="/entity/:entityId/dashboard" element={<EntityDashboard />} />
                        <Route path="entity/:entityId/media" element={<EntityMedia />} />

                        <Route path="/entity/:entityId/trips" element={<Trips />} />
                        <Route path="/entity/:entityId/trip" element={<Trip />} />
                        <Route path="/entity/:entityId/trip/:tripId" element={<Trip />} />

                        <Route path="/entity/:entityId/trip-regions" element={<TripRegions />} />
                        <Route path="/entity/:entityId/trip-region" element={<TripRegion />} />
                        <Route path="/entity/:entityId/trip-region/:regionId" element={<TripRegion />} />

                        <Route path="/entity/:entityId/trip-countries" element={<TripCountries />} />
                        <Route path="/entity/:entityId/trip-country" element={<TripCountry />} />
                        <Route path="/entity/:entityId/trip-country/:countryId" element={<TripCountry />} />

                        <Route path="/entity/:entityId/trip-tags" element={<TripTags />} />
                        <Route path="/entity/:entityId/trip-tag" element={<TripTag />} />
                        <Route path="/entity/:entityId/trip-tag/:tripTagId" element={<TripTag />} />

                        <Route path="/entity/:entityId/trip-collections" element={<TripCollections />} />
                        <Route path="/entity/:entityId/trip-collection" element={<TripCollection />} />
                        <Route path="/entity/:entityId/trip-collection/:collectionId" element={<TripCollection />} />

                        <Route path="/entity/:entityId/trip-topics" element={<TripTopics />} />
                        <Route path="/entity/:entityId/trip-topic" element={<TripTopic />} />
                        <Route path="/entity/:entityId/trip-topic/:topicId" element={<TripTopic />} />

                        <Route path="/entity/:entityId/trip-types" element={<TripTypes />} />
                        <Route path="/entity/:entityId/trip-type" element={<TripType />} />
                        <Route path="/entity/:entityId/trip-type/:typeId" element={<TripType />} />

                        <Route path="/entity/:entityId/discussions" element={<Discussions />} />
                        <Route path="/entity/:entityId/discussion/:discussionId/comments" element={<Comments />} />
                        <Route path="/entity/:entityId/discussion/:discussionId/comment" element={<Comment />} />
                        <Route path="/entity/:entityId/discussion/:discussionId/comment/:commentId" element={<Comment />} />
                        <Route path="/entity/:entityId/discussion" element={<Discussion />} />
                        <Route path="/entity/:entityId/discussion/:discussionId" element={<Discussion />} />

                        <Route path="/entity/:entityId/fixed-pages" element={<EntityPages />} />
                        <Route path="/entity/:entityId/fixed-page" element={<EntityPage />} />
                        <Route path="/entity/:entityId/fixed-page/:pageId" element={<EntityPage />} />

                        <Route path="/entity/:entityId/pages" element={<EntityPages />} />
                        <Route path="/entity/:entityId/page" element={<EntityPage />} />
                        <Route path="/entity/:entityId/page/:pageId" element={<EntityPage />} />

                        <Route path="/entity/:entityId/article-tags" element={<ArticleTags />} />
                        <Route path="/entity/:entityId/article-tag" element={<ArticleTag />} />
                        <Route path="/entity/:entityId/article-tag/:articleTagId" element={<ArticleTag />} />

                        <Route path="/entity/:entityId/article-categories" element={<ArticleCategories />} />
                        <Route path="/entity/:entityId/article-category" element={<ArticleCategory />} />
                        <Route path="/entity/:entityId/article-category/:articleCategoryId" element={<ArticleCategory />} />

                        <Route path="/entity/:entityId/articles" element={<Articles />} />
                        <Route path="/entity/:entityId/article" element={<Article />} />
                        <Route path="/entity/:entityId/article/:articleId" element={<Article />} />

                        <Route path="/entity/:entityId/users" element={<Users />} />
                        <Route path="/entity/:entityId/user" element={<User />} />
                        <Route path="/entity/:entityId/user/:userId" element={<User />} /> */}
                </>
                {/* Only product_owner (of course admin / root) can manage this section */}
                {isWarehouseManager(currentUser) &&
                    <>
                        <Route path="/warehouse_manager/:warehouseManagerId/delivery-note/:id" element={<WarehouseDeliveryNote />} />
                        <Route path="/warehouse_manager/:warehouseManagerId/delivery-notes" element={<WarehouseDeliveryNotes />} />
                    </>
                }
                {isProductOwner(currentUser) &&
                    <>
                        <Route path="/product_owner/:productOwnerId/product-inventories" element={<OwnerProductInventories />} />
                        <Route path="/product_owner/:productOwnerId/product-inventory" element={<OwnerProductInventory />} />
                        <Route path="/product_owner/:productOwnerId/product-requests" element={<OwnerProductRequests />} />
                        <Route path="/product_owner/:productOwnerId/product-request" element={<OwnerProductRequest />} />
                        <Route path="/product_owner/:productOwnerId/product-request/:productRequestId" element={<OwnerProductRequest />} />
                    </>
                }
                {/* Only operation_team (of course admin / root) can manage this section */}
                {isOperationTeam(currentUser) &&
                    <>
                        <Route path="/operation_team/inventory-requests" element={<OperationProductInventoryRequests />} />
                        <Route path="/operation_team/inventory-request" element={<OperationProductInventoryRequest />} />
                        <Route path="/operation_team/product-requests" element={<OperationProductRequests />} />
                        <Route path="/media" element={<Media />} />
                    </>
                }
                {/* Only root or admin can manage this section */}
                {(isRootOrAdmin(currentUser) || isOperationTeam(currentUser)) &&
                    <>
                        {/* <Route path="/settings/entities" element={<Entities />} />
                        <Route path="/settings/entity" element={<Entity />} />
                        <Route path="/settings/entity/:id" element={<Entity />} /> */}
                        {/* <Route path="/settings/logs" element={<Logs />} />
                        <Route path="/settings/roles" element={<Roles />} />
                        <Route path="/settings/role" element={<Role />} />
                        <Route path="/settings/role/:id" element={<Role />} /> */}
                        <Route path="/users" element={<Users />} />
                        <Route path="/user/:id" element={<User />} />
                        <Route path="/user" element={<User />} />
                        <Route path="/orders" element={<Orders />} />
                        <Route path="/delivery-notes" element={<DeliveryNotes />} />
                        <Route path="/delivery-note/:id" element={<DeliveryNote />} />
                        <Route path="/order/:id" element={<Order />} />
                        <Route path="/product-categories" element={<ProductCategories />} />
                        <Route path="/product-category" element={<ProductCategory />} />
                        <Route path="/product-category/:id" element={<ProductCategory />} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/product" element={<Product />} />
                        <Route path="/product/:id" element={<Product />} />
                    </>
                }
                {isRootOrAdmin(currentUser) && <>
                    <Route path="/admins" element={<Admins />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/admin/:id" element={<Admin />} />
                    <Route path="/warehouse/:id" element={<Warehouse />} />
                    <Route path="/warehouse" element={<Warehouse />} />
                    <Route path="/warehouses" element={<Warehouses />} />
                    <Route path="/report" element={<Report />} />
                </>}
                <Route path="settings" element={<SettingDashboard />} />
                <Route path="profile" element={<Profile />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Layout>;
    }
}

export default MemberSection;