import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Form, Input, Switch, Select } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch, useSelector } from 'react-redux';
import { createAdmin, readAdmin, updateAdmin } from '../actions/admin';
import { readWarehouses } from '../actions/warehouse';
import { isRoot } from '../helpers/user';
import { useTranslation } from 'react-i18next';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

function Admin() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.auth.user);
    const [warehousesDataSource, setWarehousesDataSource] = useState([]);
    const [role, setRole] = useState(null);
    const params = useParams();
    const { Content } = Layout;
    const [form] = Form.useForm();
    const roles = [
        "admin",
        "product_owner",
        "warehouse_manager",
        "operation_team"
    ];

    const isRootToggled = (value) => {
        form.setFieldValue('role', null);
        form.setFieldValue('warehouses', []);
        setRole(null);
    }

    const roleSelected = (value) => {
        form.setFieldValue('isRoot', false);
        form.setFieldValue('warehouses', []);
        if (typeof value !== 'undefined') {
            setRole(value);
        } else {
            setRole(null);
        }
    }

    useEffect(() => {
        dispatch(readWarehouses()).then((response) => {
            if (typeof response.result !== 'undefined') {
                setWarehousesDataSource(response.result);
            } else {
                setWarehousesDataSource([]);
            }
        }).catch((error) => {
            try {
                setWarehousesDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch]);

    const showWarehouse = (role) => {
        // only product_owner and warehouse_manager can have warehouses
        if (role === 'warehouse_manager' || role === 'product_owner') {
            return true;
        }
        return false;
    }

    const [adminTitle, setAdminTitle] = useState('');
    useEffect(() => {
        if (typeof params.id !== 'undefined') {
            setAdminTitle(t('admin.edit.title'));
            dispatch(readAdmin(params.id)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setAdminTitle(t('admin.edit.title') + ' - ' + response.result.name);
                    form.setFieldsValue(response.result);
                    if (typeof response.result.role !== 'undefined') {
                        setRole(response.result.role);
                    }
                } else {
                    message.error(t('admin.message.read-error'));
                    navigate('/admins');
                }
            }).catch(e => {
                message.error(t('admin.message.read-error'));
                navigate('/admins');
            });
        } else {
            setAdminTitle(t('admin.new.title'));
        }
    }, [params.id, dispatch, navigate, form]);

    const onFinish = (values) => {
        if (typeof values.status === 'undefined') {
            values.status = false;
        }
        if (typeof values.isRoot === 'undefined') {
            values.isRoot = false;
        }
        if (typeof values.role === 'undefined') {
            values.role = null;
        }
        if (values.isRoot) {
            // Empty the warehouses & role
            values.warehouses = [];
            values.role = null;
        }
        if (typeof params.id !== 'undefined') {
            // Update the existing admin
            dispatch(updateAdmin(params.id, values)).then((response) => {
                console.log(response);
                if (response.result) {
                    setAdminTitle('Edit Admin - ' + response.result.name);
                    form.setFieldsValue(response.result);
                    if (typeof response.result.role !== 'undefined') {
                        setRole(response.result.role);
                    }
                    console.log(response.result);
                }
                message.success(t('admin.message.update-success'));
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        } else {
            // Create new admin
            dispatch(createAdmin(values)).then((data) => {
                message.success(t('admin.message.create-success'));
                if (data.result) {
                    navigate('/admin/' + data.result.id);
                } else {
                    navigate('/admins');
                }
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    };

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <Form {...layout} form={form} name="admin" onFinish={onFinish} initialValues={{
                        'status': true
                    }}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate('/admins')}
                            title={adminTitle}
                            extra={[
                                <Button key="cancel" type="primary" danger onClick={() => navigate('/admins')}>{t('admin.button.cancel')}</Button>,
                                <Button key="save" type="primary" htmlType="submit">{params.id ? t('admin.button.update') : t('admin.button.save')}</Button>
                            ]}
                        />
                        <Form.Item name="status" label={t('admin.status.label')} valuePropName="checked">
                            <Switch />
                        </Form.Item>
                        <Form.Item
                            name="name"
                            label={t('admin.name.label')}
                            rules={[{ required: true, message: t('admin.name.required') }]}
                        >
                            <Input placeholder={t('admin.name.placeholder')} />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label={t('admin.email.label')}
                            rules={[
                                { required: true, message: t('admin.email.required') },
                                {
                                    type: 'email',
                                    message: t('admin.email.pattern'),
                                }
                            ]}
                        >
                            <Input placeholder={t('admin.email.placeholder')} />
                        </Form.Item>
                        {isRoot(currentUser) && <Form.Item name="isRoot" label={t('admin.isRoot.label')} valuePropName="checked">
                            <Switch onChange={isRootToggled} />
                        </Form.Item>}
                        <Form.Item
                            name="role"
                            label={t('admin.role.label')}
                        >
                            <Select placeholder={t('admin.role.placeholder')} allowClear onChange={roleSelected}>
                                {roles && roles.filter(role => {
                                    if (isRoot(currentUser)) {
                                        return true;
                                    } else {
                                        return role !== 'admin';
                                    }
                                }).map(role => <Select.Option key={role} value={role}>{t(`role.${role}`)}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        {showWarehouse(role) && <Form.Item
                            name="warehouses"
                            label={t('admin.warehouses.label')}
                        >
                            <Select mode="multiple" allowClear placeholder={t('admin.warehouses.placeholder')}>
                                {warehousesDataSource && warehousesDataSource.map(warehouse => <Select.Option key={warehouse.id} value={warehouse.id}>{warehouse.name}</Select.Option>)}
                            </Select>
                        </Form.Item>}
                    </Form>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Admin;