import ProductRequestService from "../services/product.request.service";

// Operation Team Use
// Action to get all product requests
export const readAllProductRequests = () => (dispatch) => {
  return ProductRequestService.readAllProductRequests().then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

// Action to add note on product request
export const addNoteOnProductRequest = (requestId, data) => (dispatch) => {
  return ProductRequestService.addNoteOnProductRequest(requestId, data).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

// Action to process the product request
export const processProductRequest = (id) => (dispatch) => {
  return ProductRequestService.processProductRequest(id).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

// Action to complete the product request
export const completeProductRequest = (id) => (dispatch) => {
  return ProductRequestService.completeProductRequest(id).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

// Action to reject the product request
export const rejectProductRequest = (id) => (dispatch) => {
  return ProductRequestService.rejectProductRequest(id).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

// Product Owner Use
// Action to get all product by product owner id
export const readProductRequests = (id) => (dispatch) => {
  return ProductRequestService.readProductRequests(id).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

// API to create product request by product owner id
export const createProductRequest = (id, data) => (dispatch) => {
  return ProductRequestService.createProductRequest(id, data).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

// API to update product request by product owner id and product request id
export const updateProductRequest = (id, requestId, data) => (dispatch) => {
  return ProductRequestService.updateProductRequest(id, requestId, data).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

// API to read product request by product owner id and product request id
export const readProductRequest = (id, requestId) => (dispatch) => {
  return ProductRequestService.readProductRequest(id, requestId).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
