import { useEffect, useState } from 'react';
import { Layout, Menu, message } from 'antd';
import {
    DesktopOutlined,
    ProfileOutlined,
    LogoutOutlined,
    ShopOutlined,
    NumberOutlined,
    CodeSandboxOutlined,
    UserOutlined,
    HistoryOutlined,
    AppstoreOutlined,
    SettingOutlined,
    ShoppingOutlined,
    HomeOutlined,
    PlusSquareOutlined,
    InboxOutlined,
    FolderOutlined,
    BarChartOutlined,
    DropboxOutlined
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { isRootOrAdmin, userBelongsTo } from '../../helpers/user';
import { logout } from "../../actions/auth";
import { readAdmins } from "../../actions/admin";
import { useTranslation } from 'react-i18next';


function MemberSidebar() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [productOwnerSource, setProductOwnerSource] = useState([]);
    const [warehouseManagerSource, setWarehouseManagerSource] = useState([]);
    const currentUser = useSelector(state => state.auth.user);

    useEffect(() => {
        if (isRootOrAdmin(currentUser)) {
            // Get all the users
            dispatch(readAdmins()).then((response) => {
                if (typeof response.result !== 'undefined') {
                    let newProductOwnerSource = [];
                    let newWarehouseManagerSource = [];
                    response.result.forEach((user) => {
                        if (!user.isRoot) {
                            if (typeof user.role !== 'undefined') {
                                if (user.role === 'product_owner') {
                                    newProductOwnerSource.push(user);
                                }
                                if (user.role === 'warehouse_manager') {
                                    newWarehouseManagerSource.push(user);
                                }
                            }
                        }
                    });
                    setProductOwnerSource(newProductOwnerSource);
                    setWarehouseManagerSource(newWarehouseManagerSource);
                } else {
                    setProductOwnerSource([]);
                    setWarehouseManagerSource([]);
                }
            }).catch((error) => {
                try {
                    setProductOwnerSource([]);
                    setWarehouseManagerSource([]);
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    }, [currentUser, dispatch]);

    const { Sider } = Layout;
    const [collapsed, setCollapsed] = useState(false);

    const productOwnerAddProductOrInventory = (id) => {
        return {
            key: `product-inventory-${id}`,
            icon: <AppstoreOutlined />,
            label: <Link to={`/product_owner/${id}/product-inventories`}>{t('sidebar.product-inventory')}</Link>,
        };
    }
    const inventoryManagement = {
        key: `inventory-management`,
        icon: <DropboxOutlined />,
        label: <Link to={`/operation_team/inventory-requests`}>{t('sidebar.inventory-management')}</Link>,
    };
    const newInventoryRequest = {
        key: `new-inventory-request`,
        icon: <NumberOutlined />,
        label: <Link to={`/operation_team/inventory-requests`}>{t('sidebar.new-inventory-request')}</Link>,
    }
    const newOperationProductRequest = {
        key: `new-product-request`,
        icon: <NumberOutlined />,
        label: <Link to={`/operation_team/product-requests`}>{t('sidebar.new-product-request')}</Link>,
    }
    const newProductRequest = (id) => {
        return {
            key: `new-product-request-${id}`,
            icon: <PlusSquareOutlined />,
            label: <Link to={`/product_owner/${id}/product-requests`}>{t('sidebar.new-product-request')}</Link>,
        };
    }
    const warehouseManagerDeliveryNotes = (id) => {
        return {
            key: `delivery-note-${id}`,
            icon: <HistoryOutlined />,
            label: <Link to={`/warehouse_manager/${id}/delivery-notes`}>{t('sidebar.delivery-note')}</Link>,
        };
    }

    let items = [];
    items.push({
        key: 'profile',
        icon: <ProfileOutlined />,
        label: <Link to="/profile">{t('sidebar.profile')}</Link>,
    });
    if (isRootOrAdmin(currentUser) || userBelongsTo(currentUser, 'operation_team')) {
        // Both product owner and warehouse manager source should be empty
        // Because the operation team doesn't have admin list
        if (productOwnerSource.length > 0) {
            productOwnerSource.forEach((user) => {
                items.push({
                    key: `product_owner-${user.id}`,
                    label: `${t('sidebar.section.product_owner')} - ${user.name}`,
                    icon: <UserOutlined />,
                    children: [
                        productOwnerAddProductOrInventory(user.id),
                        newProductRequest(user.id)
                    ]
                });
            });
        }
        if (warehouseManagerSource.length > 0) {
            warehouseManagerSource.forEach((user) => {
                items.push({
                    key: `warehouse_manager-${user.id}`,
                    label: `${t('sidebar.section.warehouse_manager')} - ${user.name}`,
                    icon: <DesktopOutlined />,
                    children: [
                        warehouseManagerDeliveryNotes(user.id)
                    ]
                });
            });
        }
        // items.push({
        //     key: 'operation',
        //     label: t('sidebar.section.operation'),
        //     icon: <CodeSandboxOutlined />,
        //     children: [
        //         inventoryManagement
        //         newInventoryRequest,
        //         newOperationProductRequest
        //     ]
        // })
        items.push({
            key: 'shop',
            label: t('sidebar.section.shop'),
            icon: <ShopOutlined />,
            children: [
                inventoryManagement,
                {
                    key: 'product',
                    label: t('sidebar.product'),
                    icon: <Link to="/products"><AppstoreOutlined /></Link>
                },
                {
                    key: 'category',
                    label: t('sidebar.category'),
                    icon: <Link to="/product-categories"><FolderOutlined /></Link>
                },
                {
                    key: 'order',
                    label: t('sidebar.order'),
                    icon: <Link to="/orders"><ShoppingOutlined /></Link>
                },
                {
                    key: 'delivery-note',
                    label: t('sidebar.delivery-note'),
                    icon: <Link to="/delivery-notes"><InboxOutlined /></Link>
                },
                {
                    key: 'user',
                    label: t('sidebar.user'),
                    icon: <Link to="/users"><UserOutlined /></Link>
                }
            ]
        });
        items.push({
            key: `media`,
            icon: <AppstoreOutlined />,
            label: <Link to={`/media`}>{t('sidebar.media')}</Link>,
        });
    }
    if (isRootOrAdmin(currentUser)) {
        items.push({
            key: 'reports',
            label: t('sidebar.reports'),
            icon: <Link to="/report"><BarChartOutlined /></Link>,
        });
        items.push({
            key: 'warehouse',
            label: t('sidebar.warehouse'),
            icon: <Link to="/warehouses"><HomeOutlined /></Link>,
        });
        items.push({
            key: 'admin',
            label: t('sidebar.admin'),
            icon: <Link to="/admins"><UserOutlined /></Link>,
        });
    }

    if (userBelongsTo(currentUser, 'product_owner')) {
        items.push(productOwnerAddProductOrInventory(currentUser.id));
        items.push(newProductRequest(currentUser.id));
    }

    if (userBelongsTo(currentUser, 'warehouse_manager')) {
        items.push(warehouseManagerDeliveryNotes(currentUser.id));
    }

    items.push({
        key: 'logout',
        icon: <LogoutOutlined />,
        label: <div onClick={() => dispatch(logout())}>{t('sidebar.logout')}</div>,
    });

    // TODO: check if enableCrm is true or false
    return <Sider width={250} collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
        <div className="logo">
            <div>{t('sidebar.system-title')}</div>
            <div>
                {isRootOrAdmin(currentUser) && <Link to="/settings"><SettingOutlined /></Link>}
            </div>
        </div>
        {/* openKeys={false && entities && entities.length > 0 && entities.map(entity => entity.id)} */}
        <Menu defaultOpenKeys={['shop']} theme="dark" mode="inline" items={items} />
    </Sider>;
}

export default MemberSidebar;