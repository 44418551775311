import React, { useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Select, Button, Table, Space, Form, Collapse, Input, InputNumber, DatePicker } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readProducts, countProduct, deleteProduct, publishProduct, unpublishProduct, sellProduct, unsellProduct, refreshProductInventoryCaches } from '../actions/product';
import { readProductCategories } from '../actions/product-category';
import moment from 'moment';
import { green, red } from '@ant-design/colors';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function Products() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { Content } = Layout;
    const [dataSource, setDataSource] = useState([]);
    const [dataSourceCount, setDataSourceCount] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [reloadFlag, setReloadFlag] = useState(new Date());
    const defaultPerPageState = searchParams.get('perPage') ? parseInt(searchParams.get('perPage')) : 40;
    const [perPage, setPerPage] = useState(defaultPerPageState);
    const defaultPageState = searchParams.get('page') ? parseInt(searchParams.get('page')) : 1;
    const [page, setPage] = useState(defaultPageState);
    const [filterForm] = Form.useForm();
    const [productCategories, setProductCategories] = useState([]);

    const refreshProductInventoriesAction = () => {
        dispatch(refreshProductInventoryCaches()).then(response => {
            message.success(t('products.message.refresh-successfully'));
            setReloadFlag(new Date());
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }


    const deleteProductAction = (id) => {
        dispatch(deleteProduct(id)).then(response => {
            message.success('Product is deleted successfully');
            setDataSource(dataSource.filter(item => item.id !== id));
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }
    const publishProductAction = (id) => {
        dispatch(publishProduct(id)).then(response => {
            message.success('Product is published successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            setReloadFlag(new Date());
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const unpublishProductAction = (id) => {
        dispatch(unpublishProduct(id)).then(response => {
            message.success('Product is unpublished successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            setReloadFlag(new Date());
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const sellProductAction = (id) => {
        dispatch(sellProduct(id)).then(response => {
            message.success('Product is sold successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            setReloadFlag(new Date());
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const unsellProductAction = (id) => {
        dispatch(unsellProduct(id)).then(response => {
            message.success('Product is unsold successfully');
            setReloadFlag(new Date());
        }).catch(error => {
            setReloadFlag(new Date());
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const columns = [
        {
            title: t('products.columns.primary_image'),
            dataIndex: 'primary_image',
            key: 'primary_image',
            render: (text, record) => {
                if (record.primary_image) {
                    return <a href={record.primary_image} target="_blank" rel="noreferrer"><img src={record.primary_image} alt={record.name} width="80" /></a>
                }
            }
        },
        {
            title: t('products.columns.sku'),
            dataIndex: 'sku',
            key: 'sku',
        },
        {
            title: t('products.columns.name'),
            dataIndex: 'name',
            key: 'name',
            width: 350,
            render: (text, record) => {
                if (typeof record.translations !== 'undefined') {
                    return <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
                        {record.translations.map((translation, key) => {
                            return <li key={key}>[{translation.lang}] {translation.name}</li>
                        })}
                    </ul>
                } else {
                    return 'N/A';
                }
            }
        },
        {
            title: t('products.columns.volume'),
            dataIndex: 'volume',
            key: 'volume',
            align: 'center'
        },
        {
            title: t('products.columns.price'),
            dataIndex: 'price',
            key: 'price',
            align: 'center'
        },
        {
            title: t('products.columns.saleable'),
            dataIndex: 'saleable',
            key: 'saleable',
            align: 'center',
            render: (text, record) => {
                if (record.saleable === true) {
                    return <CheckOutlined style={{ color: green.primary }} />
                } else {
                    return <CloseOutlined style={{ color: red.primary }} />
                }
            }
        },
        {
            title: t('products.columns.published'),
            dataIndex: 'published',
            key: 'published',
            align: 'center',
            render: (text, record) => {
                if (record.published === true) {
                    return <CheckOutlined style={{ color: green.primary }} />
                } else {
                    return <CloseOutlined style={{ color: red.primary }} />
                }
            }
        },
        {
            title: t('products.columns.quantity'),
            dataIndex: 'allQuantity',
            key: 'allQuantity',
            children: [
                {
                    align: 'center',
                    title: t('products.columns.quantity'),
                    dataIndex: 'quantity',
                    key: 'quantity',
                },
                {
                    align: 'center',
                    title: t('products.columns.orderedQuantity'),
                    dataIndex: 'orderedQuantity',
                    key: 'orderedQuantity',
                },
            ]
        },
        {
            title: t('products.columns.created_at'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text, record) => {
                return moment(record.created_at).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        {
            title: t('products.columns.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return <Space.Compact>
                    <Button type="primary" onClick={() => navigate(`/product/${record.id}`)}>{t('products.button.edit')}</Button>
                    <Popconfirm
                        title={t('products.confirm.delete_message')}
                        onConfirm={() => deleteProductAction(record.id)}
                        okText={t('products.confirm.delete_confirm_ok')}
                        cancelText={t('products.confirm.delete_confirm_cancel')}
                    >
                        <Button danger type="primary">{t('products.button.delete')}</Button>
                    </Popconfirm>
                    {record.saleable === false ? <Popconfirm
                        title={t('products.confirm.sell_message')}
                        onConfirm={() => sellProductAction(record.id)}
                        okText={t('products.confirm.sell_confirm_ok')}
                        cancelText={t('products.confirm.sell_confirm_cancel')}
                    ><Button type="primary">{t('products.button.sell')}</Button></Popconfirm> : <Popconfirm
                        title={t('products.confirm.unsell_message')}
                        onConfirm={() => unsellProductAction(record.id)}
                        okText={t('products.confirm.unsell_confirm_ok')}
                        cancelText={t('products.confirm.unsell_confirm_cancel')}
                    ><Button type="primary" danger>{t('products.button.unsell')}</Button></Popconfirm>}
                    {record.published === false ? <Popconfirm
                        title={t('products.confirm.publish_message')}
                        onConfirm={() => publishProductAction(record.id)}
                        okText={t('products.confirm.publish_confirm_ok')}
                        cancelText={t('products.confirm.publish_confirm_cancel')}
                    ><Button type="primary">{t('products.button.publish')}</Button></Popconfirm> : <Popconfirm
                        title={t('products.confirm.unpublish_message')}
                        onConfirm={() => unpublishProductAction(record.id)}
                        okText={t('products.confirm.unpublish_confirm_ok')}
                        cancelText={t('products.confirm.unpublish_confirm_cancel')}
                    ><Button type="primary" danger>{t('products.button.unpublish')}</Button></Popconfirm>}
                </Space.Compact>
            }
        }
    ];

    useEffect(() => {
        dispatch(readProductCategories()).then((response) => {
            if (typeof response.result !== 'undefined') {
                setProductCategories(response.result.map(item => {
                    let name = [];
                    if (typeof item.translations !== 'undefined' && Array.isArray(item.translations) && item.translations.length > 0) {
                        name = item.translations.map((translation) => {
                            return translation.name;
                        });
                    }
                    return {
                        label: `${item.slug} ${name.length > 0 ? ` - ${name.join('/')}` : ''}`,
                        value: item._id
                    }
                }));
            } else {
                setProductCategories([]);
            }
        }).catch((error) => {
            try {
                setProductCategories([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, []);

    useEffect(() => {
        dispatch(countProduct(searchParams)).then(function (response) {
            if (typeof response.result !== 'undefined') {
                setDataSourceCount(response.result);
            } else {
                message.error('Error while fetching media');
            }
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
        dispatch(readProducts(searchParams)).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
            } else {
                setDataSource([]);
            }
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [reloadFlag, dispatch, navigate, searchParams]);

    useEffect(() => {
        let newSearchParams = {};
        for (const entry of searchParams.entries()) {
            const [key, value] = entry;
            if (typeof value !== 'undefined' && value !== '') {
                newSearchParams[key] = value;
            }
        }
        newSearchParams['page'] = page;
        newSearchParams['perPage'] = perPage;
        setSearchParams(newSearchParams);
    }, [perPage, page, setSearchParams]);

    useEffect(() => {
        for (const entry of searchParams.entries()) {
            const [key, value] = entry;
            if (typeof value !== 'undefined' && value !== '') {
                filterForm.setFieldValue(key, value);
            }
        }
    }, [searchParams]);

    const { Panel } = Collapse;

    const productDataSettings = [
        {
            slug: 'sku',
            name: t('products.columns.sku'),
            type: 'text'
        },
        {
            slug: 'name',
            name: t('products.columns.name'),
            type: 'text'
        },
        {
            slug: 'volume',
            name: t('products.columns.volume'),
            type: 'text'
        },
        {
            slug: 'saleable',
            name: t('products.columns.saleable'),
            type: 'boolean'
        },
        {
            slug: 'published',
            name: t('products.columns.published'),
            type: 'boolean'
        },
        {
            slug: 'categories',
            name: t('products.columns.categories'),
            type: 'dropdown',
            dropdownOptions: productCategories
        }
    ]

    const applyFilters = (values) => {
        console.log('values', values);
        let newSearchParams = Object.assign({}, { page, perPage });
        for (let key in values) {
            if (typeof values[key] !== 'undefined' && values[key] !== '') {
                if (moment.isMoment(values[key])) {
                    newSearchParams[key] = values[key].format('YYYY-MM-DD');
                } else {
                    newSearchParams[key] = values[key];
                }
            }
        }
        console.log('values', values);
        setSearchParams(newSearchParams);
    }

    const clearFilters = () => {
        let newSearchParams = Object.assign({}, { page, perPage });
        filterForm.resetFields();
        setSearchParams(newSearchParams);
    }

    let hasSearchParams = false;
    for (const entry of searchParams.entries()) {
        const [param] = entry;
        if (param !== 'page' && param !== 'perPage') {
            hasSearchParams = true;
            break;
        }
    }

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title={t('products.title')}
                        subTitle={t('products.subtitle')}
                        extra={[
                            <Popconfirm
                                key="refresh"
                                title={t('products.confirm.refresh_message')}
                                onConfirm={() => refreshProductInventoriesAction()}
                                okText={t('products.confirm.refresh_confirm_ok')}
                                cancelText={t('products.confirm.refresh_confirm_cancel')}
                            ><Button type="primary">{t('products.button.refresh')}</Button></Popconfirm>,
                            <Button key="create" type="primary" onClick={() => navigate('/product')}>{t('products.button.new')}</Button>
                        ]}
                    />
                    <Collapse style={{ marginTop: 10 }} bordered={false} defaultActiveKey={hasSearchParams ? ['filter'] : []}>
                        <Panel header={<strong>{t('products.filter.title')}</strong>} key="filter">
                            <Form form={filterForm} layout="inline" onFinish={applyFilters}>
                                <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key="_id" name="_id" label="ID">
                                    <Input />
                                </Form.Item>
                                {productDataSettings.map(field => {
                                    switch (field.type) {
                                        case 'checkbox':
                                        case 'radio':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Select allowClear={true}>{field.choiceOptions && field.choiceOptions.map(option => <Select.Option key={option} value={option.value}>{option.label}</Select.Option>)}</Select>
                                            </Form.Item>;
                                        case 'dropdown':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Select allowClear={true}>{field.dropdownOptions && field.dropdownOptions.map(option => <Select.Option key={option} value={option.value}>{option.label}</Select.Option>)}</Select>
                                            </Form.Item>;
                                        case 'number':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <InputNumber />
                                            </Form.Item>;
                                        case 'email':
                                        case 'primary_email':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} rules={[{ type: 'email' }]}>
                                                <Input />
                                            </Form.Item>;
                                        case 'date':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} rules={[{ type: 'date' }]}>
                                                <DatePicker />
                                            </Form.Item>;
                                        case 'text':
                                        case 'textarea':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Input />
                                            </Form.Item>;
                                        case 'boolean':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} valuePropName="checked">
                                                <Select allowClear={true}>
                                                    <Select.Option value="true">True</Select.Option>
                                                    <Select.Option value="false">False</Select.Option>
                                                </Select>
                                            </Form.Item>;
                                        default:
                                            console.log(`Please contact admin to add support for ${field.name} <${field.slug}|${field.type}> field type`);
                                            return null;
                                    }
                                })}
                                {/* <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key="createdAt" name="createdAt" label="Created At" rules={[{ type: 'date' }]}>
                                    <DatePicker />
                                </Form.Item> */}
                                <Form.Item style={{ width: '100%' }}>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            {t('products.filter.apply')}
                                        </Button>
                                        <Button type="primary" danger onClick={clearFilters}>
                                            {t('products.filter.clear')}
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </Panel>
                    </Collapse>
                    <Table
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns}
                        size='small'
                        pagination={{
                            position: ['topRight', 'bottomRight'],
                            total: dataSourceCount,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            pageSize: perPage,
                            current: page,
                            onChange: (page, pageSize) => {
                                setPage(page);
                                setPerPage(pageSize);
                            }
                        }}
                    />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Products;