import React, { useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readWarehouses, deleteWarehouse } from '../actions/warehouse';
import { PageHeader } from '@ant-design/pro-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function Warehouses() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Content } = Layout;
    const [dataSource, setDataSource] = useState([]);

    const deleteWarehouseAction = (id) => {
        dispatch(deleteWarehouse(id)).then((response) => {
            message.success(t('warehouses.message.delete-success'));
            setDataSource(dataSource.filter(item => item.id !== id));
        }).catch(e => {
            console.log(e);
            message.error(t('warehouses.message.delete-failed'));
        });
    }

    const columns = [
        {
            title: t('warehouses.columns.id'),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t('warehouses.columns.name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('warehouses.columns.slug'),
            dataIndex: 'slug',
            key: 'slug',
        },
        {
            title: t('warehouses.columns.created'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => <>{moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</>
        },
        {
            title: t('warehouses.columns.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => <Space>
                <Button type="primary" onClick={() => navigate(`/warehouse/${record.id}`)}>{t('warehouses.actions.edit')}</Button>
                <Popconfirm
                    title={t('warehouses.message.delete-confirm')}
                    onConfirm={() => deleteWarehouseAction(record.id)}
                    okText={t('warehouses.actions.delete-ok')}
                    cancelText={t('warehouses.actions.delete-cancel')}
                >
                    <Button type="primary" danger>{t('warehouses.actions.delete')}</Button>
                </Popconfirm>
            </Space>

        }
    ];

    useEffect(() => {
        dispatch(readWarehouses()).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
            } else {
                setDataSource([]);
            }
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch]);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title={t('warehouses.title')}
                        subTitle={t('warehouses.subtitle')}
                        extra={[
                            <Button key="create" type="primary" onClick={() => navigate('/warehouse')}>{t('warehouses.actions.new')}</Button>
                        ]}
                    />
                    <Table
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns} />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Warehouses;