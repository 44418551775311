import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Form, Input, Select, InputNumber, Radio } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { createProductInventoryByOperationTeam } from '../actions/product.inventory';
import { readWarehouses } from '../actions/warehouse';
import ProductService from '../services/product.service';
import AdminService from '../services/admin.service';
import { useTranslation } from 'react-i18next';
import ReferenceSelect from './fields/referenceSelect.field';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 14,
    },
};

function OperationProductInventoryRequest() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [warehousesDataSource, setWarehousesDataSource] = useState([]);
    const params = useParams();
    const { Content } = Layout;
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(readWarehouses()).then((response) => {
            if (typeof response.result !== 'undefined') {
                setWarehousesDataSource(response.result);
            } else {
                setWarehousesDataSource([]);
            }
        }).catch((error) => {
            try {
                setWarehousesDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch]);

    useEffect(() => {
        setProductInventoryTitle(t('product-inventory.new.title'));
    }, [params.productOwnerId, dispatch, navigate, form, t]);

    const [adminTitle, setProductInventoryTitle] = useState('');

    const onFinish = (values) => {
        console.log(values);
        // Create new admin
        dispatch(createProductInventoryByOperationTeam(values)).then((data) => {
            message.success(t('product-inventory.message.create-success'));
            if (data.result) {
                navigate('/operation_team/inventory-requests');
            } else {
                navigate('/operation_team/inventory-requests');
            }
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    };

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <Form {...layout} form={form} name="product-inventory" onFinish={onFinish} initialValues={{
                        status: 'processing'
                    }}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate(`/operation_team/inventory-requests`)}
                            title={adminTitle}
                            extra={[
                                <Button key="cancel" type="primary" danger onClick={() => navigate('/admins')}>{t('product-inventory.button.cancel')}</Button>,
                                <Button key="save" type="primary" htmlType="submit">{t('product-inventory.button.save')}</Button>
                            ]}
                        />
                        <Form.Item
                            name="status"
                            label={t('product-inventory.status.label')}
                            rules={[{ required: true, message: t('product-inventory.status.required') }]}
                        >
                            <Radio.Group buttonStyle="solid" defaultValue={'processing'}>
                                {['pending', 'processing', 'approved', 'rejected', 'freezed'].map(status => {
                                    return <Radio.Button key={status} value={status}>{t(`product-inventory.status.${status}`)}</Radio.Button>
                                })}
                            </Radio.Group>
                        </Form.Item>
                        <ReferenceSelect
                            isMultiple={false}
                            selectProps={{
                                showSearch: true,
                                allowClear: true,
                            }}
                            onFetch={ProductService.searchProductsBySkuOrName}
                            inner={{
                                rules: [{ required: true, message: t('product-inventory.product.required') }],
                                name: "product"
                            }}
                            wrapper={{
                                rules: [{ required: true, message: t('product-inventory.product.required') }],
                                label: t('product-inventory.product.label'),
                            }} />
                        <ReferenceSelect
                            isMultiple={false}
                            selectProps={{
                                showSearch: true,
                                allowClear: true,
                            }}
                            onFetch={AdminService.readProductOwnerAdmins}
                            inner={{
                                rules: [{ required: true, message: t('product-inventory.owner.required') }],
                                name: "owner"
                            }}
                            wrapper={{
                                rules: [{ required: true, message: t('product-inventory.owner.required') }],
                                label: t('product-inventory.owner.label'),
                            }} />
                        <Form.Item
                            name="warehouse"
                            label={t('product-inventory.warehouse.label')}
                            rules={[{ required: true, message: t('product-inventory.warehouse.required') }]}
                        >
                            <Select allowClear placeholder={t('referenceSelect.select')}>
                                {warehousesDataSource.map((warehouse) => (
                                    <Select.Option key={warehouse.id} value={warehouse.id}>{warehouse.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="initialQuantity"
                            label={t('product-inventory.initial-quantity.label')}
                            rules={[{ required: true, message: t('product-inventory.initial-quantity.required') }]}
                        >
                            <InputNumber min={1} />
                        </Form.Item>
                        <Form.Item
                            name="remark"
                            label={t('product-inventory.remark.label')}
                        >
                            <Input.TextArea
                                placeholder={t("product-inventory.remark.placeholder")}
                                autoSize={{ minRows: 5, maxRows: 10 }}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default OperationProductInventoryRequest;