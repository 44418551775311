import authHeader from './auth-header';
import axios from "axios";
import {
    API_OWNER_PRODUCT_REQUEST_URL,
    API_OPERATION_PRODUCT_REQUEST_URL
} from "./config";

// API to get all product requests
const readAllProductRequests = () => {
    return axios.get(API_OPERATION_PRODUCT_REQUEST_URL(null), {
        headers: authHeader()
    }).then(res => res.data);
}

// API to process the product request
const processProductRequest = (id) => {
    return axios.put(API_OPERATION_PRODUCT_REQUEST_URL(id) + 'process', null, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to approve the product request
const completeProductRequest = (id) => {
    return axios.put(API_OPERATION_PRODUCT_REQUEST_URL(id) + 'complete', null, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to reject the product request
const rejectProductRequest = (id) => {
    return axios.put(API_OPERATION_PRODUCT_REQUEST_URL(id) + 'reject', null, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to add note on product request by product request id
const addNoteOnProductRequest = (id, data) => {
    return axios.put(API_OPERATION_PRODUCT_REQUEST_URL(id) + 'note', data, {
        headers: authHeader()
    }).then(res => res.data);
}


// Product Owner Use
// API to get all product requests by product owner id
const readProductRequests = (id) => {
    return axios.get(API_OWNER_PRODUCT_REQUEST_URL(id), {
        headers: authHeader()
    }).then(res => res.data);
}

// API to create product request by product owner id
const createProductRequest = (id, data) => {
    return axios.post(API_OWNER_PRODUCT_REQUEST_URL(id), data, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to update product request by product owner id and product request id
const updateProductRequest = (id, requestId, data) => {
    return axios.put(API_OWNER_PRODUCT_REQUEST_URL(id) + requestId, data, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to read product request by product owner id and product request id
const readProductRequest = (id, requestId) => {
    return axios.get(API_OWNER_PRODUCT_REQUEST_URL(id) + requestId, {
        headers: authHeader()
    }).then(res => res.data);
}


const productRequest = {
    // product owner use
    readProductRequests,
    createProductRequest,
    updateProductRequest,
    readProductRequest,
    // operation team use
    readAllProductRequests,
    processProductRequest,
    completeProductRequest,
    rejectProductRequest,
    addNoteOnProductRequest,
};

export default productRequest;