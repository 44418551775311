import OrderService from "../services/order.service";

// Actino to assign stock to order
export const assignStockToOrder = (id, stockValues) => (dispatch) => {
    return OrderService.assignStockToOrder(id, stockValues).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get order by id
export const readOrder = (id) => (dispatch) => {
    return OrderService.readOrder(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to ship the order
export const shipOrder = (id) => (dispatch) => {
    return OrderService.shipOrder(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to complete order
export const completeOrder = (id) => (dispatch) => {
    return OrderService.completeOrder(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to count order
export const countOrder = (searchParams) => (dispatch) => {
    return OrderService.countOrder(searchParams).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all orders
export const readOrders = (searchParams) => (dispatch) => {
    return OrderService.readOrders(searchParams).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to cancel the order
export const cancelOrder = (id) => (dispatch) => {
    return OrderService.cancelOrder(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to refund the order
export const refundOrder = (id, amount) => (dispatch) => {
    return OrderService.refundOrder(id, amount).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to process order
export const processOrder = (id) => (dispatch) => {
    return OrderService.processOrder(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to add note to order
export const addNoteToOrder = (id, note) => (dispatch) => {
    return OrderService.addNoteToOrder(id, note).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to ship the order
export const processOrderPreparing = (id) => (dispatch) => {
    return OrderService.processOrderPreparing(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}