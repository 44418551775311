import WarehouseService from "../services/warehouse.service";

// Action to get warehouse by id
export const readWarehouse = (id) => (dispatch) => {
    return WarehouseService.readWarehouse(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all warehouses
export const readWarehouses = () => (dispatch) => {
    return WarehouseService.readWarehouses().then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the warehouse
export const createWarehouse = (warehouse) => (dispatch) => {
    return WarehouseService.createWarehouse(warehouse).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to update the warehouse
export const updateWarehouse = (id, warehouse) => (dispatch) => {
    return WarehouseService.updateWarehouse(id, warehouse).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the warehouse
export const deleteWarehouse = (id) => (dispatch) => {
    return WarehouseService.deleteWarehouse(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}