import { Layout, Form, Input, Button, Row, Col, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import './login.page.scss';
import { reset } from "../actions/auth";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Reset = (props) => {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const dispatch = useDispatch();
    const params = useParams();
    const { t } = useTranslation();
    console.log(params);

    const onFinish = (values) => {
        if (typeof params.id !== 'undefined' && typeof params.token !== 'undefined') {
            dispatch(reset(params.id, params.token, values.password)).then(() => {
                message.success(t('reset.update-success'));
                navigate('/login');
            }).catch((error) => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        } else {
            message.error(`Your ${props.mode ? props.mode : 'reset'} link is invalid`);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        message.error(JSON.stringify(errorInfo));
    };


    useEffect(() => {
        if (isLoggedIn) {
            navigate('/dashboard');
        }
    }, [isLoggedIn, navigate]);

    if (!isLoggedIn) {
        return <Layout className="crm-layout crm-layout--login" style={{ minHeight: '100vh' }}>
            <Form
                name="basic"
                labelCol={{
                    // span: 12,
                }}
                wrapperCol={{
                    span: 6,
                    offset: 9
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Row>
                    <Col span={6} offset={9}>
                        <h1 className="crm-layout__title">{t('reset.update-title')}</h1>
                    </Col>
                </Row>

                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: t('reset.password-required'),
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder={t('reset.password.placeholder')} />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: t('reset.password-confirmation-required'),
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error(t('reset.password-confirmation-not-match')));
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder={t('reset.password-confirmation.placeholder')} />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 9,
                        span: 6,
                    }}
                >
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                        {t('reset.update')}
                    </Button>
                </Form.Item>
            </Form>
        </Layout>;
    } else {
        return '';
    }
};

export default Reset;