import React, { useEffect, useState } from 'react';
import { Layout, message, Select, Button, Table, Space, Form, Collapse, Input, InputNumber, DatePicker } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readUsers, countUser } from '../actions/user';
import moment from 'moment';
import { green, red } from '@ant-design/colors';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function Users() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { Content } = Layout;
    const [dataSource, setDataSource] = useState([]);
    const [dataSourceCount, setDataSourceCount] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultPerPageState = searchParams.get('perPage') || 40;
    const [perPage, setPerPage] = useState(defaultPerPageState);
    const defaultPageState = searchParams.get('page') || 1;
    const [page, setPage] = useState(defaultPageState);
    const [filterForm] = Form.useForm();

    const columns = [
        {
            title: t('users.columns.id'),
            dataIndex: 'id',
            key: 'id',
            width: 240
        },
        {
            title: t('users.columns.lastName'),
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: t('users.columns.firstName'),
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: t('users.columns.phoneRegion'),
            dataIndex: 'phoneRegion',
            key: 'phoneRegion',
        },
        {
            title: t('users.columns.phoneNumber'),
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: t('users.columns.email'),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: t('users.columns.fromGoogle'),
            dataIndex: 'fromGoogle',
            key: 'fromGoogle',
            align: 'center',
            render: (text, record) => {
                return (record.google && record.google.id) ? <CheckOutlined style={{ color: green.primary }} /> : <CloseOutlined style={{ color: red.primary }} />;
            }
        },
        {
            title: t('users.columns.createdAt'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => {
                return moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        {
            title: t('users.columns.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return <Space>
                    <Button type="primary" onClick={() => navigate(`/user/${record.id}`)}>{t('user.button.read')}</Button>
                    <Button type="primary" style={{ backgroundColor: green[5] }} onClick={() => navigate(`/orders?user=${record.id}`)}>{t('user.button.orders')}</Button>
                </Space>
            }
        }
    ];

    useEffect(() => {
        dispatch(countUser(searchParams)).then(function (response) {
            if (typeof response.result !== 'undefined') {
                setDataSourceCount(response.result);
            } else {
                message.error('Error while fetching media');
            }
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
        dispatch(readUsers(searchParams)).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
            } else {
                setDataSource([]);
            }
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch, navigate, searchParams]);

    useEffect(() => {
        // set form values
        searchParams.forEach((value, key) => {
            filterForm.setFieldsValue({ [key]: value });
        });
    }, [searchParams, filterForm]);

    const { Panel } = Collapse;

    const userDataSettings = [
        {
            slug: 'email',
            name: t('users.columns.email'),
            type: 'text'
        },
        {
            slug: 'name',
            name: t('users.columns.name'),
            type: 'text'
        },
        {
            slug: 'phoneNumber',
            name: t('users.columns.phoneNumber'),
            type: 'text'
        },
        {
            slug: 'google',
            name: t('users.columns.fromGoogle'),
            type: 'boolean'
        }
    ];

    const applyFilters = (values) => {
        console.log('values', values);
        let newSearchParams = Object.assign({}, { page, perPage });
        for (let key in values) {
            if (typeof values[key] !== 'undefined' && values[key] !== '') {
                if (moment.isMoment(values[key])) {
                    newSearchParams[key] = values[key].format('YYYY-MM-DD');
                } else {
                    newSearchParams[key] = values[key];
                }
            }
        }
        console.log('values', values);
        setSearchParams(newSearchParams);
    }

    const clearFilters = () => {
        let newSearchParams = Object.assign({}, { page, perPage });
        filterForm.resetFields();
        setSearchParams(newSearchParams);
    }

    let hasSearchParams = false;
    for (const entry of searchParams.entries()) {
        const [param] = entry;
        console.log('param', param);
        if (param !== 'page' && param !== 'perPage') {
            hasSearchParams = true;
            break;
        }
    }

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title={t('users.title')}
                        subTitle={t('users.subtitle')}
                        extra={[
                            <Button key="create" type="primary" onClick={() => navigate('/user')}>{t('users.button.new')}</Button>
                        ]}
                    />
                    <Collapse style={{ marginTop: 10 }} bordered={false} defaultActiveKey={hasSearchParams ? ['filter'] : []}>
                        <Panel header={<strong>{t('users.filter.title')}</strong>} key="filter">
                            <Form form={filterForm} layout="inline" onFinish={applyFilters}>
                                <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key="_id" name="_id" label="ID">
                                    <Input />
                                </Form.Item>
                                {userDataSettings.map(field => {
                                    switch (field.type) {
                                        case 'checkbox':
                                        case 'radio':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Select allowClear={true}>{field.choiceOptions && field.choiceOptions.map(option => <Select.Option key={option} value={option.value}>{option.label}</Select.Option>)}</Select>
                                            </Form.Item>;
                                        case 'dropdown':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Select allowClear={true}>{field.dropdownOptions && field.dropdownOptions.map(option => <Select.Option key={option} value={option.value}>{option.label}</Select.Option>)}</Select>
                                            </Form.Item>;
                                        case 'number':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <InputNumber />
                                            </Form.Item>;
                                        case 'email':
                                        case 'primary_email':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} rules={[{ type: 'email' }]}>
                                                <Input />
                                            </Form.Item>;
                                        case 'date':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} rules={[{ type: 'date' }]}>
                                                <DatePicker />
                                            </Form.Item>;
                                        case 'text':
                                        case 'textarea':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Input />
                                            </Form.Item>;
                                        case 'boolean':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} valuePropName="checked">
                                                <Select allowClear={true}>
                                                    <Select.Option value="true">True</Select.Option>
                                                    <Select.Option value="false">False</Select.Option>
                                                </Select>
                                            </Form.Item>;
                                        default:
                                            console.log(`Please contact admin to add support for ${field.name} <${field.slug}|${field.type}> field type`);
                                            return null;
                                    }
                                })}
                                {/* <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key="createdAt" name="createdAt" label="Created At" rules={[{ type: 'date' }]}>
                                    <DatePicker />
                                </Form.Item> */}
                                <Form.Item style={{ width: '100%' }}>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            {t('users.filter.apply')}
                                        </Button>
                                        <Button type="primary" danger onClick={clearFilters}>
                                            {t('users.filter.clear')}
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </Panel>
                    </Collapse>
                    <Table
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns}
                        size='small'
                        pagination={{
                            position: ['topRight', 'bottomRight'],
                            total: dataSourceCount,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            pageSize: perPage,
                            current: page,
                            onChange: (page, pageSize) => {
                                setPage(page);
                                setPerPage(pageSize);
                            }
                        }}
                    />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Users;