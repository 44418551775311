import React, { useEffect } from 'react';
import { Layout, Form, Input, Button, message } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import MemberSidebar from './components/member.sidebar.component';
import { useNavigate } from 'react-router-dom';
import MemberFooter from './components/member.footer.component';
import { useDispatch, useSelector } from 'react-redux';
import { profileUpdateName, profileUpdatePassword } from '../actions/auth';
import { useTranslation } from 'react-i18next';

function Profile() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.auth.user);
    const { Content } = Layout;
    const [form] = Form.useForm();
    const [passwordForm] = Form.useForm();

    const onFinishName = (values) => {
        console.log('Success:', values);
        dispatch(profileUpdateName(currentUser ? currentUser.id : null, values.name)).then(() => {
            message.success(t('profile.message.updated-successfully'));
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    };

    const onFinishPassword = (values) => {
        console.log('Success:', values);
        dispatch(profileUpdatePassword(currentUser ? currentUser.id : null, values.oldPassword, values.newPassword)).then(() => {
            message.success(t('profile.message.updated-password-successfully'));
            passwordForm.resetFields();
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        message.error(JSON.stringify(errorInfo));
    };

    useEffect(() => {
        if (typeof currentUser.name !== 'undefined') {
            form.setFieldsValue({
                name: currentUser.name
            });
        }
    }, [form, currentUser.name]);


    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title={t('profile.title')}
                        subTitle={t('profile.subtitle')}
                    />
                    <Form
                        form={form}
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 8 }}
                        onFinish={onFinishName}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label={t('profile.field.name.label')}
                            name="name"
                            rules={[{ required: true, message: t('profile.field.name.required') }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                {t('profile.button.update-name')}
                            </Button>
                        </Form.Item>
                    </Form>
                    <Form
                        form={passwordForm}
                        name="password"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 8 }}
                        onFinish={onFinishPassword}
                        onFinishFailed={onFinishFailed}
                        initialValues={{}}
                        autoComplete="off"
                    >
                        <h1 style={{ textAlign: 'center' }}>{t('profile.headings.update-password')}</h1>
                        <Form.Item
                            name="oldPassword"
                            label={t('profile.field.old-password.label')}
                            rules={[
                                {
                                    required: true,
                                    message: t('profile.field.old-password.required'),
                                },
                            ]}
                        >
                            <Input.Password placeholder={t('profile.field.old-password.placeholder')} />
                        </Form.Item>
                        <Form.Item
                            name="newPassword"
                            label={t('profile.field.new-password.label')}
                            rules={[
                                {
                                    required: true,
                                    message: t('profile.field.new-password.required')
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password placeholder={t('profile.field.new-password.placeholder')} />
                        </Form.Item>
                        <Form.Item
                            name="confirmNewPassword"
                            label={t('profile.field.confirm-new-password.label')}
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: t('profile.field.confirm-new-password.required'),
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(t('profile.message.password-not-match')));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder={t('profile.field.confirm-new-password.placeholder')} />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                {t('profile.button.update-password')}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Profile;