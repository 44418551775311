import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Table, Space, Select, Form, Collapse, Input, InputNumber, DatePicker, Descriptions } from 'antd';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readProductInventories } from '../actions/product.inventory';
import { PageHeader } from '@ant-design/pro-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'underscore';

function OwnerProductInventories() {
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [filterForm] = Form.useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const { Content } = Layout;
    const [dataSource, setDataSource] = useState([]);

    const expandRecord = (record) => {
        return <>
            <div>Stock ID: {record.id}</div>
            {record.remark && <Descriptions bordered column={1}>
                {record.remark && <Descriptions.Item label={t('operationProductInventoryRequests.columns.remark')} labelStyle={{ width: 240 }}>{record.remark}</Descriptions.Item>}
            </Descriptions>}
            {(Array.isArray(record.logs) && record.logs.length > 0) ? <Table
                rowKey={record => record._id}
                dataSource={_.sortBy(record.logs, 'createdAt').reverse()}
                columns={[
                    {
                        title: t('operationProductInventoryRequests.columns.logs.action'),
                        dataIndex: 'action',
                        key: 'action',
                    },
                    {
                        title: t('operationProductInventoryRequests.columns.logs.note'),
                        dataIndex: 'note',
                        key: 'note',
                    },
                    {
                        title: t('operationProductInventoryRequests.columns.logs.date'),
                        dataIndex: 'date',
                        key: 'date',
                        render: (text, record) => {
                            if (typeof record.date !== 'undefined' && record.date !== null) {
                                return moment(record.date).format('DD/MM/YYYY HH:mm:ss');
                            } else {
                                return 'N/A';
                            }
                        }
                    }
                ]}
            >
            </Table> : <div>{t('logs.not-available')}</div>}
        </>;
    }

    const columns = [
        {
            title: t('ownerProductInventories.columns.id'),
            dataIndex: 'id',
            key: 'id',
            width: '200px',
            render: (text, record) => <div style={{ fontSize: '0.8em' }}>{record.id}</div>
        },
        {
            title: t('ownerProductInventories.columns.product'),
            dataIndex: 'product',
            key: 'product',
            width: '250px',
            render: (text, record) => {
                if (record.productDetail) {
                    let productDetail = [record.productDetail.sku];
                    if (typeof record.productDetail.translations !== 'undefined' && Array.isArray(record.productDetail.translations)) {
                        record.productDetail.translations.forEach((translation) => {
                            console.log(translation);
                            if (typeof translation.name !== 'undefined' && translation.lang === 'zh-HK') {
                                productDetail.push(<br />);
                                productDetail.push(translation.name);
                            }
                        });
                    }
                    return <div style={{ fontSize: '0.8em' }}>{productDetail}<br />&lt;<Link to={`/product/${record.product}`}>{record.product}</Link>&gt;</div>;
                } else {
                    return <><Link to={`/product/${record.product}`}>{record.product}</Link></>
                }
            }
        },
        {
            title: t('ownerProductInventories.columns.warehouse'),
            dataIndex: 'warehouse',
            key: 'warehouse',
            render: (text, record) => <>{record.warehouse.name}</>
        },
        {
            title: t('ownerProductInventories.columns.quantity'),
            dataIndex: 'quantity',
            key: 'quantity',
            children: [
                {
                    align: 'center',
                    title: t('ownerProductInventories.columns.initialQuantity'),
                    dataIndex: 'initialQuantity',
                    key: 'initialQuantity',
                },
                {
                    align: 'center',
                    title: t('ownerProductInventories.columns.currentQuantity'),
                    dataIndex: 'currentQuantity',
                    key: 'currentQuantity',
                },
                {
                    align: 'center',
                    title: t('ownerProductInventories.columns.orderedQuantity'),
                    dataIndex: 'orderedQuantity',
                    key: 'orderedQuantity',
                },
            ]
        },
        {
            title: t('ownerProductInventories.columns.status'),
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text, record) => <>{t(`ownerProductInventories.columns.${record.status}`)}</>
        },
        {
            title: t('ownerProductInventories.columns.remark'),
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            render: (text, record) => {
                if (typeof record.remark !== 'undefined' && record.remark && record.remark !== '') {
                    return t('admins.columns.yes');
                } else {
                    return t('admins.columns.no');
                }
            }
        },
        {
            title: t('ownerProductInventories.columns.created'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => <>{moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</>
        },
        // {
        //     title: t('ownerProductInventories.columns.actions'),
        //     dataIndex: 'actions',
        //     key: 'actions',
        //     render: (text, record) => <Space>
        //         <span>N.A.</span>
        //     </Space>

        // }
    ];

    useEffect(() => {
        // set form values
        searchParams.forEach((value, key) => {
            filterForm.setFieldsValue({ [key]: value });
        });
    }, [searchParams, filterForm]);

    useEffect(() => {
        dispatch(readProductInventories(params.productOwnerId, searchParams)).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
                console.log(response.result);
            } else {
                setDataSource([]);
            }
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch, params.productOwnerId, searchParams]);

    const productDataSettings = [
        {
            slug: 'sku',
            name: t('products.columns.sku'),
            type: 'text'
        },
        {
            slug: 'name',
            name: t('products.columns.name'),
            type: 'text'
        },
        {
            slug: 'status',
            name: t('ownerProductInventories.columns.status'),
            type: 'dropdown',
            dropdownOptions: [
                { value: 'pending', label: t('ownerProductInventories.columns.pending') },
                { value: 'processing', label: t('ownerProductInventories.columns.processing') },
                { value: 'approved', label: t('ownerProductInventories.columns.approved') },
                { value: 'freezed', label: t('ownerProductInventories.columns.freezed') },
                { value: 'rejected', label: t('ownerProductInventories.columns.rejected') },

            ]
        }
    ];

    const applyFilters = (values) => {
        console.log('values', values);
        let newSearchParams = {};
        for (let key in values) {
            if (typeof values[key] !== 'undefined' && values[key] !== '') {
                if (moment.isMoment(values[key])) {
                    newSearchParams[key] = values[key].format('YYYY-MM-DD');
                } else {
                    newSearchParams[key] = values[key];
                }
            }
        }
        console.log('values', values);
        setSearchParams(newSearchParams);
    }

    const clearFilters = () => {
        let newSearchParams = {};
        filterForm.resetFields();
        setSearchParams(newSearchParams);
    }

    let hasSearchParams = false;
    for (const entry of searchParams.entries()) {
        const [param] = entry;
        console.log('param', param);
        if (param !== 'page' && param !== 'perPage') {
            hasSearchParams = true;
            break;
        }
    }

    const { Panel } = Collapse;

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title={t('ownerProductInventories.title')}
                        subTitle={t('ownerProductInventories.subtitle')}
                        extra={[
                            <Button key="1" type="primary" onClick={() => navigate(`/product_owner/${params.productOwnerId}/product-inventory`)}>
                                {t('ownerProductInventories.button.new')}
                            </Button>
                        ]}
                    />
                    <p className="list-note">{t('ownerProductInventories.description')}</p>
                    <Collapse style={{ marginTop: 10, marginBottom: 10 }} bordered={false} defaultActiveKey={hasSearchParams ? ['filter'] : []}>
                        <Panel header={<strong>{t('products.filter.title')}</strong>} key="filter">
                            <Form form={filterForm} layout="inline" onFinish={applyFilters}>
                                <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key="_id" name="_id" label="ID">
                                    <Input />
                                </Form.Item>
                                {productDataSettings.map(field => {
                                    switch (field.type) {
                                        case 'checkbox':
                                        case 'radio':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Select allowClear={true}>{field.choiceOptions && field.choiceOptions.map(option => <Select.Option key={option} value={option.value}>{option.label}</Select.Option>)}</Select>
                                            </Form.Item>;
                                        case 'dropdown':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Select allowClear={true}>{field.dropdownOptions && field.dropdownOptions.map(option => <Select.Option key={option} value={option.value}>{option.label}</Select.Option>)}</Select>
                                            </Form.Item>;
                                        case 'number':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <InputNumber />
                                            </Form.Item>;
                                        case 'email':
                                        case 'primary_email':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} rules={[{ type: 'email' }]}>
                                                <Input />
                                            </Form.Item>;
                                        case 'date':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} rules={[{ type: 'date' }]}>
                                                <DatePicker />
                                            </Form.Item>;
                                        case 'text':
                                        case 'textarea':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name}>
                                                <Input />
                                            </Form.Item>;
                                        case 'boolean':
                                            return <Form.Item style={{ marginBottom: 10, width: 'calc( (100% - 48px) /3 )' }} key={field.slug} name={field.slug} label={field.name} valuePropName="checked">
                                                <Select allowClear={true}>
                                                    <Select.Option value="true">True</Select.Option>
                                                    <Select.Option value="false">False</Select.Option>
                                                </Select>
                                            </Form.Item>;
                                        default:
                                            console.log(`Please contact admin to add support for ${field.name} <${field.slug}|${field.type}> field type`);
                                            return null;
                                    }
                                })}
                                <Form.Item style={{ width: '100%' }}>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            {t('products.filter.apply')}
                                        </Button>
                                        <Button type="primary" danger onClick={clearFilters}>
                                            {t('products.filter.clear')}
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </Panel>
                    </Collapse>
                    <Table
                        size="small"
                        rowKey={record => record.id}
                        expandable={{
                            expandedRowRender: expandRecord,
                            rowExpandable: () => true
                        }}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{
                            position: ['topRight', 'bottomRight'],
                            showSizeChanger: true,
                            total: dataSource.length,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                        }}
                    />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default OwnerProductInventories;