import authHeader from './auth-header';
import axios from "axios";
import { API_AUTH_URL } from "./config";

// API to login
const login = (email, password) => {
    return axios
        .post(API_AUTH_URL + "login", {
            email,
            password,
        })
        .then((response) => {
            console.log(response);
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
};

// API to reset user
const reset = (userId, token, password) => {
    return axios
        .post(API_AUTH_URL + "reset", {
            id: userId,
            token,
            password
        }).then((response) => {
            console.log(response);
            return response.data;
        });
}


const logout = () => {
    localStorage.removeItem("user");
};

// API to update password in first login
const firstUpdatePassword = (userId, password) => {
    return axios.post(API_AUTH_URL + "firstUpdatePassword", {
        userId,
        password
    }, {
        headers: authHeader()
    }).then((response) => {
        if (response.data.id) {
            let mergedUser = localStorage.getItem("user");
            if (mergedUser) {
                mergedUser = JSON.parse(mergedUser);
                mergedUser = { ...mergedUser, ...response.data };
                localStorage.setItem("user", JSON.stringify(mergedUser));
            }
        }
        return response.data;
    });
}

// API to update name in profile
const profileUpdateName = (userId, name) => {
    return axios.post(API_AUTH_URL + "profileUpdateName", {
        userId,
        name
    }, {
        headers: authHeader()
    }).then((response) => {
        if (response.data.id) {
            let mergedUser = localStorage.getItem("user");
            if (mergedUser) {
                mergedUser = JSON.parse(mergedUser);
                mergedUser = { ...mergedUser, ...response.data };
                localStorage.setItem("user", JSON.stringify(mergedUser));
            }
        }
        return response.data;
    });
}

// API to update password in profile
const profileUpdatePassword = (userId, oldPassword, newPassword) => {
    return axios.post(API_AUTH_URL + "profileUpdatePassword", {
        userId,
        oldPassword,
        newPassword
    }, {
        headers: authHeader()
    }).then((response) => {
        if (response.data.id) {
            let mergedUser = localStorage.getItem("user");
            if (mergedUser) {
                mergedUser = JSON.parse(mergedUser);
                mergedUser = { ...mergedUser, ...response.data };
                localStorage.setItem("user", JSON.stringify(mergedUser));
            }
        }
        return response.data;
    });
}

// API to get current user
const currentUser = () => {
    return axios.get(API_AUTH_URL + "currentUser", {
        headers: authHeader()
    }).then((response) => {
        if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
    });
}


const auth = {
    login,
    logout,
    firstUpdatePassword,
    profileUpdateName,
    profileUpdatePassword,
    currentUser,
    reset
};

export default auth;