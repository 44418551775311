import React, { useEffect, useState } from 'react';
import { Layout, Form, Input, Button, message, Row, Col, Statistic, Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import MemberSidebar from './components/member.sidebar.component';
import { useNavigate } from 'react-router-dom';
import MemberFooter from './components/member.footer.component';
import { useDispatch, useSelector } from 'react-redux';
import { profileUpdateName, profileUpdatePassword } from '../actions/auth';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import { getReportFromSinceLaunched } from '../actions/report';

const formatter = (value) => <CountUp end={value} separator="," />;
const priceFormatter = (value) => <CountUp end={value} prefix="$" separator="," />;

function Report() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Content } = Layout;
    const [dataSource, setDataSource] = useState(null);

    useEffect(() => {
        dispatch(getReportFromSinceLaunched()).then((response) => {
            if (typeof response.result !== 'undefined' && response.result !== null) {
                setDataSource(response.result);
            }
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, []);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title={t('report.title')}
                        subTitle={t('report.subtitle')}
                    />
                    <h1>{t('report.general')}</h1>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Statistic title={t('report.total_product_title')} value={(dataSource && dataSource.totalProduct) ? dataSource.totalProduct : 0} formatter={formatter} />
                        </Col>
                        <Col span={12}>
                            <Statistic title={t('report.total_saleable_product_title')} value={(dataSource && dataSource.totalSaleableProduct) ? dataSource.totalSaleableProduct : 0} formatter={formatter} />
                        </Col>
                        <Col span={12}>
                            <Statistic title={t('report.total_published_product_title')} value={(dataSource && dataSource.totalPublishedProduct) ? dataSource.totalPublishedProduct : 0} formatter={formatter} />
                        </Col>
                        <Col span={12}>
                            <Statistic title={t('report.total_saleable_published_product_title')} value={(dataSource && dataSource.totalSaleablePublishedProduct) ? dataSource.totalSaleablePublishedProduct : 0} formatter={formatter} />
                        </Col>
                        <Col span={12}>
                            <Statistic title={t('report.total_product_has_stock_title')} value={(dataSource && dataSource.totalProductWithStock) ? dataSource.totalProductWithStock : 0} precision={2} formatter={formatter} />
                        </Col>
                        <Col span={24}>
                            <Statistic title={t('report.total_user_title')} value={(dataSource && dataSource.totalUser) ? dataSource.totalUser : 0} precision={2} formatter={formatter} />
                        </Col>
                    </Row>
                    <br />
                    <h1>{t('report.transactions')}</h1>
                    <Tabs defaultActiveKey="since_launch" items={
                        [
                            {
                                key: 'since_launch',
                                label: t('report.since_website_launch'),
                                children: <>
                                    <h1>{t('report.since_website_launch')}</h1>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <Statistic title={t('report.total_order_title')} value={(dataSource && typeof dataSource.since_website_launch !== 'undefined' && dataSource.since_website_launch.totalOrder) ? dataSource.since_website_launch.totalOrder : 0} formatter={formatter} />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title={t('report.total_revenue_title')} value={(dataSource && typeof dataSource.since_website_launch !== 'undefined' && dataSource.since_website_launch.totalRevenue) ? dataSource.since_website_launch.totalRevenue : 0} formatter={priceFormatter} />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title={t('report.total_refunded_title')} value={(dataSource && typeof dataSource.since_website_launch !== 'undefined' && dataSource.since_website_launch.totalRefunded) ? dataSource.since_website_launch.totalRefunded : 0} precision={2} formatter={priceFormatter} />
                                        </Col>
                                    </Row>
                                </>
                            },
                            {
                                key: 'last_7_days',
                                label: t('report.last_7_days'),
                                children: <>
                                    <h1>{t('report.last_7_days')}</h1>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <Statistic title={t('report.total_order_title')} value={(dataSource && typeof dataSource.last_7_days !== 'undefined' && dataSource.last_7_days.totalOrder) ? dataSource.last_7_days.totalOrder : 0} formatter={formatter} />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title={t('report.total_revenue_title')} value={(dataSource && typeof dataSource.last_7_days !== 'undefined' && dataSource.last_7_days.totalRevenue) ? dataSource.last_7_days.totalRevenue : 0} formatter={priceFormatter} />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title={t('report.total_refunded_title')} value={(dataSource && typeof dataSource.last_7_days !== 'undefined' && dataSource.last_7_days.totalRefunded) ? dataSource.last_7_days.totalRefunded : 0} precision={2} formatter={priceFormatter} />
                                        </Col>
                                    </Row>
                                </>
                            },
                            {
                                key: 'last_30_days',
                                label: t('report.last_30_days'),
                                children: <>
                                    <h1>{t('report.last_30_days')}</h1>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <Statistic title={t('report.total_order_title')} value={(dataSource && typeof dataSource.last_30_days !== 'undefined' && dataSource.last_30_days !== null && dataSource.last_30_days.totalOrder) ? dataSource.last_30_days.totalOrder : 0} formatter={formatter} />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title={t('report.total_revenue_title')} value={(dataSource && typeof dataSource.last_30_days !== 'undefined' && dataSource.last_30_days !== null && dataSource.last_30_days.totalRevenue) ? dataSource.last_30_days.totalRevenue : 0} formatter={priceFormatter} />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title={t('report.total_refunded_title')} value={(dataSource && typeof dataSource.last_30_days !== 'undefined' && dataSource.last_30_days !== null && dataSource.last_30_days.totalRefunded) ? dataSource.last_30_days.totalRefunded : 0} precision={2} formatter={priceFormatter} />
                                        </Col>
                                    </Row>
                                </>
                            },
                            {
                                key: 'last_90_days',
                                label: t('report.last_90_days'),
                                children: <>
                                    <h1>{t('report.last_90_days')}</h1>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <Statistic title={t('report.total_order_title')} value={(dataSource && typeof dataSource.last_90_days !== 'undefined' && dataSource.last_90_days !== null && dataSource.last_90_days.totalOrder) ? dataSource.last_90_days.totalOrder : 0} formatter={formatter} />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title={t('report.total_revenue_title')} value={(dataSource && typeof dataSource.last_90_days !== 'undefined' && dataSource.last_90_days !== null && dataSource.last_90_days.totalRevenue) ? dataSource.last_90_days.totalRevenue : 0} formatter={priceFormatter} />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title={t('report.total_refunded_title')} value={(dataSource && typeof dataSource.last_90_days !== 'undefined' && dataSource.last_90_days !== null && dataSource.last_90_days.totalRefunded) ? dataSource.last_90_days.totalRefunded : 0} precision={2} formatter={priceFormatter} />
                                        </Col>
                                    </Row>
                                </>
                            },
                            {
                                key: 'since_this_year',
                                label: t('report.since_this_year'),
                                children: <>
                                    <h1>{t('report.since_this_year')}</h1>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <Statistic title={t('report.total_order_title')} value={(dataSource && typeof dataSource.since_this_year !== 'undefined' && dataSource.since_this_year !== null && dataSource.since_this_year.totalOrder) ? dataSource.since_this_year.totalOrder : 0} formatter={formatter} />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title={t('report.total_revenue_title')} value={(dataSource && typeof dataSource.since_this_year !== 'undefined' && dataSource.since_this_year !== null && dataSource.since_this_year.totalRevenue) ? dataSource.since_this_year.totalRevenue : 0} formatter={priceFormatter} />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title={t('report.total_refunded_title')} value={(dataSource && typeof dataSource.since_this_year !== 'undefined' && dataSource.since_this_year !== null && dataSource.since_this_year.totalRefunded) ? dataSource.since_this_year.totalRefunded : 0} precision={2} formatter={priceFormatter} />
                                        </Col>
                                    </Row>
                                </>
                            }
                        ]
                    } />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Report;