import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
// import role from "./role";
// import admin from "./admin";
// import userAttrs from "./userAttrs";

export default combineReducers({
    auth,
    message,
    // role,
    // admin,
    // userAttrs
});