import AdminService from "../services/admin.service";

// Action to get admin by id
export const readAdmin = (id) => (dispatch) => {
    return AdminService.readAdmin(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all admins
export const readAdmins = () => (dispatch) => {
    return AdminService.readAdmins().then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the admin
export const createAdmin = (admin) => (dispatch) => {
    return AdminService.createAdmin(admin).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to update the admin
export const updateAdmin = (id, admin) => (dispatch) => {
    return AdminService.updateAdmin(id, admin).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the admin
export const deleteAdmin = (id) => (dispatch) => {
    return AdminService.deleteAdmin(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Actino to enable the admin
export const enableAdmin = (id) => (dispatch) => {
    return AdminService.enableAdmin(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to disable the admin
export const disableAdmin = (id) => (dispatch) => {
    return AdminService.disableAdmin(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to reset the admin
export const resetAdmin = (id) => (dispatch) => {
    return AdminService.resetAdmin(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}