import React, { useEffect, useState } from "react";
import {
  Layout,
  message,
  Button,
  Form,
  Input,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { useNavigate, useParams } from "react-router-dom";
import MemberSidebar from "./components/member.sidebar.component";
import MemberFooter from "./components/member.footer.component";
import { useDispatch } from "react-redux";
import { createProductRequest, readProductRequest, updateProductRequest } from "../actions/product.request";
import { useTranslation } from "react-i18next";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

function ProductRequest() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { Content } = Layout;
  const [form] = Form.useForm();
  const [productRequestTitle, setProductRequestTitle] = useState("");

  useEffect(() => {
    if (typeof params.productRequestId !== "undefined") {
      setProductRequestTitle(t("product-request.edit.title"));
      dispatch(
        readProductRequest(params.productOwnerId, params.productRequestId)
      )
        .then((response) => {
          if (
            typeof response.result !== "undefined" &&
            typeof response.result.id !== "undefined"
          ) {
            setProductRequestTitle(t("product-request.edit.title"));
            form.setFieldsValue(response.result);
          } else {
            message.error(t("product-request.message.read-error"));
            navigate(
              `/product_owner/${params.productOwnerId}/product-requests`
            );
          }
        })
        .catch((e) => {
          message.error(t("product-request.message.read-error"));
          navigate(`/product_owner/${params.productOwnerId}/product-requests`);
        });
    } else {
      setProductRequestTitle(t("product-request.new.title"));
    }
  }, [
    params.productOwnerId,
    params.productRequestId,
    dispatch,
    navigate,
    form,
    t
  ]);

  const onFinish = (values) => {
    if (typeof params.productRequestId !== "undefined") {
      dispatch(
        updateProductRequest(
          params.productOwnerId,
          params.productRequestId,
          values
        )
      )
        .then((data) => {
          message.success(t("product-request.message.update-success"));
          if (data.result) {
            navigate(
              "/product_owner/" + params.productOwnerId + "/product-request/" + params.productRequestId
            );
          }
        })
        .catch((error) => {
          try {
            let responseMessage = error.response.data.message;
            console.log(responseMessage);
            message.error(responseMessage);
          } catch (e) {
            console.log("Failed:", e);
            message.error(JSON.stringify(e));
          }
        });
    } else {
      // Create new admin
      dispatch(createProductRequest(params.productOwnerId, values))
        .then((data) => {
          message.success(t("product-request.message.create-success"));
          if (data.result) {
            navigate(
              "/product_owner/" + params.productOwnerId + "/product-requests"
            );
          } else {
            navigate(
              "/product_owner/" + params.productOwnerId + "/product-requests"
            );
          }
        })
        .catch((error) => {
          try {
            let responseMessage = error.response.data.message;
            console.log(responseMessage);
            message.error(responseMessage);
          } catch (e) {
            console.log("Failed:", e);
            message.error(JSON.stringify(e));
          }
        });
    }
  };

  return (
    <>
      <MemberSidebar />
      <Layout className="site-layout">
        <Content style={{ margin: "0 16px" }}>
          <div className="site-layout-background">
            <Form
              {...layout}
              form={form}
              name="product-request"
              onFinish={onFinish}
              initialValues={{
                status: true,
              }}
            >
              <PageHeader
                className="site-page-header"
                onBack={() =>
                  navigate(
                    `/product_owner/${params.productOwnerId}/product-requests`
                  )
                }
                title={productRequestTitle}
                extra={[
                  <Button
                    key="cancel"
                    type="primary"
                    danger
                    onClick={() => navigate(`/product_owner/${params.productOwnerId}/product-requests`)}
                  >
                    {t("product-request.button.cancel")}
                  </Button>,
                  <Button key="save" type="primary" htmlType="submit">
                    {t("product-request.button.save")}
                  </Button>,
                ]}
              />
              <Form.Item
                name="sku"
                label={t("product-request.sku.label")}
                rules={[
                  {
                    required: true,
                    message: t("product-request.sku.required"),
                  },
                ]}
              >
                <Input placeholder={t("product-request.sku.placeholder")} />
              </Form.Item>
              <Form.Item name="name" label={t("product-request.name.label")}>
                <Input placeholder={t("product-request.name.placeholder")} />
              </Form.Item>
              <Form.Item name="url" label={t("product-request.url.label")}>
                <Input placeholder={t("product-request.url.placeholder")} />
              </Form.Item>
              <Form.Item
                name="volume"
                label={t("product-request.volume.label")}
              >
                <Input
                  placeholder={t("product-request.volume.placeholder")}
                />
              </Form.Item>
              <Form.Item name="note" label={t("product-request.note.label")}>
                <Input.TextArea
                  placeholder={t("product-request.note.placeholder")}
                  autoSize={{ minRows: 5, maxRows: 10 }}
                />
              </Form.Item>
            </Form>
          </div>
        </Content>
        <MemberFooter />
      </Layout>
    </>
  );
}

export default ProductRequest;
