import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Table, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readProductCategories } from '../actions/product-category';
import { green, red } from '@ant-design/colors';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function ProductCategories() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { Content } = Layout;
    const [dataSource, setDataSource] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [reloadFlag, setReloadFlag] = useState(new Date());

    const columns = [
        {
            title: t('productCategories.columns.id'),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t('productCategories.columns.slug'),
            dataIndex: 'slug',
            key: 'slug',
        },
        {
            title: t('productCategories.columns.name'),
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                if (typeof record.translations !== 'undefined') {
                    return <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
                        {record.translations.map((translation, key) => {
                            return <li key={key}>[{translation.lang}] {translation.name}</li>
                        })}
                    </ul>
                } else {
                    return 'N/A';
                }
            }
        },
        {
            title: t('productCategories.columns.status'),
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (record.status) {
                    return <CheckOutlined style={{ color: green.primary }} />;
                } else {
                    return <CloseOutlined style={{ color: red.primary }} />;
                }
            }
        },
        {
            title: t('productCategories.columns.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return <Space>
                    <Button type="primary" onClick={() => navigate(`/product-category/${record.id}`)}>{t('productCategories.button.edit')}</Button>
                </Space>
            }
        }
    ];

    useEffect(() => {
        dispatch(readProductCategories(searchParams)).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
            } else {
                setDataSource([]);
            }
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [reloadFlag, dispatch, navigate, searchParams]);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title={t('product-categories.title')}
                        subTitle={t('product-categories.subtitle')}
                        extra={[
                            <Button key="create" type="primary" onClick={() => navigate('/product-category')}>{t('productCategories.button.new')}</Button>
                        ]}
                    />
                    <Table
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns}
                        size='small'
                    />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default ProductCategories;