import ProductInventoryService from "../services/product.inventory.service";

// Operation Team Use
// Action to batch action on selected product inventories
export const batchProductInventory = (inventoryIds, action) => (dispatch) => {
    return ProductInventoryService.batchProductInventory(inventoryIds, action).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}


// Action to get all product inventory
export const readAllProductInventories = (searchParams) => (dispatch) => {
    return ProductInventoryService.readAllProductInventories(searchParams).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to process the product inventory request
export const processProductInventory = (id) => (dispatch) => {
    return ProductInventoryService.processProductInventory(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to addLog on the product inventory request
export const addLogProductInventory = (id, body) => (dispatch) => {
    return ProductInventoryService.addLogProductInventory(id, body).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to update the remark on the product inventory request
export const updateRemarkProductInventory = (id, body) => (dispatch) => {
    return ProductInventoryService.updateRemarkProductInventory(id, body).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to approve the product inventory request
export const approveProductInventory = (id) => (dispatch) => {
    return ProductInventoryService.approveProductInventory(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to reject the product inventory request
export const rejectProductInventory = (id) => (dispatch) => {
    return ProductInventoryService.rejectProductInventory(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to freeze the product inventory request
export const freezeProductInventory = (id) => (dispatch) => {
    return ProductInventoryService.freezeProductInventory(id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to adjust the product inventory request
export const adjustProductInventory = (id, body) => (dispatch) => {
    return ProductInventoryService.adjustProductInventory(id, body).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Product Owner Use
// Action to get all product inventory by product owner id
export const readProductInventories = (id, searchParams) => (dispatch) => {
    return ProductInventoryService.readProductInventories(id, searchParams).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// API to create product inventory request by product owner id
export const createProductInventory = (id, data) => (dispatch) => {
    return ProductInventoryService.createProductInventory(id, data).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// API to create product inventory request by operation team
export const createProductInventoryByOperationTeam = (data) => (dispatch) => {
    return ProductInventoryService.createProductInventoryByOperationTeam(data).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}
