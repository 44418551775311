import authHeader from './auth-header';
import axios from "axios";
import { API_REPORT_URL } from "./config";

const getReportFromSinceLaunched = () => {
    return axios.get(API_REPORT_URL + 'since-launch', {
        headers: authHeader()
    }).then(res => res.data);
}

const ReportService = {
    getReportFromSinceLaunched
};

export default ReportService;